<template>
  <div class="product-intro__price-under">
    <BestDealTips
      v-if="bestDealCombo.show"
      :text="bestDealCombo.content"
      :analysis="bestDealCombo.analysis"
    />
  </div>
</template>

<script>
import BestDealTips from './BestDealTips'
export default {
  name: 'ProductIntroPriceTips',
  components: { BestDealTips },
  props: {
    priceTipsConfig: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    bestDealCombo() {
      return this.priceTipsConfig.best_deal
    }
  }
}
</script>

<style lang="less">
.product-intro {
  &__price-under {
    display: inline-flex;
    align-items: center;
    margin-top: 8px;
  }
}
</style>
