<template>
  <div>
    <div
      v-if="sizeGuideShow || showModel"
      class="product-intro__sizeguide"
      @keyup.esc="escHandle"
    >
      <h2
        ref="sizeGuideHeadHook"
        v-enterkey
        class="product-intro__sizeguide-head"
        da-event-click="1-8-6-21"
        tabindex="0"
        :aria-label="language.SHEIN_KEY_PC_14669"
        @click="handleFold"
      >
        <span>{{ language.SHEIN_KEY_PC_14669 }}</span>

        <sui_icon_min_16px
          v-if="unfold.includes('SizeGuide')"
          size="16px"
          class="head-icon"
        />
        <sui_icon_add_16px
          v-else
          size="16px"
          class="head-icon"
        />
      </h2>
      <ClientOnly>
        <div
          v-show="unfold.includes('SizeGuide')"
          class="product-intro__sizeguide-panel"
        >
          <div
            v-if="showModel"
            class="product-intro__sizeguide-summary"
          >
            <div class="product-intro__sizeguide-summary-cover">
              <div class="inner">
                <img :src="thumbnailImg(model.image, '220x')" />
              </div>
            </div>
            <div class="product-intro__sizeguide-summary-list">
              <div
                v-if="modelWearingSizeShow"
                tabindex="0"
                :aria-label="`${language.SHEIN_KEY_PC_20985}: ${model.size} ${modelLocalSize}`"
              >
                {{ language.SHEIN_KEY_PC_20985 }}:
                <span>{{ model.size }} {{ modelLocalSize }}</span>
              </div>
              <div class="model-item">
                <template v-for="item in modelSize">
                  <template v-if="showFootInfo">
                    <div
                      v-if="
                        item.column == 'BallGirth' || item.column == 'FootLength'
                      "
                      :key="item.key"
                      class="line"
                      tabindex="0"
                      :aria-label="`${item.key}: ${item.value}`"
                    >
                      {{ item.key }}: <span>{{ item.value }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <div
                      v-if="
                        item.column !== 'BallGirth' &&
                          item.column !== 'FootLength'
                      "
                      :key="item.key"
                      tabindex="0"
                      :aria-label="`${item.key}: ${item.value}`"
                    >
                      {{ item.key }} <span>{{ item.value }}</span>
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div v-if="sizeGuideShow">
            <FitAndFabric />
            <CommonBaseSizeTable
              v-model:currentUnit="currentSizeUnit"
              :sale-attr-list="saleAttrList"
              :current-country="country"
              :product-intro-data="productIntroData"
              :has-local-size="hasLocalSize"
              :language="language"
              :size-priority="sizeConfig.sizePriority"
              from="productIntro"
              @change-unit="handleChangeUnit"
              @handle-change-country="handleChangeCountry"
            />
          </div>
        </div>
      </ClientOnly>
    </div>
  </div>
</template>

<script>
import CommonBaseSizeTable from './CommonBaseSizeTable'
import FitAndFabric from './FitAndFabric'
import { mapState, mapMutations, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getBubbleInfo } from '../utils/sizeInfoDesc'
import { transformImg } from '@shein/common-function'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { sui_icon_min_16px, sui_icon_add_16px } from '@shein-aidc/icon-vue3'
daEventCenter.addSubscriber({ modulecode: '1-8-6' })

export default {
  name: 'ProductIntroSizeGuide',
  components: {
    CommonBaseSizeTable,
    FitAndFabric,
    ClientOnly,
    sui_icon_min_16px,
    sui_icon_add_16px
  },
  props: {
    currentUnit: {
      type: Number,
      default: -1
    }
  },
  computed: {
    ...mapGetters('Size', ['sizeConfig']),
    ...mapState([
      'productIntroData',
      'unfold',
      'language',
      'hasLocalSize',
      'country',
      'sizeGuideCountry',
      'tackInfoDes',
      'saleAttrList',
      'WEB_CLIENT'
    ]),
    showModel() {
      if (!this.model || !Object.keys(this.model).length) return false
      if (this.showFootInfo) {
        const FootLength = this.modelSize
          .find((el) => el.column === 'FootLength')
          ?.value?.replace(/\s|\//g, '')
        const BallGirth = this.modelSize
          .find((el) => el.column === 'BallGirth')
          ?.value?.replace(/\s|\//g, '')
        return FootLength && BallGirth
      }
      return true
    },
    currentSizeUnit: {
      get() {
        return this.currentUnit
      },
      set(val) {
        this.$emit('update:currentUnit', val)
      },
    },
    parentCats() {
      return this.productIntroData?.parentCats || {}
    },
    showFootInfo() {
      if (this.WEB_CLIENT === 'romwe') {
        return false
      }
      return (
        this.parentCats?.children?.[0]?.cat_id ==
        (this.WEB_CLIENT === 'shein' ? '1745' : '668')
      )
    },
    model() {
      return this.productIntroData.model || {}
    },
    bubbleInfo() {
      return getBubbleInfo(
        this.saleAttrList.skc_sale_attr,
        this.sizeInfoDes,
        this.tackInfoDes,
        this.language
      )
    },
    sizeInfoDes() {
      return this.productIntroData.sizeInfoDes || {}
    },
    sizeInfoStrFilterField() {
      return [
        'size',
        'attr_id',
        'attr_name',
        'attr_value_id',
        'attr_value_name',
        'attr_value_name_en',
      ]
    },
    sizeGuideShow() {
      const bubbleInfo =
        this.bubbleInfo[this.currentSizeUnit == 1 ? 'inch' : 'cm']
      for (let i = 0; i < bubbleInfo.length; i++) {
        if (bubbleInfo[i].list.length) {
          for (let j = 0; j < bubbleInfo[i].list.length; j++) {
            const keys = Object.keys(bubbleInfo[i].list[j])
            if (
              keys.filter((_) => !this.sizeInfoStrFilterField.includes(_))
                .length > 0
            ) {
              return true
            }
          }
        }
      }
      return false
    },
    modelAttr() {
      return {
        Height: this.language.SHEIN_KEY_PC_15463,
        Waist: this.language.SHEIN_KEY_PC_15465,
        Bust: this.language.SHEIN_KEY_PC_15464,
        Hip: this.language.SHEIN_KEY_PC_15466,
        BallGirth: this.language.SHEIN_KEY_PC_19350,
        FootLength: this.language.SHEIN_KEY_PC_19351,
      }
    },
    // 模特相关信息是否展示
    modelRelativesConfig() {
      const { moduleRenderConfig } = this.productIntroData || {}
      const config = Object.create(null)
      if (!Array.isArray(moduleRenderConfig) || !moduleRenderConfig.length) {
        return config
      }
      // 模特展示配置key: pc_goods_details_model
      const modelConfig = moduleRenderConfig
        .find(config => config.sceneKey === 'pc_goods_details_model')
      const rules = modelConfig?.content?.[0]?.content?.props?.items?.[0]?.fields
      if (!Array.isArray(rules)) {
        return config
      }

      rules.forEach(rule => {
        config[rule.name] = !!rule.isShow
      })
      return config
    },
    // 模特穿着尺码是否展示
    modelWearingSizeShow() {
      const { modelRelativesConfig } = this
      if (modelRelativesConfig.size === false) {
        return false
      }
      return true
    },
    modelSize() {
      const { model, modelAttr, modelRelativesConfig } = this
      if (model && Object.keys(model).length) {
        const attr = model.attrcm
        const inch = model.attrinch
        // this.currentSizeUnit === 1 ? model.attrinch : model.attrcm;
        const result = []
        for (const [key, value] of Object.entries(attr)) {
          // 筛除掉ccc配置为不展示的类别
          if (modelRelativesConfig[key] === false || (this.isBlankSize(this.model.attrcm[key]) && this.isBlankSize(this.model.attrinch[key]))) {
            continue
          }
          const translateKey = modelAttr[key]
          result.push({ key: translateKey, value: value + ' / ' + inch[key], column: key })
        }
        return result
      }
      return []
    },
    // 模特展示哪个国家的本地尺码
    modelLocalSize() {
      let tabCountry =  this.country
      if(this.sizeConfig.showOnlyOneLocalSize){
        tabCountry = this.country === this.sizeConfig?.localSizeList?.country_code ? this.country : ''
      }
      const multiLocalSize =
        this.productIntroData.multiLocalSize?.size_rule_list || {}
      const localSizeData = multiLocalSize[tabCountry] || []
      if (!localSizeData.length) return
      const localSizeSelected = localSizeData.find(
        (i) => i.name == this.model.size
      )
      if (!localSizeSelected) return ''
      return `(${tabCountry}: ${localSizeSelected.correspond})`
    },
  },
  watch: {
    model: {
      handler(newValue, oldValue) {
        if (!oldValue && typeof newValue?.sizeUnit != 'undefined') 
          this.currentSizeUnit =  Number(newValue.sizeUnit)
      },
      immediate: true,
    },
  },
  mounted() {
    daEventCenter.triggerNotice({
      daId: '1-8-6-31',
    })
  },
  methods: {
    ...mapMutations(['assignState']),
    // esc 关闭
    escHandle() {
      this.unfold.includes('SizeGuide') && this.$refs['sizeGuideHeadHook'].click()
    },
    handleChangeCountry(val) {
      this.assignState({ country: val })
    },
    /**
     * 收起展开
     */
    handleFold() {
      let updatedArray = []
      if (this.unfold.includes('SizeGuide')) {
        updatedArray = this.unfold.filter(item => item !== 'SizeGuide')
      } else {
        updatedArray = [...this.unfold, 'SizeGuide']
      }
      this.assignState({
        unfold: updatedArray,
      })
    },
    /**
     * 改变尺码单位
     */
    handleChangeUnit(num) {
      this.currentSizeUnit = num
    },
    thumbnailImg(fileUrl, size) {
      let fileName = this.transformImg({ img: fileUrl })
      let suffix = `.${fileName.split('.').pop()}`
      return fileName.replace(suffix, `_thumbnail_${size}${suffix}`)
    },
    transformImg,
    // 过滤掉空白尺码
    isBlankSize(size) {
      return [0, 0.0, '0', '0.0'].includes(size)
    },
  },
  emits: ['update:currentUnit'],
}
</script>

<style lang="less">
.product-intro {
  &__sizeguide-panel {
    padding-bottom: 15px;
    margin-top: 15px;
  }
  &__sizeguide-summary {
    margin-bottom: 40px;
    display: flex;
  }
  &__sizeguide-summary-cover {
    .margin-r(15px);
    .inner {
      width: 62px;
      height: 0;
      padding-bottom: 100%;
      overflow: hidden;
      border-radius: 50%;
    }
    img {
      width: 100%;
    }
  }
  &__sizeguide-summary-list {
    // width: 260px;
    font-size: 12px;
    .model-item {
      .flexbox();
      flex-wrap: wrap;
      > div {
        // flex: 0 0 50%;
        margin-top: 10px;
        margin-right: 24px;
      }
      .line {
        flex: 0 0 100%;
      }
    }
  }
}
.common-sizetable {
  padding-bottom: 10px;
  font-size: 12px;
  &::-webkit-scrollbar {
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(224, 224, 224, 1);
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: rgba(247, 248, 250, 1);
  }
  &__units {
    cursor: pointer;
    z-index: @zindex-hover;
    font-size: 14px;
    vertical-align: bottom;
    .icon-down,
    .icon-up {
      padding-left: 5px;
      font-weight: 700;
      font-size: 14px;
      vertical-align: bottom;
    }
    ul {
      position: absolute;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
      border: 1px solid rgba(242, 242, 242, 1);
      padding: 5px 0;
      top: 29px;
      .right(0);
    }
    li {
      width: 138px;
      height: 36px;
      line-height: 36px;
      .padding-l(16px);
      cursor: pointer;
    }
  }
  &__units-left {
    .right(15px);
  }
  &__units-item {
    margin: 4px 0;
    .margin-l(15px);
    white-space: nowrap;
    display: inline-block;
    .she-radio {
      .margin-r(2px);
    }
  }
  &__table {
    background-color: #fff;
    overflow-x: auto;
    padding-bottom: 1px;
    &::-webkit-scrollbar {
      height: 6px; /* stylelint-disable-line declaration-no-important */
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: rgba(224, 224, 224, 1);
    }
    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: rgba(247, 248, 250, 1);
    }
    .trhead {
      color: #222;
      white-space: nowrap;
      font-weight: 700;
    }
  }
  &__table-luvette {
    &.common-sizetable__table-size {
      font-size: 12px;
    }
    table {
      width: 100%;
    }
  }
  &__table-tr {
    text-align: center;
    td {
      min-width: 85px;
      height: 38px;
      padding: 0 4px;
      transition: background-color 0.2s, color 0.2s;
      &.col-highlight {
        background-color: #e5e5e5aa;
        color: #222;
      }
    }
    &:hover {
      td {
        background-color: #e5e5e5aa;
        color: #222;
      }
    }
    &:hover td:hover {
      background-color: #e5e5e5;
      color: #222;
      opacity: 1;
    }
    &:hover td:hover ~ td {
      /* stylelint-disable-line selector-class-pattern, selector-max-specificity, selector-max-type  */
      background-color: inherit;
    }
    td:first-child {
      min-width: 85px;
      height: 38px;
      padding: 0 4px;
      color: #222;
      table-layout: fixed;
      font-weight: 700;
    }
  }
}
</style>
