<template>
  <div
    class="product-intro__freeshipping"
    :class="{
      isShowSeller: shippingAbtState.showSellerInfo
    }"
  >
    <!-- Enter -->
    <h2
      v-if="shippingStyleV1"
      class="product-intro__ship-to"
      tabindex="0"
      :aria-label="shippingTitle"
    >
      <span>{{ shippingTitle }}</span>
      <template v-if="shippingTo">
        <span class="medium">{{ shippingTo }}</span>
      </template>
    </h2>
    <!-- CTD-11299 全量非卡片样式 -->
    <template v-if="shippingList.length && hydratedFinished">
      <ShippingNormal
        ref="ENTER_REF"
        v-bind="{
          ...componentsProps,
          language,
          delayWarn,
          isShwoDelayWarn,
          isLogisticsShippinFree,
          showSheinClub,
          largeShipStatus: shippingDetail.largeShipStatus,
          isPaidUser,
          mallCode,
          showShippingClub,
          isRw: false,
          agingExtend: shippingDetail?.aging_extends?.[0] || {},
          shippingAbtState,
          descQuickShipTime,
          showDescQuickShipTime,
          quickShipHighlight,
          isSSR
        }"
        @click-enter-handle="clickEnterHandle"
      />
    </template>

    <!-- COD -->
    <template v-if="codPolicy && codPolicy.codTitle">
      <ShippingCodPolicy
        :language="language"
        :is-rw="false"
        :cod-policy="codPolicy"
        @click-handle="showReturnModal"
      />
    </template>

    <!-- 退换货 -->
    <ShippingReturnPolicy
      :return-policy-text="returnPolicyText"
      :allow-return="allowReturn"
      :is-new-out-title="isNewOutTitle"
      :is-refund-able="isRefundAble"
      :language="language"
      :is-rw="false"
      :return-exchange-abt="returnExchangeAbt"
      :is-customization="isCustomization"
      :return-exchange-forbid-text="returnExchangeForbidText"
      @click-handle="showReturnModal"
    />

    <!-- 购物保障 放心购 Guarantee -->
    <!-- v-if="showShippingGuarantee" -->
    <ShippingGuarantee
      :language="language"
      :css-right="GB_cssRight"
      :ui-style="shippingGuaranteeUIStyle"
      :dark-bg="shippingGuaranteeDarkBg"
    />

    <div
      v-if="shippingAbtState.showSellerInfo && showShippingGuarantee"
      class="shipping-holder-4px"
    ></div>

    <!-- 商家信息 -->
    <SoldBySeller
      v-if="shippingAbtState.showSellerInfo"
      v-expose="{ id: '1-6-1-149' }"
      :seller-info="detailStoreSellerInfo"
      :store-info="storeInfo"
      :label-list="labelList"
      :language="language"
      :seller-analysis-data="sellerAnalysisData"
      :show-seller-icon="showSellerIcon"
      :show-report="shippingAbtState.showReport"
      :show-obligationsseller="shippingAbtState.showObligationsseller"
      :css-right="GB_cssRight"
      :business-model="businessModel"
      :goods-id="productIntroData.detail.goods_id"
      :local-warehouse-text="localWarehouseText"
      @click-report="clickReport"
    />

    <div class="shipping-holder-20px"></div>

    <!-- 落地弹窗 -->
    <ClientOnly>
      <template v-if="renderShippingModal">
        <!-- 物流配送信息弹窗 -->
        <ShippingDeliveryDialog
          :language="language"
          :mall-name="mallName"
          :delay-warn="delayWarn"
          :shipping-list="shippingList"
          :delivery-text="deliveryText"
          :shipping-to-text="shippingToText"
          :shipping-modal="shippingModal"
          :free-shipping-info="freeShippingInfo"
          :is-logistics-shippin-free="isLogisticsShippinFree"
          :default-address="defaultAddress"
          :not-support-free="notSupportFree"
          :shipping-abt-state="shippingAbtState"
          :desc-quick-ship-time="descQuickShipTime"
          :large-ship-status="shippingDetail.largeShipStatus"
          :address-select-modal="addressSelectModal"
          :show-desc-quick-ship-time="showDescQuickShipTime"
          :config="shippingConfig"
          :quick-ship-highlight="quickShipHighlight"
          @change-address-handle="changeAddress"
          @cancel-address-modal="cancelAddressModal"
          @click-address-handle="clickAddressHandle"
          @update-shipping-modal="updateShippingModal"
        />
      </template>


      <template v-if="renderReportDialog">
        <ReportDialogOld
          v-if="shippingAbtState.showReport && isUs && !isDsaSite"
          v-model="reportDialogShow"
          :language="language"
          :skc="productIntroData.detail.goods_sn"
          :has-call="shippingAbtState.showReportCall"
          :store-info="storeInfo"
          :detail="productIntroData.detail"
        />
        <ReportDialog
          v-if="shippingAbtState.showReport && !isUs && !isDsaSite"
          v-model="reportDialogShow"
          :language="language"
          :has-call="shippingAbtState.showReportCall"
          :store-info="storeInfo"
          :detail="productIntroData.detail"
        />
        <ReportDialogDsa
          v-if="shippingAbtState.showReport && isDsaSite"
          v-model="reportDialogShow"
          :language="language"
          :has-call="shippingAbtState.showReportCall"
          :store-info="storeInfo"
          :detail="productIntroData.detail"
        />
      </template>


      <template v-if="renderShippingReturnCodModal">
        <ShippingReturnCodModal
          :modal-type="modalType"
          :cod-policy="codPolicy"
          :language="language"
          :return-cod-modal="returnCodModal"
          :return-policy-text="returnPolicyText"
          @close-handle="returnCodModal = false"
        />
      </template>
    </ClientOnly>
  </div>
</template>

<script>
import 'public/src/icon/gd-shipping.svg'
import 'public/src/icon/cod.svg'
import 'public/src/icon/gd-freereturn.svg'
import 'public/src/icon/gd-more-grey.svg'
import 'public/src/icon/arrow-down.svg'

import ShippingNormal from './ShippingNormal'
import ShippingCodPolicy from './ShippingCodPolicy'
import ShippingReturnPolicy from './ShippingReturnPolicy'
// import ShippingNomalSkeleton from './ShippingNomalSkeleton'
import SoldBySeller from './SoldBySeller'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'
import { template, setLocalStorage, getLocalStorage } from '@shein/common-function'
import { defineAsyncComponent } from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { commonMixins } from './mixins'

import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

let user, SiteUID
if (typeof window !== 'undefined') {
  user = gbCommonInfo.user
  SiteUID = gbCommonInfo.SiteUID
}
daEventCenter.addSubscriber({ modulecode: '1-8-6' })
daEventCenter.addSubscriber({ modulecode: '1-6-1' })

// mapGetters
import schttp from 'public/src/services/schttp'
export default {
  name: 'ProductIntroShippingEnter',
  components: {
    ClientOnly,
    ShippingNormal,
    ShippingCodPolicy,
    ShippingReturnPolicy,
    SoldBySeller,
    // ShippingNomalSkeleton,
    ShippingGuarantee: defineAsyncComponent(() =>
      import(/* webpackChunkName: "ShippingGuarantee" */'./ShippingGuarantee')
    ),
    ShippingReturnCodModal: defineAsyncComponent(() =>
      import(/* webpackChunkName: "ShippingReturnCodModal" */'./ShippingReturnCodModal')
    ),
    ShippingDeliveryDialog: defineAsyncComponent(() =>
      import(/* webpackChunkName: "ShippingDeliveryDialog" */'./ShippingDeliveryDialog')
    ),
    ReportDialog: defineAsyncComponent(() => import(/* webpackChunkName: "ReportDialog" */'./ReportDialog/index')),
    ReportDialogOld: defineAsyncComponent(() => import(/* webpackChunkName: "ReportDialogOld" */'./ReportDialog/ReportDialogOld')),
    ReportDialogDsa: defineAsyncComponent(() => import(/* webpackChunkName: "ReportDialogDsa" */'./ReportDialog/ReportDialogDsa/index')),
  },
  mixins: [commonMixins],
  props: {
    hydratedFinished: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      renderShippingReturnCodModal: false,
      returnCodModal: false,
      modalType: '', // 弹窗类型 cod return
      defaultAddress: null,
      cartAddItemParams: {},
      shippingSummary: {},
      descQuickShipTime: '', // 快速发货文案
      addressSelectModal: false, // 地址选择组件是否active状态
      tspLabel: {
        free_policy_limit: 0,
        free_activity_limit: 0,
        freight_activity_limit: 0,
      },
      getPostCodConfigFeeInfo: {},
      delayWarn: [],
      shippingList: [],
      returnAndCodPolicyExpose: false,
      shippingPosition: '',
      renderShippingModal: false,
      shippingModal: false,
      returnAndCodPolicy: {},
      shippingTo: '',
      // showShippingClub: false,
      isSSR: true,
      cacheSearchKey: '',
      is_hide_for_local_shop: false,
      renderReportDialog: false,
      reportDialogShow: false,
      detailStoreSellerInfo: {},
      address_data: null,
    }
  },
  computed: {
    ...mapState(['productIntroData', 'language', 'IS_RW', 'quickShip', 'GB_cssRight', 'lang', 'skuInfo', 'DETAIL_STREAM_PART']),
    ...mapGetters(['screenSiteAbtConfig', 'screenAbtConfig', 'freeShippingInfo', 'showQuickShip', 'sheinClubShippingSiteConfig', 'deliveryDialogNInfo', 'isCustomization',
      'deliveryStrongTitleSection', 'isSupportQuickship', 'shippingDetail', 'quickshipAgingText', 'quickshipStrongAbt', 'showSheinClub', 'currentSkuStatus', 'isPaidUser', 'goodsFreeShippingInfo', 'quickShipInfo']),
    shippingStyleV1() {
      return this.screenAbtConfig?.shipping?.p == 'new' && this.hydratedFinished
    },
    isShwoDelayWarn() {
      return this.freeShippingInfo?.isShowDelayWarn || false
    },
    isLogisticsShippinFree() {
      return (this.freeShippingInfo?.isProductFreeShipping || this.freeShippingInfo?.isPromotionFreeShipping || this.freeShippingInfo?.isCouponFreeShipping) && this.shippingAbtState.showFreeinQuire
    },
    hasDetailStoreSellerInfo () {
      return this.productIntroData.hasDetailStoreSellerInfo || {}
    },
    sellerAnalysisData () {
      const { goods_id = '' } = this.productIntroData?.detail || {}
      const { storeCode: store_code = '' } = this.storeInfo || {}
      return {
        goods_id,
        store_code,
      }
    },
    satisfyQuickshipStrongAbt() {
      const { floorQuickshipStyle, floorNDeliveryStyle } = this.quickshipStrongAbt // 物流楼层quickship强化样式 || 物流楼层n天达
      if(floorQuickshipStyle || floorNDeliveryStyle) {
        return true
      }
      return false
    },
    useStrongStyleType() { // 大于0表示命中quickship增强样式
      // 0 原来icon 1 深色icon 2 空心icon
      if(this.satisfyQuickshipStrongAbt) {
        return this.isSupportQuickship.isSomeSupportQuickship ? 1 : 2
      }
      return 0
    },
    shippingAbtState() {
      const { screenAbtConfig, screenSiteAbtConfig, mallCode } = this
      const {
        localwarehouse,
        DetailPageQuickShipShow,
        ShippingLogisticsTime,
        shipping,
        moresellerinfo,
        obligationsseller,
        freeinquire,
        ReportItem,
        Environprotect,
        allshiptimeshow,
        Deletemess
      } = screenAbtConfig || {}
      const {
        detailshowsellerinfo
      } = screenSiteAbtConfig || {}
      const all_ship_time_show_map = this.shippingDetail?.all_ship_time_show_map || {}
      let showReport = (() => {
        if (ReportItem?.p?.Reportshow !== 'show') return false
        if (ReportItem?.p?.limitstore === 'no') return true
        if (
          ReportItem?.p?.limitstore === 'yes' &&
          this.storeInfo.storeType == 1
        )
          return true
        return false
      })()
      const showSellerIcon = moresellerinfo?.p?.moresellerinfo === 'show'
      return {
        showLocalwarehouse: localwarehouse?.param?.localwarehouse == 'show',
        showQuickShip: DetailPageQuickShipShow?.param == 'DetailShowQuick',
        showShippingDayPercent:
          ShippingLogisticsTime?.param?.LogisticsTime == 'B',
        shipping: shipping?.param || 'type=A',
        showSellerInfo: detailshowsellerinfo?.param?.detailshowsellerinfo == 'show',
        showFreeinQuire: freeinquire?.p?.freeinquire === 'new',
        showObligationsseller: obligationsseller?.p?.obligationsseller === 'show',
        showReport,
        showSellerIcon,
        showReportCall: ReportItem?.p?.call === 'yes',
        showTransportaop: shipping?.p === 'Transportaop',
        showAllShipTimeShow: allshiptimeshow?.param?.allshiptimeshow === 'B' && all_ship_time_show_map[mallCode] === 1,
        showEnvironprotect: Environprotect?.p?.Environprotect === 'new',
        showDeletemess: Deletemess?.p?.Deletemess === 'show'
      }
    },
    shippingTitle() {
      const { language, showMallSite, mallName } = this
      if (showMallSite) {
        return template(mallName, language.SHEIN_KEY_PC_21861)
      } else {
        return language['SHEIN_KEY_PC_16575']
      }
    },
    shippingToText() {
      const { language, showMallSite, mallName } = this
      if (showMallSite) {
        return template(mallName, language.SHEIN_KEY_PC_22040)
      } else {
        return `${language['SHEIN_KEY_PC_16575']}:`
      }
    },
    componentsProps() {
      return {
        shippingSummary: this.shippingSummary,
        useStrongStyleType: this.useStrongStyleType,
        quickshipStrongAbt: this.quickshipStrongAbt, // abt看看能不能不用传
        quickShipInfo: this.quickShipInfo, // abt看看能不能不用传
        deliveryStrongTitleSection: this.deliveryStrongTitleSection, // 免邮的qs强化文案title
        satisfyQuickshipStrongAbt: this.satisfyQuickshipStrongAbt,
        isSupportQuickship: this.isSupportQuickship,
        quickshipAgingText: this.quickshipAgingText,
        shippingStyleV1: this.shippingStyleV1
      }
    },
    // 退货弹窗的文案
    returnPolicyText() {
      return this.returnAndCodPolicy?.return_policy || ''
    },
    returnExchangeAbt() {
      return this.screenAbtConfig?.Returnorexchange?.p?.Returnorexchange === 'new'
    },
    returnExchangeForbidText() {
      let text = ''
      const returnPolicy = this.returnAndCodPolicy?.return_policy || {}
      if(!this.returnExchangeAbt) {
        text = this.language.SHEIN_KEY_PC_16992
      }else if(returnPolicy.is_return == 3) {
        text = this.language.SHEIN_KEY_PC_32123 || 'Items in this category cannot be returned or exchanged'
      } else {
        text = this.language.SHEIN_KEY_PC_32124 || 'Items with final sale cannot be returned or exchanged'
      }
      return text
    },
    // 运输弹窗的文案
    deliveryText() {
      return this.returnAndCodPolicy?.delivery || ''
    },
    codPolicy() {
      let { cod_policy = {} } = this.returnAndCodPolicy || {}
      // 替换COD政策上下限价格
      if (cod_policy?.codDescription) {
        cod_policy.codDescription = this.replaceCodPrice(
          cod_policy?.codDescription
        )
      }
      return cod_policy
    },
    showDescQuickShipTime() {
      return !!(
        this.quickShip == 1 &&
        this.showQuickShip &&
        this.shippingAbtState?.showQuickShip &&
        this.descQuickShipTime
      )
    },
    mallCode() {
      const { productIntroData } = this
      return productIntroData?.mallCode || ''
    },
    showMallSite() {
      const { productIntroData } = this
      return productIntroData?.showMallSite || ''
    },
    mallName() {
      const { mallCode, productIntroData } = this
      return productIntroData?.detail?.mall?.[mallCode]?.mall_name || ''
    },
    // 是否允许免费退货
    allowReturn() {
      if (JSON.stringify(this.returnAndCodPolicy) == '{}') return
      const returnPolicy = this.returnAndCodPolicy?.return_policy
      if (
        Number(returnPolicy.is_return) == 1 &&
        returnPolicy.returnType !== 's1'
      ) {
        return true
      } else {
        return false
      }
    },
    isRefundAble() {
      const returnPolicy = this.returnAndCodPolicy?.return_policy
      return !!returnPolicy.is_refund_able
    },
    isNewOutTitle() {
      const returnPolicy = this.returnAndCodPolicy?.return_policy
      return !!returnPolicy.is_new_out_title
    },
    notSupportFree() {
      return this.shippingList.length
        ? this.shippingList.every(i => i.is_support_free_shipping == 0)
        : false
    },
    quickShipHighlight() {
      return (
        this.showDescQuickShipTime &&
        this.is_hide_for_local_shop &&
        this.screenAbtConfig?.['3Psellerdelivery']?.param?.[
          '3Psellerdelivery'
        ] === 'show'
      )
    },
    storeInfo() {
      const { productIntroData } = this
      return productIntroData?.storeInfo || {}
    },
    labelList() {
      const { productIntroData } = this
      return productIntroData?.labelList || []
    },
    businessModel() {
      const { productIntroData } = this
      return productIntroData?.detail?.business_model ?? 0
    },
    sellorInfoTxt() {
      const { language } = this
      const { storeType, title, tocPerformParty } = this.storeInfo
      const SHEIN = language.SHEIN_KEY_PC_25674
      const isPlatform = storeType === 1
      const sortByVal = isPlatform ? title : SHEIN
      const shipFromVal = isPlatform
        ? tocPerformParty === 1
          ? SHEIN
          : title
        : SHEIN
      const isSameVal = sortByVal === shipFromVal
      return isSameVal
        ? template(shipFromVal, language.SHEIN_KEY_PC_25670)
        : template(sortByVal, shipFromVal, language.SHEIN_KEY_PC_25669)
    },
    shippingConfig() {
      const { Deliverytooptimize } = this.screenAbtConfig
      const { deliveryDialogNTips } = this.deliveryDialogNInfo
      const showCostTopTime =
        Deliverytooptimize?.param?.Deliverytooptimize == 'optimize'
      return {
        showCostTopTime,
        deliveryDialogNTips,
        shippingStyleV1: this.shippingStyleV1
      }
    },
    showShippingGuarantee() {
      return true
    },
    shippingGuaranteeDarkBg() {
      return this.shippingAbtState.shipping === 'Type=A' ||
      this.shippingAbtState.shipping === 'typeA' ||
      this.shippingAbtState.shipping === 'typeB'
    },
    shippingGuaranteeUIStyle() {
      return 'oneLineHighLight'
    },
    showSellerIcon() {
      return this.shippingAbtState.showSellerIcon && this.hasDetailStoreSellerInfo
    },
    isUs() {
      return SiteUID === 'us'
    },
    isDsaSite() {
      return ['eur', 'euqs', 'fr', 'de', 'it', 'es', 'nl', 'pl', 'pt', 'roe', 'es', 'se', 'uk'].includes(SiteUID)
    },
    goodsId() {
      return this.productIntroData?.detail?.goods_id ?? ''
    },
    showShippingClub() {
      return !!this.sheinClubShippingSiteConfig.FREE_SHIPPING_TIMES
    },
    isUk() {
      return SiteUID && !!~SiteUID.indexOf('uk')
    },
    // 欧盟(不含uk)
    isEurUnion() {
      const sheinEurSite = ['fr', 'de', 'es', 'it', 'nl', 'se', 'eur', 'pl', 'pt', 'ch', 'roe', 'euqs']
      return sheinEurSite.includes(SiteUID)
    },

    localWarehouseText () {
      const { language, isUk, isUs, isEurUnion, skuInfo, shippingAbtState, isSupportQuickship } = this
      const showLocalwarehouse = shippingAbtState?.showLocalwarehouse
      if(!showLocalwarehouse) return ''

      const { isSomeSupportQuickshipBase, currentQuickship } = isSupportQuickship
      // 选择sku的情况下，如果当前sku不支持快速发货，不展示
      // 未选择sku的情况下，如果所有sku都不支持快速发货，不展示
      // vue3bug 空值在ssr下会报错，所以设置默认为‘ ’， https://github.com/vuejs/core/issues/7215
      if(skuInfo?.sku_code) {
        if(!currentQuickship) return ' '
      } else {
        if(!isSomeSupportQuickshipBase) return ' '
      }
      let text = ' '
      const { SHEIN_KEY_PC_31202, SHEIN_KEY_PC_31203, SHEIN_KEY_PC_31204 } = language
      if(isEurUnion) text = SHEIN_KEY_PC_31202
      if(isUk) text = SHEIN_KEY_PC_31204
      if(isUs) text = SHEIN_KEY_PC_31203
      return text
    },
  },
  watch: {
    mallCode() {
      const params = this.getCacheParams() || {}
      this.getShippingData(params)
      this.getReturnAndCodPolicy(params?.countryId)
    },
    goodsId() {
      const params = this.getCacheParams() || {}
      this.getReturnAndCodPolicy(params?.countryId)
    },
    shippingDetail(val){
      this.getShippingDataHandle(val || {})
    },
  },
  created() {
    this.getShippingDataHandle(this.shippingDetail || {})
    this.returnAndCodPolicy = this.productIntroData?.returnAndCodPolicy || {}
  },
  mounted() {
    this.shippingInit()
    // this.getSellerInfo()
  },
  methods: {
    ...mapMutations(['updateShippingDetail', 'updatePrimeShipping', 'updateFreeReturnPolicy', 'updateQuickShipInfo', 'updateGoodsFreeShippingInfo']),
    // 获取卖家信息
    async getSellerInfo() {
      const { businessModel } = this
      const { sellerSource, sellerId } = this.storeInfo
      const params = { business_model: businessModel, sellerSource, sellerId }
      schttp({
        method: 'GET',
        url: '/api/productInfo/sellerInfo/get',
        params: params
      }).then(res => {
        this.detailStoreSellerInfo = res
      })
    },
    // 替换cod上下限价格
    replaceCodPrice(text = '') {
      const { orderMinimumAmount, orderMaximumAmount, serviceCharge } =
        this.getPostCodConfigFeeInfo || {}
      const placeholders = {
        orderMinimumAmount,
        orderMaximumAmount,
        serviceCharge,
      }
      const result = text.replace(
        /\{([^}]+)\}/g,
        (match, placeholder) => placeholders[placeholder] || match
      )
      return result
    },
    async getPrimeShippingDes(country_id) {
      // 付费会员专属折扣：EXCLUSIVE_DISCOUNTS   付费会员免邮权益：FREE_SHIPPING_TIMES   付费会员N倍积分：MULTIPLE_POINTS
      const result = await schttp({
        url: '/api/productInfo/primeShipping/get',
        method: 'GET',
        params: {
          country_id,
        }
      })
      this.updatePrimeShipping({
        primeShipping: result?.info || {},
        ready: true
      })
    },
    async getQuickShipInfo(address) {
      const { storeCode } = this.storeInfo
      const { goods_sn } = this.productIntroData.detail
      const addressInfoOther = {
        state: address?.stateName || '', // 省
        stateId: address?.stateId,
        city: address?.cityName || '', // 城市
        cityId: address?.cityId,
        country: address?.countryName || '',
        countryId: address?.countryId || '', // 国家id
        siteUid: SiteUID,
      }
      const params = {
        addressInfoOther,
        scene: 'productDetail',
        mall_list: [{
          mall_code: this.mallCode,
          store_code: storeCode,
          skc_code: goods_sn,
        }]
      }
      const res = await schttp({
        url: '/api/common/getNewQsTimeLineInfoSerCon/get',
        method: 'POST',
        data: params,
      })
      let qsInfo = res?.qs_mall_list?.qs_mall_list_all?.[0] || {}
      let data = {
        info: qsInfo?.product_quickship_des || '',
        quickShipText: qsInfo?.product_quickship_tag || 'QuickShip',
        quickShipTextEn: qsInfo?.product_quickship_tag_en || 'QuickShip',
        quickShipType: qsInfo?.product_quickship_type || 1,
        ...res
      }
      this.updateQuickShipInfo(data)
    },
    async getProductFree(address) {
      const { storeCode } = this.storeInfo
      const { business_model, goods_sn, cat_id } = this.productIntroData.detail
      const params = {
        country_id: address?.countryId || '', // 国家id,
        goods_list: [{
          business_model: business_model,
          cat_id: cat_id,
          skc_code: goods_sn,
          store_code: storeCode,
          id: 1,
          mall_code: this.mallCode
        }]
      }
      const res = await schttp({
        url: '/api/productInfo/queryProductFree/get',
        method: 'POST',
        data: params,
      })
      const [goodsFreeShipingInfo = {} ] = res?.info?.goods_list || [{}]
      this.updateGoodsFreeShippingInfo(goodsFreeShipingInfo)
    },
    shippingInit() {
      this.getTspLabel(() => {
        if (this.lang !== 'in') {
          // 非 in 站取默认地址来判断运输政策
          this.getDefaultAddress()
        } else {
          // in 站查看是否有存储 PinCode 来请求运输政策
          /* 不默认取用户选择 */
          this.getShippingData() // 没用了
        }
      })

      const params = this.getCacheParams() || {}
      // this.getShippingData(params)
      this.getReturnAndCodPolicy(params?.countryId)
    },
    /**
     * 显示退换货政策详情弹窗
     */
    showReturnModal(type) {
      this.renderShippingReturnCodModal = true
      setTimeout(() => { // 保证弹窗渲染完毕后再显示 重构时可注意优化去掉这个代码
        this.returnCodModal = true
        this.modalType = type
      }, 200)
    },
    /**
     * 获取默认地址
     */
    async getDefaultAddress() {
      let [params, cacheParams] = [{}, this.getCacheParams()]
      if (cacheParams) {
        params = cacheParams
      } else {
        params = await this.getDefaultAddressData()
      }
      this.defaultAddress = params
    },
    getCacheParams() {
      const addressCookie = this.getLocalStorageAndRemoveCookie('addressCookie')
      let [cache, params] = [null, {}]
      if (addressCookie) {
        // 先读缓存
        cache = typeof addressCookie == 'string' ? JSON.parse(addressCookie) : addressCookie
        if (!this.isValidCache(cache)) cache = null
      }
      if (cache) {
        params = {
          countryId: cache.countryId,
          stateId: cache.stateId,
          cityId: cache.cityId,
          districtId: cache.districtId,
        }
      }
      return cache ? params : null
    },
    getLocalStorageAndRemoveCookie (key) {
      let cache = ''
      try {
        cache = getLocalStorage(key)
      } catch (error) {
        cache = ''
      }
      return cache
    },
    async setCacheBysetLocalStorage (key, data) {
      const res = await schttp({
        method: 'POST',
        url: '/api/common/busineseCache/update',
        data: {
          cacheData: data,
          scanType: 'addrcache'
        }
      })
      if (res?.code == '0') {
        setLocalStorage({
          key,
          value: JSON.stringify(data)
        })
      }
    },
    getDefaultAddressData() {
      return new Promise(resolve => {
        schttp({
          url: '/user/addressbook/get_limit_addr',
        }).then(res => {
          if (
            res &&
            Number(res.code) === 0 &&
            res.info &&
            res.info.address &&
            res.info.address.length
          ) {
            const addressList = res.info.address
            const filterDefaultAddress = addressList.filter(
              item => Number(item.isDefault) === 1
            ) // 筛选出默认地址
            const result = filterDefaultAddress.length
              ? filterDefaultAddress[0]
              : addressList[0]
            if (result) {
              resolve({
                countryId: result.countryId,
                stateId: result.stateId,
                cityId: result.cityId,
                districtId: result.districtId,
              })
            } else {
              resolve({})
            }
          } else {
            resolve({})
          }
        })
      })
    },
    /**
     * 切换地址后，重新请求配送信息
     */
    changeAddress(res) {

      const params = {
        countryId: res.country.id,
        countryName: res.country.country,
        stateId: res.state.id,
        stateName: res.state.name,
        cityId: res.city.id,
        cityName: res.city.name,
        districtId: res.district.id,
        districtName: res.district.name,
        isInit: res.isInit,
      }

      this.getShippingData(params, (cacheData) => {
        const {
          countryId,
          stateId,
          cityId,
          districtId,
          isInit
        } = cacheData || {}
        this.defaultAddress = {
          countryId,
          stateId,
          cityId,
          districtId,
        }
        // const cacheData = {
        //   countryId,
        //   stateId,
        //   cityId,
        //   districtId,
        //   stateName,
        //   cityName,
        //   districtName,
        //   countryName:
        //       (this.shippingList.length &&
        //         this.shippingList[0].shippingCountryName) ||
        //       '',
        // }
        this.setCache(cacheData, isInit ? '0' : '1')
      })
      if (res.country.id) {
        this.getReturnAndCodPolicy(res.country.id)
        this.getPrimeShippingDes(res.country.id)
        this.getQuickShipInfo(params)
        this.getProductFree(params)
      }
      this.cancelAddressModal()
      this.getDelayWarn(res.country.id)
    },
    clickAddressHandle() {
      this.addressSelectModal = true
    },
    /**
     * 获取tsp标签
     * 不参与政策免邮 236/239(测试)269/272(灰度/线上)
     * 不参与活动免邮 237/240(测试)270/273(灰度/线上)
     * 不参与运费活动 238/241(测试)271/274(灰度/线上)
     */
    getTspLabel(cb) {
      const TSP_LABEL = {
        shein: {
          test: [236, 237, 238],
          prod: [269, 270, 271],
        },
        romwe: {
          test: [239, 240, 241],
          prod: [272, 273, 274],
        },
      }
      const IS_PROD = location.protocol === 'https:' ? 'prod' : 'test'
      schttp({
        url: '/api/productInfo/tspLabelForShip/get',
        method: 'GET',
        params: {
          goods_list: this.productIntroData.detail.goods_id,
          label_cate_ids: TSP_LABEL[WEB_CLIENT][IS_PROD].join(','),
        },
      }).then(({ data }) => {
        this.tspLabel = {
          free_policy_limit: data?.[0]?.label_cate_ids.includes(
            TSP_LABEL[WEB_CLIENT][IS_PROD][0]
          )
            ? 1
            : 0,
          free_activity_limit: data?.[0]?.label_cate_ids.includes(
            TSP_LABEL[WEB_CLIENT][IS_PROD][1]
          )
            ? 1
            : 0,
          freight_activity_limit: data?.[0]?.label_cate_ids.includes(
            TSP_LABEL[WEB_CLIENT][IS_PROD][2]
          )
            ? 1
            : 0,
        }
        cb && cb()
      })
    },
    createComparionFun(propertyName, amount) {
      return (object1, object2) => {
        const value1 =
          (object1[propertyName] && object1[propertyName][amount]) || 0
        const value2 =
          (object2[propertyName] && object2[propertyName][amount]) || 0
        if (value1 < value2) {
          return -1
        } else if (value1 > value2) {
          return 1
        } else {
          return 0
        }
      }
    },
    /**
     * 设置物流显示状态
     */
    setShippingSummary(shippingList = [], params = {}) {
      if (!shippingList.length) {
        this.shippingSummary = {
          textType: 'empty',
          list: {},
        }
      } else {
        // 按运费shippingPrices.amount进行从低到高排序， 当aging_is_show==0（不包邮）时取运费最低的运输方式外漏展示
        shippingList.forEach(item => {
          if (item.starting_price_obj && item.last_price_obj) {
            item.lowestShippingPrices =
              Number(item.starting_price_obj.amount) >
              Number(item.last_price_obj.amount)
                ? item.last_price_obj
                : item.starting_price_obj
          }
        })
        shippingList.sort(
          this.createComparionFun('lowestShippingPrices', 'amount')
        )
        const agingIsShow = shippingList.filter(item => item.aging_is_show == 1)
        const curShipping = agingIsShow[0] || shippingList[0]
        let sortFun =
          this.shippingAbtState?.shipping === 'quick'
            ? this.sortTypeFun()
            : this.sortPriceFun()
        shippingList.sort(sortFun)
        if (curShipping.address_info) {
          const { city, district } = curShipping.address_info
          this.shippingTo = [
            city,
            district,
            curShipping.address_info.state,
            curShipping.shippingCountryName,
          ].join(' ')
          if (city) this.shippingTo = ' ' + this.shippingTo
          this.cartAddItemParams = curShipping.address_info
        } else {
          this.cartAddItemParams = params
          const { state = '', city = '', district = '' } = params
          this.shippingTo = [
            city,
            district,
            state,
            curShipping.shippingCountryName,
          ].join(' ')
          if (city) this.shippingTo = ' ' + this.shippingTo
        }
        if (curShipping.is_support_free_shipping == 0) {
          this.shippingSummary = {
            textType: 'nofree',
            list: curShipping,
          }
        } else if (agingIsShow.length) {
          this.shippingSummary = {
            textType:
              curShipping.starting_price_obj.amount <= 0 ? 'free' : 'threshold',
            list: curShipping,
          }
        } else if (shippingList[0] && shippingList[0].aging_is_show === 0) {
          this.shippingSummary = {
            textType: 'nofree',
            list: curShipping,
          }
        } else {
          this.shippingSummary = {
            textType: 'empty',
            list: {},
          }
        }
      }
    },
    /**
     * 获取延迟提示
     */
    getDelayWarn(countryId) {
      const { business_model = 0 } = this.productIntroData.detail
      schttp({
        url: '/api/productInfo/shippingDetailSimple/get',
        method: 'GET',
        params: {
          goods_sn: this.productIntroData.detail.goods_sn,
          countryId,
          business_model,
          mall_code: this.mallCode,
          productFreeId: this.goodsFreeShippingInfo?.rule_id,
          joinTransportTypes: this.goodsFreeShippingInfo?.join_transport_types,
        },
      }).then(res => {
        this.delayWarn = res
      })
    },
    /**
     * 获取物流信息
     */
    async getShippingData(queryData = {}, cacheCallback = () => {}) {
      const {
        countryId = '',
        stateName = '',
        cityName = '',
        districtName = '',
      } = queryData

      let params = {}
      const { business_model = 0, goods_sn } = this.productIntroData.detail
      if (this.lang === 'in') {
        // 印度站通过手动输入 pincode 来查询物流信息，其他站则通过默认地址查询
        if (this.currentPinCode) {
          params = {
            pincode: this.currentPinCode,
          }
        } else {
          params = {
            country_id: '100', // 印度站默认 Country ID
          }
        }
      } else {
        params = this.getCacheParams() || {}
        if (countryId) {
          params = {
            country_id: countryId,
            state: stateName,
            city: cityName,
            district: districtName,
          }
        } else {
          const countryData = await UserInfoManager.get({ key: 'Country', actionType: 'getCountryCode' })
          params = {
            country_id: countryData?.countryId || ''
          }
        }
      }
      Object.assign(params, {
        ...this.tspLabel,
      })
      const { storeCode } = this.storeInfo || {}
      const queryParams = {
        productFreeId: this.goodsFreeShippingInfo?.rule_id,
        joinTransportTypes: this.goodsFreeShippingInfo?.join_transport_types,
        business_model,
        market_scope: this.storeInfo?.salesArea,
        goods_sn,
        storeCode,
        mall_code: this.mallCode || '',
        ...params,
      }

      const cacheKey = JSON.stringify(queryParams)
      if (cacheKey == this.cacheSearchKey) return
      this.cacheSearchKey = cacheKey
      schttp({
        url: '/api/productInfo/shippingDetail/get',
        params: queryParams,
      }).then(res => {
        const cacheData = {
          ...queryData,
          countryName: res?.shippingMethods?.[0]?.shippingCountryName || '',
        }
        this.updateShippingDetail(res)
        cacheCallback?.(cacheData)
      })
    },
    getShippingDataHandle(res, address_data, params) {
      const shippingMethods = res.shippingMethods || []
      const { deliveryDialogNTitle } = this.deliveryDialogNInfo || {}
      this.descQuickShipTime = res.desc_quick_ship_time
      let storeQsAging = res.merchant_agings?.[0]
      // 如果店铺时效节点返回时效信息，优先使用店铺时效节点的信息
      if(storeQsAging?.aging_type) {
        this.descQuickShipTime = storeQsAging?.desc_replace
      }
      if(this.descQuickShipTime) {
        this.descQuickShipTime = this.descQuickShipTime.replace(/{QSname}/g, `${deliveryDialogNTitle}`)
      }
      if (shippingMethods && shippingMethods.length) {
        this.shippingList = shippingMethods.filter(
          item => Number(item.isAvailable) === 1
        )
        this.is_hide_for_local_shop = this.shippingList.some(
          item => item.is_hide_for_local_shop === 1
        )
        this.isSSR = false
        this.setShippingSummary(this.shippingList, params)
        this.handleCartAddItemEntrance()
      } else {
        this.setShippingSummary()
      }
    },
    // 判断缓存是否有效
    isValidCache(cache) {
      // 判断是否同一个用户
      if (cache.memberId != user?.member_id) return false
      // 判断是否退出登录过
      if (cache.loginTimeLast != user?.login_time_last) return false
      // 判断是否同一个站点
      if (cache.siteUid != SiteUID) return false
      return true
    },
    // 缓存数据
    setCache(item, isUserHandle = '0') {
      const realSetData = {}
      const addressCache = {}
      const addressCookie = {
        addressId: item?.addressId || '', // 详细地址id
        countryId: item?.countryId || '', // 国家id
        stateId: item?.stateId || '', // 省id
        cityId: item?.cityId || '', // 城市id
        districtId: item?.districtId || '', // 区id
        createdTime: new Date().getTime(), // 缓存生成时间
        memberId: user?.member_id || '', // 用户id
        loginTimeLast: user?.login_time_last || '', // 用户最后登录时间
        isUserHandle, // 是否为用户主动操作 是：1，否：0
        siteUid: SiteUID,
        state: item?.stateName || '', // 省
        city: item?.cityName || '', // 城市
        district: item?.districtName || '', // 区
        postcode: item?.postcode || '', // 邮编
        country: item?.countryName || '', // 国家
      }
      // 详细地址缓存... 省市区...
      if (item?.countryId) {
        addressCache[item?.countryId] = item?.countryName
      }
      if (item?.cityId) {
        addressCache[item?.cityId] = item?.cityName
      }
      if (item?.stateId) {
        addressCache[item?.stateId] = item?.stateName
      }
      if (item?.districtId) {
        addressCache[item?.districtId] = item?.districtName
      }
      Object.keys(addressCookie).forEach(val => {
        if (addressCookie[val]) {
          realSetData[val] = addressCookie[val]
        }
      })
      // setCookie({ key: 'addressCookie', value: JSON.stringify(realSetData) })
      this.setCacheBySetLocalStorage('addressCookie', realSetData)
      if (JSON.stringify(addressCache) !== '{}') {
        localStorage.setItem('address_cache', JSON.stringify(addressCache))
      } else {
        localStorage.removeItem('address_cache')
      }
    },
    async setCacheBySetLocalStorage (key, data) {
      const res = await schttp({
        method: 'POST',
        url: '/api/common/busineseCache/update',
        data: {
          cacheData: data,
          scanType: 'addrcache'
        }
      })
      if (res?.code == '0') {
        setLocalStorage({
          key,
          value: data
        })
      }
    },
    handleCartAddItemEntrance() {
      if (!this.shippingList?.length) return
      this.shippingList.some(({ full_amount, has_zero_threshold }) => {
        const reveal =
          full_amount &&
          Number(full_amount.amount) === 0 &&
          has_zero_threshold == 1
        if (Number(full_amount?.amount) > 0 || reveal) {
          return true
        }
      })
    },
    /**
     * 获取退换货信息
     */
    getReturnAndCodPolicy(country_id) {
      const { productIntroData = {} } = this
      const { detail = {} } = productIntroData
      const businessModel = detail.business_model || 0
      schttp({
        url: '/api/productInfo/returnAndCodPolicy/get',
        params: {
          cat_id: this.productIntroData.detail.cat_id,
          skc: this.productIntroData.detail.goods_sn,
          goods_id: detail?.goods_id,
          language: this.lang,
          country_id: country_id || '',
          mall_code: this.mallCode || '',
          businessModel
        },
      }).then(res => {
        this.returnAndCodPolicy = res
        // 展示 cod 政策时才刷新cod价格
        this.codPolicy?.codTitle && this.refreshCodPrice(country_id)
        this.$nextTick(() => {
          this.exposeReturnAndCodPolicy()
        })
        this.updateFreeReturnPolicy({
          freeReturnPolicy: !!(res?.canFreeReturn * 1),
        })
      })
    },
    refreshCodPrice(country_id) {
      schttp({
        url: '/api/productInfo/getCodConfigFeeCon/get',
        params: {
          countryId: country_id || '226', // 美国 226 兜底
        },
      }).then(res => {
        const { cod_service_fee = {}, cod_amount_limit = {} } = res?.info || {}
        this.getPostCodConfigFeeInfo = {
          orderMinimumAmount:
            cod_amount_limit?.min_amount?.amountWithSymbol || '',
          orderMaximumAmount:
            cod_amount_limit?.max_amount?.amountWithSymbol || '',
          serviceCharge: cod_service_fee?.amount?.amountWithSymbol || '',
        }
      })
    },
    exposeReturnAndCodPolicy() {
      if (this.returnAndCodPolicyExpose) return
      this.returnAndCodPolicyExpose = true
      this.returnPolicyText &&
        daEventCenter.triggerNotice({
          daId: '1-8-6-118',
        })
      this.codPolicy?.codTitle &&
        daEventCenter.triggerNotice({
          daId: '1-8-6-75',
        })
    },
    sortTypeFun() {
      let dic =
        this.lang !== 'ru'
          ? {
            express_shipping: 4,
            express_shoptransit: 3, // 快递店配
            stand_shipping: 2,
            shop_transit: 1,
            expedited_shipping: 0,
          }
          : {
            express_shipping: 2,
            express_shoptransit: 3, // 快递店配
            stand_shipping: 1,
            shop_transit: 4,
            expedited_shipping: 0,
          }
      return (a, b) => {
        return dic[b.type] - dic[a.type]
      }
    },
    sortPriceFun() {
      let dic = {
        express_shipping: 4,
        express_shoptransit: 3, // 快递店配
        stand_shipping: 1,
        shop_transit: 2,
        expedited_shipping: 0,
      }
      return (a, b) => {
        const value1 =
          (a['starting_price_obj'] && a['starting_price_obj']['amount']) || 0
        const value2 =
          (b['starting_price_obj'] && b['starting_price_obj']['amount']) || 0
        if (value1 === value2) {
          return dic[b.type] - dic[a.type]
        } else {
          return value1 - value2
        }
      }
    },
    async clickEnterHandle({ shippingPosition }) {
      // this.renderShippingModal = true

      if (!this.renderShippingModal) {
        this.renderShippingModal = true
        await this.$nextTick()
      }

      this.shippingPosition = shippingPosition
      this.shippingModal = true
    },
    async updateShippingModal(value) {
      if (!this.renderShippingModal) {
        this.renderShippingModal = true
        await this.$nextTick()
      }
      this.shippingModal = value
    },
    /**
     * 关闭地址选择组件
     */
    cancelAddressModal() {
      this.addressSelectModal = false
    },
    async clickReport() {

      if(!this.renderReportDialog) {
        this.renderReportDialog = true
        await this.$nextTick()
      }

      if (!isLoginFn() && !this.isUs && !this.isDsaSite) {
        return SHEIN_LOGIN.show({ cb: this.clickReport })
      }

      this.reportDialogShow = true
      daEventCenter.triggerNotice({
        daId: '1-6-1-151',
      })
    },
  },
}
</script>

<style lang="less">
/* stylelint-disable */
.product-intro {
  &__free-delay-tip {
    margin: 5px 0;
    font-size: 12px;
    color: @sui_color_gray_light1 !important;
    // 超过两行省略
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &__freeshipping {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    .clearfix();
    background-color: #f7f8fa;
    &.product-intro__bg-none {
      background: none;
    }
    .product-ship__delay {
      padding-top: 15px;
      word-break: break-word;
    }
    .product-seller-info {
      display: flex;
      padding-bottom: 15px;
      margin-top: -12px;
      color: #666666;
      margin-left: 52px;
      font-size: 12px;
      &.isNotSwiper {
        color: @sui_color_gray_light1;
      }
    }
    &.isShowSeller {
      .product-intro__freeshipping-abt {
        margin-bottom: 0;
      }
      .product-intro__freeshipping-item {
        // padding-bottom: 8px;
        // TODO CTD-11299
      }
      .product-seller-info {
        margin-top: 0;
      }
    }
  }
  .product-intro__report {
    margin-left: 6px;
    margin-right: 20px;
    color: @sui_color_link;
    white-space: nowrap;
    font-size: 12px;
    cursor: pointer;
  }
  &__unfree {
    margin: 5px 0 10px 32px;
    color: #999;
  }
  &__quickship-tip {
    padding: 0px 0 5px;
    margin-left: 32px;
    font-size: 12px;
    color: @sui_color_gray_dark2;
    &.popup {
      margin-left: 0;
      margin-bottom: 10px;
    }
    &.no-ml {
      margin-left: 0;
    }
    .tit {
      margin-bottom: 9px;
      font-size: 14px;
      color: @sui_color_gray_dark1;
      font-weight: bold;
    }
  }
  &__quickship-highlight-tip {
    margin-top: 5px;
    color: #666;
  }
  &__quickship-disclaimer {
    background: @sui_color_prompt_warning;
    padding: 12px;
    font-size: 12px;
    color: @sui_color_gray_dark1;
    margin-top: 24px;
  }
  &__bg-none {
    .product-intro__freeshipping-title {
      line-height: 22px;
    }
  }
  &__ship-to {
    padding: 20px 20px 4px 20px;
    font-weight: 700;
    font-size: 16px;
    color: #000;
    text-transform: capitalize;
    /* rw:begin */
    font-family: 'Adieu';
    font-weight: normal;
  }
  &__bg-none {
    .product-intro__freeshipping-item {
      padding-left: 0;
    }
    .product-seller-info {
      margin-left: 32px;
      margin-top: 8px;
    }
  }

  &__shiptype {
    text-transform: capitalize;
  }
  &__shipcheap {
    font-weight: 700;
    color: #222;
  }
  &__shipswiper {
    margin-left: 32px;
  }
  &__shipcost {
    color: @sui_color_safety;
    direction: ltr /* rtl: ltr */;
  }
  &__light {
    font-weight: 100;
  }
  &__freeshipping-item {
    width: 50%;
    .fl();
    padding: 12px 20px 15px 20px;
    display: table;
    cursor: pointer;
    > div {
      display: table-cell;
      // vertical-align: top;
    }
    &.full-line {
      width: 100%;
    }
    &.paddingTop-0 {
      padding-top: 0;
    }
    &.padding-bootom-0 {
      padding-bottom: 0;
    }
  }
  &__freeshipping-abt {
    margin-bottom: 15px;
    .product-intro__freeshipping-icon {
      width: auto;
    }
    .swiper-slide {
      &.only-one {
        width: 100%;
      }
      width: 240px;
      background: #f6f6f6;
      color: #767676;
      padding: 10px;
      font-size: 12px;
      height: initial;
      cursor: pointer;
    }
  }
  &__timetip {
    margin-top: 5px;
    span {
      font-weight: 700;
      color: #222;
    }
  }
  &__day-des {
    margin-top: 5px;
    color: @sui_color_gray_dark1;
  }
  &__freeshipping-icon {
    position: relative;
    width: 32px;
    .svgicon {
      font-size: 24px;
      color: #198055;
      vertical-align: middle;
    }
    .svgicon:not(.product-intro__freeshipping-more) {
      width: 22px;
      height: 22px;
    }
    p {
      display: inline-block;
      font-size: 12px;
      color: #000;
      font-weight: 700;
      padding-left: 6px;
      cursor: pointer;
    }
    .icon-gd-more {
      vertical-align: super;
    }
    .icon-left,
    .icon-right {
      font-size: 12px;
      color: #999;
      cursor: not-allowed;
      display: inline-block;
      transform: unset /* rtl: rotate(0deg) */;
      &.product-intro__shipactive {
        color: #000;
        cursor: pointer;
        font-weight: 700;
      }
    }
  }
  &__shippage {
    position: absolute;
    right: 0;
    top: 2px;
    font-size: 12px;
  }
  &__freeshipping-title {
    margin-bottom: 5px;
    color: #000;
    font-size: 12px;
    font-weight: bold;
    // padding: 6px 0;
    padding-top: 2px;
    p {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-width: 320px;
    }
    span {
      max-width: 90%;
      display: inline-block;
      vertical-align: middle;
    }
  }
  &__freeshipping-desc {
    * {
      display: inline-block;
    }
    color: #767676 /* rw: #959595*/;
    font-size: 12px;
    line-height: 1;
  }
  &__freeshipping-strong-text {
    color: #000;
  }
  &__freeshipping-time {
    display: block;
    margin-top: 5px;
  }
  &__freeshipping-time-strong {
    color: #000;
    .double-icon {
      margin-left: 2px;
    }
  }
  &__freeshipping-standard-shipstext {
    color: #000;
    margin-top: 5px;
    &.has-top {
      margin-top: 3px;
    }
  }
  &__freeshipping-n-delivery {
    color: #000;
    margin-top: 3px;
  }
  &__freeshipping-more {
    margin-left: 4px;
    font-weight: normal;
  }
  &__cart-addItem {
    margin-top: 8px;
    font-size: 12px;
    color: #666;
    cursor: pointer;
    display: flex;
    align-items: center;
    i {
      font-size: 12px;
      color: #222;
    }
  }
  .shipping-holder-4px {
    height: 8px;
  }
  .shipping-holder-20px {
    height: 20px;
  }
  .green {
    color: @sui_color_safety;
    white-space: nowrap;
  }
  .gray {
    color: @sui_color_gray_dark2;
  }
  .black {
    color: @sui_color_gray_dark1;
  }
  .medium {
    font-weight: 400;
  }
  .no-mb {
    margin-bottom: 0;
  }
  .no-pb {
    padding-bottom: 0;
  }

  // .green {
  //   color: @sui_color_safety;
  //   font-weight: normal;
  // }
}
.product-intro__freeshipping-item.no-limit {
  span {
    max-width: none;
    display: unset;
  }
}
.common-shipping-modal {
  .c-modal .modal-dialog {
    width: 700px;
  }
  .modal-content {
    padding: 50px;
  }
}
.product-ship {
  .input-error-txt {
    font-size: 12px;
  }
  &__title {
    padding-bottom: 20px;
    font-size: 16px;
    color: #000;
    font-weight: 700;
    border-bottom: 1px solid #e5e5e5;
  }
  &__content {
    padding-top: 25px;
    font-size: 12px;
    min-height: 300px;
    overflow-y: auto;
    // max-height: 500px;
    overflow-x: hidden;
    word-break: break-word;
    .green {
      color: @sui_color_safety;
      font-weight: normal;
    }
    .product-ship__more {
      margin-top: 15px;
      text-transform: capitalize;
    }
  }
  &__ship-to {
    margin-bottom: 25px;
    .detail-address-component {
      vertical-align: middle;
    }
    .country {
      margin: 0 10px;
    }
  }
  &__ship-text {
    white-space: nowrap;
    font-weight: bold;
  }
  &__ship-address {
    padding: 0 15px;
  }
  &__pincode-content {
    font-weight: bold;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    vertical-align: middle;
    .margin-r(15px);
    button {
      text-transform: capitalize;
    }
    > div {
      display: flex;
    }
  }
  &__pincode-annouce {
    margin-top: 10px;
    &.warning {
      color: #faad14;
    }
  }
  &__pincode-content-btn {
    transition: background 0.2s;
  }
  &__pincode-des {
    margin-bottom: 20px;
    font-size: 12px;
    text-align: center;
  }
  &__free-des {
    color: @sui_color_safety;
    & + .product-ship__free-des {
      margin-top: 10px;
    }
  }
  &__table {
    margin-top: 25px;
    tr {
      &.tr-head {
        background-color: #f6f6f6;
      }
    }
    td {
      padding: 12px 12px;
      border: 1px solid #e5e5e5;
      white-space: pre-line;
    }
    .title {
      white-space: normal;
      color: var(---sui_color_gray_dark2, #666);
    }
    .gray {
      color: @sui_color_gray_dark2;
    }
  }
  &__all-time {
    color: var(---sui_color_gray_dark1, #222);
  }
  &__first {
    min-width: 132px;
    max-width: 132px;
  }
  &__second {
    min-width: 299px;
  }
  &__last {
    width: 167px;
  }
  &__day-td {
    font-size: 12px;
    color: @sui_color_gray_dark1;
    i {
      margin-left: 4px;
      color: #959595;
      vertical-align: bottom;
    }
    i:hover {
      cursor: pointer;
    }
  }
  &__price-td {
    white-space: nowrap;
  }
  &__more {
    u {
      text-decoration: none;
    }
    a {
      color: @sui_color_link;
    }
  }
  &__return-content {
    > p {
      margin-bottom: 15px;
    }
    a {
      color: @sui_color_link;
    }
    .sui-color-highlight {
      color: @sui_color_highlight;
    }
  }
  &__large-text {
    margin-top: 25px;
  }
  &__costs-tips {
    margin-top: 25px;
  }
  &__selected-content {
    display: inline-block;
    vertical-align: middle;
    .address-name {
      display: flex;
      align-items: center;
      .single-country {
        margin: 0 5px;
        font-weight: bold;
      }
      .select-sec {
        position: relative;
        cursor: pointer;
        display: inline-block;
        border: 1px solid #222222;
        padding: 4px 8px;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        .padding-r(20px);
        i {
          position: absolute;
          .right(2px);
          top: 4px;
          display: inline-block;
          transform: rotate(0deg);
          transition: transform 0.2s linear;
        }
        &.active {
          i {
            transition: transform 0.2s linear;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.product-ship__all-time {
  &.inline-align {
    display: inline-flex;
    align-items: center;
  }
}
.detail-address-component {
  min-height: 410px;
  font-size: 14px;
  .address-selector {
    .header {
      color: #000;
      font-size: 16px;
      padding: 8px 0;
      font-weight: bold;
      border-bottom: 1px solid #e5e5e5;
      margin: 0 10px 15px;
    }
    .address-tab {
      white-space: normal;
      .selector-item {
        position: relative;
        display: inline-block;
        width: 290px;
        height: 50px;
        margin: 0 10px 15px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        vertical-align: bottom;
        .flex-box {
          display: flex;
          height: 100%;
          flex-flow: column;
          justify-content: center;
          cursor: pointer;
          .padding-l(12px);
          .padding-r(30px);
          .title {
            color: #999;
          }
          .title-fix {
            position: absolute;
            top: 50%;
            margin-top: -7px;
          }
          span {
            color: #222;
            font-size: 12px;
          }
          .select-value {
            border: none;
            &:focus {
              outline: none;
              -webkit-appearance: none;
              border-radius: 0;
              box-shadow: none;
            }
            &.readonly {
              cursor: pointer;
            }
          }
          > .address-icon,
          > i {
            color: #999;
            position: absolute;
            top: 50%;
            .right(12px);
            line-height: 50px;
            transition: all linear 0.2s;
            transform: translateY(-50%) rotate(0deg);
          }
        }
        &.active {
          border: 1px solid rgba(0, 0, 0, 0.8);
          color: #222;
          .flex-box {
            > .address-icon,
            > i {
              transition: all linear 0.2s;
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }
        &.disable {
          background: #f6f6f6;
          .flex-box {
            cursor: not-allowed;
          }
        }
        .address-list-box {
          .delimiter {
            background: #f5f5f5;
            color: #222;
            padding: 6px 12px;
          }
          position: absolute;
          width: 100%;
          max-height: 300px;
          overflow: auto;
          background: #fff;
          box-sizing: content-box;
          border: 1px solid #222;
          .margin-l(-1px);
          z-index: @zindex-hack;
          > li {
            position: relative;
            cursor: pointer;
            padding: 6px 12px;
            .padding-r(36px);
            color: #666;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:hover {
              background-color: #edeef0;
            }
            &.active {
              color: #000;
              font-weight: bold;
            }
            .icon-check {
              position: absolute;
              .right(12px);
              top: 4px;
            }
          }
        }
      }
    }
  }
  .c-dropdown {
    width: 200px;
    display: inline-block;
  }
}
.she-anim-visibility0 {
  visibility: hidden;
}
</style>
