<template>
  <div
    class="product-ExtraVoucherFloor"
    v-expose="{
      id:'1-8-6-225', data: {location: 1}
    }"
  >
    <div class="product-ExtraVoucherFloor__price">
      {{ discountValue }}
    </div>
    <div class="product-ExtraVoucherFloor__separation-vertical"></div>
    <div class="product-ExtraVoucherFloor__desc">
      <span class="product-ExtraVoucherFloor__desc-desc">{{ texts.extraVoucher }}</span>
      <span class="product-ExtraVoucherFloor__desc-cate">&ensp;{{ texts.forNewUeserInCatNameMulti }}</span>
    </div>
    <div class="product-ExtraVoucherFloor__circle product-ExtraVoucherFloor__circle-left"></div>
    <div class="product-ExtraVoucherFloor__circle product-ExtraVoucherFloor__circle-right"></div>
  </div>
</template>

<script setup>
/**
 * @file ExtraVoucherFloor
 * @description 首购礼金 - 商详楼层
*/
import { computed, ref } from 'vue'
const props = defineProps({
  extraVoucherPromotionInfo: {
    type: Object,
    default: () => ({})
  }
})

const discountValue = computed(() => props.extraVoucherPromotionInfo.discountAmountWithSymbol)

const texts = ref(props.extraVoucherPromotionInfo.extraVoucherFloorTypeTexts)

</script>

<style lang="less">
.product-ExtraVoucherFloor {
  background: linear-gradient(273.28deg, #FF8989 2.71%, #FF4343 97.29%);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  margin-top: 10px;
  position: relative;
  width: 100%;
  height: 30px;
  padding: 0 10px;
  border-radius: 2px;
  cursor: pointer;
  &__price{
    font-family: Arial;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.1px;
    text-align: left;
    color: #FFF;
    margin-right: 8px;
  }
  &__separation-vertical{
    height: 14px;
    width: 1px;
    background: rgba(255, 255, 255, 0.4);
    margin-right: 8px;
  }
  &__desc{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #FFF;
  }
  &__desc-desc{
    font-family: Arial;
    font-size: 12px;
    font-weight: 700;
    line-height: 13.8px;
    text-align: left;
    color: #FFF;
  }
  &__desc-cate{
    font-family: Arial;
    font-size: 12px;
    font-weight: 400;
    line-height: 13.8px;
    text-align: left;
    color: #FFF;
  }
  &__circle {
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: #FFF;
  }
  &__circle-left{
    left: -3px;
  }
  &__circle-right{
    right: -3px;
  }
}
</style>
