const mutations = {
  SET_DATA(state, payload) {
    state.context = payload
  },
  assignState(state, payload) {
    Object.assign(state, payload)
  },
  setProductIntroData(state, payload) {
    state.productIntroData = Object.assign(state.productIntroData, payload)
  },
  showTipModal(state, payload = {}) {
    const { text, show = true, type = '', twobtn = false } = payload
  
    state.tipModal = {
      text,
      type,
      show,
      twobtn,
    }
  },
  hideTipModal(state) {
    state.tipModal = {
      text: '',
      show: false,
      type: '',
      twobtn: false,
    }
  },
  showLoginModal(state, payload = {}) {
    const { type = '', originRef = null, likeType, callback = null } = payload
  
    state.loginModal = {
      show: true,
      type,
      originRef,
      likeType,
      callback,
    }
    SHEIN_LOGIN.show({
      cb: (status) => {
        this.dispatch('loginModalSucCb', status)
      },
      from: likeType == 'add' ? 'wishlist' : 'other',
    })
  },
  updateBuyBoxRecInfo(state, payload) {
    state.buyBoxRecInfo = payload
  },
  hideLoginModal(state) {
    state.loginModal = {
      show: false,
      type: '',
      originRef: null,
      likeType: '',
      callback: null,
    }
  },
  setBeforeLoginLikeType(state, payload) {
    const { likeType } = payload
    state.beforeLoginLikeType = likeType
  },
  setExclusivePromotionPrice(state, payload) {
    state.productIntroData.detail = Object.assign(
      {},
      state.productIntroData.detail,
      payload
    )
  },
  setTsp(state, payload) {
    state.tsp = payload
  },
  updateRecommendCountData(state, payload = {}) {
    state.recommendCountData = Object.assign(state.recommendCountData, payload)
  },
  updatePrimeShipping(state, payload = {}) {
    state.hotModules.shipping.primeShipping = payload.primeShipping
    state.primeShippingReady = payload.ready
  },
  updateFreeReturnPolicy(state, payload = {}) {
    state.freeReturnPolicy = payload.freeReturnPolicy
  },
  updateHot(state, payload) {
    const {
      pageInfo,
      productInfo,
      user,
      shipping,
      comment,
      promotion,
      ccc,
      pay,
      recommend,
      category
    } = payload || {}
    const { isPaidUser } = user || {}
    const { commentInfo, commentInfoLocal } = comment || {}

    state.hotModules.pageInfo.screenAbtConfig = pageInfo.screenAbtConfig || {}
    state.hotModules.pageInfo.screenAbtTspConfig = pageInfo.screenAbtTspConfig || {}
    state.hotModules.pageInfo.apolloConfigs = pageInfo.apolloConfigs || {}

    state.hotModules.productInfo.skuMapCompose.skuAllInfo = productInfo?.skuMapCompose?.skuAllInfo || {}
    state.hotModules.productInfo.sheinClubContent = productInfo?.sheinClubContent || {}
    state.hotModules.productInfo.getPrice = productInfo?.getPrice || {}
    state.hotModules.productInfo.attrSizeList = productInfo?.attrSizeList || {}
    state.hotModules.productInfo.goods_imgs = productInfo?.goods_imgs || {}

    state.hotModules.user.isPaidUser = isPaidUser
    state.hotModules.recommend.topRankingInfoNew = recommend?.topRankingInfoNew || {}
    state.hotModules.recommend.topRankingRecommendList = recommend?.topRankingRecommendList || {}

    state.hotModules.comment.commentInfo = commentInfo
    state.hotModules.comment.commentInfoLocal = commentInfoLocal

    state.hotModules.pay.afterPayInfo = pay.afterPayInfo
    state.hotModules.pay.paymentInfo = pay.paymentInfo

    state.hotModules.category.showAgeLimit = category?.showAgeLimit || false

    state.realTimeReady = true // TODO  干嘛的？ 有必要吗 yidier

    // 更新促销信息
    this.commit('updateSpecialPromotion', promotion)

    // 更新 CCC 配置
    this.commit('updateCccConfig', ccc)

    // 更新物流信息
    this.commit('updateShipping', shipping)

    setTimeout(() => {
      this.dispatch('realTimeUpdateCallBack')
    }, 500)
  },
  updateShipping(state, payload) {
    state.hotModules.shipping.freeReturnPolicy = payload?.freeReturnPolicy
    state.hotModules.shipping.primeShipping = payload?.primeShipping
    state.hotModules.shipping.quickShipInfo = payload?.quickShipInfo
    state.hotModules.shipping.sessionCountry = payload?.sessionCountry
    state.hotModules.shipping.goodsFreeShippingInfo = payload?.goodsFreeShippingInfo || null
    // state.country = payload?.country
  },
  updateSpecialPromotion(state, payload) { // 暂时这样子写，后续融合要从模型中拿
    const { newFlashPromotion, exclusivePromotionPrice, promotionInfoFromServer, promotionBadgesAndBelts } = payload
    const { badgeInfo, beltInfo, locateLabelsInfo, maskLayer } = promotionBadgesAndBelts || {}
    // state.hotModules.promotion.newFlashPromotion = newFlashPromotion
    // state.hotModules.promotion.exclusivePromotionPrice = exclusivePromotionPrice
    // state.hotModules.promotion.promotionInfoFromServer = promotionInfoFromServer

    state.badgeInfo = badgeInfo
    state.beltInfo = beltInfo
    state.locateLabelsInfo = locateLabelsInfo

    state.promotionInfoFromServer = promotionInfoFromServer
    state.newFlashPromotion = newFlashPromotion
    state.exclusivePromotionPrice = exclusivePromotionPrice
    state.maskLayer = maskLayer

  },
  updateCccConfig(state, payload) {
    const { desc, tips, cccAddCartPrompt } = payload || {}
    state.cccConfig.desc = desc
    state.cccConfig.tips = tips
    state.cccConfig.isReady = true

    state.hotModules.ccc.cccAddCartPrompt = cccAddCartPrompt
  },
  // // 更新闪购信息
  updateNewFlashPromotion(state, payload) {
    state.newFlashPromotion = payload
  },
  // // S3 会员
  updateExclusivePromotionPrice(state, payload) {
    state.exclusivePromotionPrice = payload
  },
  updateDetailStreamPart(state, { key, value }) {
    state.DETAIL_STREAM_PART[key] = value
  },
  updateAttrList(state, payload) {
    state.productIntroData.attrSizeList = payload
  },
  updateDetailMall(state, payload) {
    state.productIntroData.detail.mall = payload
  },
  updateRealtimePriceStatus(state, payload) {
    state.realtimePriceStatus = payload
  },
  updateRealtimePricePromotionStatus(state, payload) {
    state.realtimePricePromotionStatus = payload
  },
  // 更新到手价信息
  updateSkcEstimatedInfo(state, payload) {
    state.skcEstimatedInfo = payload
  },
  updateCountry(state, payload) {
    state.country = payload
  },
  updateSizeGuideCountry(state, payload) {
    state.sizeGuideCountry = payload
  },
  updateShippingDetail(state, payload) {
    state.productIntroData.shippingDetail = payload
  },
  updateHeelInfo(state, payload) {
    state.showHeelHeight = payload.showHeelHeight
    state.heelHeight = payload.heelHeight
    state.cmInchInfo = payload.cmInchInfo
  },
  updateSkuInfo(state, payload) {
    state.skuInfo = payload
  },
  updateExternalSkuCode(state, payload) {
    state.externalSkuCode = payload
  },
  updateQuickShipInfo(state, payload) {
    state.hotModules.shipping.quickShipInfo = payload
  },
  updateGoodsFreeShippingInfo(state, payload) {
    state.hotModules.shipping.goodsFreeShippingInfo = payload
  },
}
export default mutations
