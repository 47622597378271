const hotModules = {
  pageInfo: {
    screenAbtConfig: {},
    screenAbtTspConfig: {},
    apolloConfigs: {}
  },
  productInfo: {
    sheinClubContent: {},
    skuMapCompose: {
      skuAllInfo: {}
    },
    attrSizeList: {},
    getPrice: {},
    goods_imgs: {},
  },
  user: {
    isPaidUser: null
  },
  comment: {
    commentInfoLocal: {},
    commentInfo: {}
  },
  shipping: {
    goodsFreeShippingInfo: null,
    freeReturnPolicy: null,
    primeShipping: {}, // 当前站点下 付费会员物流活动信息
    sessionCountry: {},
    country: '',
    quickShipInfo: null,
  },
  promotion: {
    promotionInfoFromServer: {},
    exclusivePromotionPrice: {},
    newFlashPromotion: null,
    promotionBadgesAndBelts: {}
  },
  ccc: {
    desc: {},
    tips: '',
    cccAddCartPrompt: {}
  },
  pay: {
    afterPayInfo: {},
    paymentInfo: {}
  },
  recommend: {
    topRankingRecommendList: [],
    topRankingInfoNew: {}
  },
  category: {
    showAgeLimit: null,
  }
}

const DETAIL_STREAM_PART = {
  FIRST: false,
  SECOND: false,
  THIRD: false,
  FOURTH: false
}


const state = {
  headerVueSsr: false, // 头部是否开启ssr
  bsLibsEnvs: {}, // 头部组件上下文
  currentGoodsId: '', // 当前商品 ID
  currentGoodsSn: '', // 当前商品goodsSn
  productIntroData: {}, // 商品区域数据
  fixedRatio: null, // 详情主图比例
  language: {}, // 多语言
  lang: '',
  langPath: '',
  appLanguage: '',
  GB_cssRight: false,
  IS_RW: false,
  SiteUID: '',
  LAZY_IMG: '',
  LAZY_GREY: '',
  PUBLIC_CDN: '',
  URL_PATH: '',
  PUBLIC_IMG: '',
  WEB_CLIENT: '',
  LAZY_IMG_SQUARE: '',
  RESOURCE_SDK: null, // 资源 SDK
  MAIN_IMG_SUPPORT_CUT: false,
  isUpdateSkc: false,
  currentSelectedColor: '', // 当前选中的颜色
  quantity: 1, // 加购数量，目前仅beauty分类可以修改数量
  // notSelectedTips: false, // 是否显示未选择尺寸提示
  optionsData: [],
  colorsInfo: [],
  brandDetail: {},
  seriesDetail: {},
  brandSeriesDetail: {},
  localStoreInfo: {}, // 店铺信息
  storeRate: 0, // 店铺评分
  // 店铺关注信息
  storeFollowInfo: {
    // abt命中才展示
    show: false,
    // 用户关注状态是否变更中
    followStateChanging: false,
    // 店铺关注数量
    followCount: '',
    // 店铺30日销量（tsp）
    salesVolume: '',
    // 用户关注状态，-1: 未登录，0: 未关注，1: 已关注
    userFollowState: -1,
    // 埋点信息
    abtAnaInfo: ''
  },
  // 店铺信息是否准备完毕
  // 为等待数据渲染节点，保证埋点数据准确性
  storeAndBrandDataReady: false,
  // 评论基础数据是否加载完毕
  reviewBaseDataReady: false,
  showLocalReviews: false, // 是否只显示本地评论开关
  onlyLocalStatus: false, // 本地评论显示状态
  unfold: [],
  sizeGuideIsUnfold: false,
  descriptionIsUnfold: false,
  brandDetailUnfold: false,
  loading: false, // 数据是否加载中
  buyBoxRecInfo: [], // buyBox推荐数据
  tipModal: {
    // 提示弹窗
    text: '',
    type: '',
    show: false,
    twobtn: false
  },
  isLoginChange: false,
  loginModal: {
    // 登陆弹窗
    show: false,
    type: '',
    originRef: null,
    likeType: '',
    callback: null
  },
  isLike: false,
  beforeLoginLikeType: '', // 记录登录前点击的收藏类型，用于登录后执行收藏动作
  // 旧推荐位，第一个推荐位
  recommendListCustomersAlsoViewd: [],
  // 新推荐位，第一个推荐位
  recommendListYouMayAlsoLike: [],
  commentTranslateCache: {}, // 已翻译的评论
  commentTranslateConfig: {},
  hasFit: 0,
  translateloading: [],
  currTranslateLang: '',
  translateModel: {
    show: false,
    lang: '',
    comment_id: '',
    cb: null
  },
  realtimePriceStatus: false,
  realtimePricePromotionStatus: false, // 处理价格icon闪跳一下又消失的bug
  commentMemberSizeConfig: [],
  otherOptions: [],
  couponList: [],
  promotionLimitShow: false,
  goods_ids: [],
  allReportNum: 0, // 试用报告数量
  commentInfoNum: 0, // 当前商品的非拼接评论数量
  buyBoxTotalNum: 0, // 同款组拼接评论数量
  gradeState: [],
  isBodySize: true,
  detailHotNews: false,
  isSelectHide: false,
  showGetTheLook: true, // get the look接入abt
  updateVolume: 0,
  cccPartData: {
    // 页面模板
    moreDetailImage: 2, // 商品细节图
    colorItemStyle: 1, // color样式
    qtyStyle: 2, // 数量加减器
    sizeGuideStyle: 1, // sizeGuide的展示
    descriptionStyle: 1, // Descriptions模块的展示

    // premium模板
    premiumProductIntroGallery: 1, // 大图 (0: 默认, 1: 2+swiper)
    premiumHeadName: 1, // 商品名称 (0: 默认, 1: 加粗)
    premiumSizeDeviationTips: 1, // 尺码偏大偏小提示 (0: 展示在size标题旁, 1: 展示在size模块下方)
    premiumMaterial: 1 // Material & Wash Care模块 (0: 不展示, 1: 展示)
  },
  exclusivePromotionPrice: {},
  sheinClubUserInfo: {},
  newFlashPromotion: null,
  promotionData: [],
  promotionLoaded: false,
  badgeInfo: null,
  country: '',
  // ssrLocalCountry: '',
  sizeGuideCountry: '',
  cccConfig: {
    desc: {},
    tips: ''
  },
  isBatchTranslate: false,
  languageConfigSet: [],
  defaultLang: '',
  transltedComments: [],
  isAutoTranslate: true,
  tempComment: {},
  tackInfoDes: [],
  skcSaleAttr: [],
  saleAttrList: {
    skc_name: '',
    skc_sale_attr: [],
    sku_list: []
  },
  polyAttrSize: [],
  skuInfo: {},
  skuAttrs: [],
  // checkNotSelectedTips: 0,
  hasLocalSize: false,
  attribute: '',
  mainAttribute: '',
  externalSkuCode: '', // 初始化尺码选中
  quickShip: 0,
  mallName: '',
  mallCode: '',
  relatedGoodSizes: {},
  oneLocalSize: '',
  showHeelHeight: false, // 展示跟高强化信息，涉及到description、tips、detail外露场景
  heelHeight: '',
  cmInchInfo: '',
  heelHeightRange: '', // 会有多个地方用到格式为High Heel (5.2-5.9cm/2.1-2.3inch) 或 High Heel (5.2cm/2.1inch )
  outfitsImg: {},
  outfitActiveId: 0, // 选中锚点状态
  renderOutfitDialog: false, // 是否渲染搭配弹窗
  showOutfit: false, // 搭配弹窗打开状态
  showCouponsPickingTips: false,
  // 是否标记单条本地评论
  markSingleLocalComment: false,
  getGoodDetailFrom: 'main', // 从哪里进入的商详页面，默认main，如果从内衣尺码推荐结果 add to bag进入，则是addbag
  ssrAbtMap: {}, // 非必要不要往里面加
  // 一键购状态
  // oneClickPayState: {
  //   support: false,
  //   billInfo: null,
  //   stage: 1,
  // },
  beltInfo: {}, // 腰带信息
  locateLabelsInfo: {}, // ccc角标信息
  maskLayer: {},      // 主图蒙层
  defaultMallCode: '',
  // findSimilarFloatInfo: {
  //   show: 0,
  //   products: [],
  //   from: '',
  //   filterSize: '',
  //   mode: '',
  //   hasMoreProducts: false,
  //   showLoginModal: null,
  // },
  findSimilarApiTimeStamp: '',
  viewPromotion: false,
  recProducts: [], // about store下方的推荐商品
  storeFlashSaleInfo: [], // 店铺下方的闪购推荐商品信息
  recDrawerProducts: [], // 推荐弹窗里的推荐商品
  addOnItemProducts: {}, // 凑单弹窗里的推荐商品
  addOnitemTotal: 0,  // 凑单弹窗里的凑单推荐商品总数
  addOnitemGoodsId: '', // 缓存凑单弹窗里的凑单推荐商品id
  oftenBWProducts: {},  // 凑单弹窗里的ofbw推荐商品
  oftenBWProductsTotal: 0,  // 凑单弹窗里的ofbw推荐商品总数
  oftenBWTabs: [],  // 凑单弹窗里often bought with的tabs
  bffCouponInfo: null,
  exposedFilterList: [], // 推荐商品曝光后的过滤池
  isUseNewAddMore: false,
  showComboBuyDrawer: false, // 组合购弹窗
  showAddOnItemDialog: false, // 展示凑单弹窗
  fetchRecDataPromise: Promise.resolve('failure'),
  recDrawerDataFlag: 5, // 倒计时5秒
  recommendCountData: {
    // 推荐弹窗出现次数
    // 加车后推荐弹窗出现次数
    addCartRecommendCount: 0,
    // 收藏后推荐弹窗出现次数
    addWishRecommendCount: 0
  },
  noResetUnfold: false, // 不重置展开状态
  freeReturnPolicy: false, // 免费退货政策
  // primeShipping: {}, // 当前站点下 付费会员物流活动信息
  sizeFormatData: null, // 尺码格式化数据
  primeShippingReady: false,
  isAfterSsr: false,
  googleSEO: {}, // google seo信息
  realtimeBffInfo: {}, // bff-realtime的数据, TODO BFF - Delete - 后期pc开始中间层融合时，此处将删除
  showColorsTag: false, // 是否展示颜色标签
  realTimeBffReady: false, // bff-realtime的数据是否准备完毕

  promotionInfoFromServer: {}, // 促销直出依赖信息,
  screenAbtConfig: {}, // 客户端ABT配置

  // realTime链路数据
  hotModules,
  realTimeReady: false, // TODO  干嘛的？ 有必要吗 yidier
  skcEstimatedInfo: null,


  // 更新时机切分
  DETAIL_STREAM_PART
}

export default state
