<template>
  <div
    class="product-item__ranking-item"
    :class="`product-item__${rankingoptizAbt}`"
  >
    <p class="ranking-item__primary-wrapper">
      <img 
        :src="rankingIcon"
        class="ranking-item__img"
      />
      <span class="ranking-item__text">
        <template v-if="rankingoptizAbt === 'new'">
          {{ (language.SHEIN_KEY_PC_32674 || `No.`) + rankingList.score }} 
        </template>
        <template v-if="['plana-1', 'plana-3'].includes(rankingoptizAbt)">
          {{ '#' + rankingList.score }} 
        </template>
        {{ rankingList.rankTypeText }} 
      </span>
    </p>
    <span class="ranking-item__second">
      {{ rankingList.composeIdText }}
    </span>
    <span
      v-if="rankingoptizAbt === 'plana-2'"
      class="ranking-item__score-right"
    >
      {{ (language.SHEIN_KEY_PC_32674 || `No.`) + rankingList.score }} 
    </span>
    <span
      v-if="showRankingRecommendList"
      class="ranking-item__rank-recommend"
    >
      <span
        v-for="(item, index) in rankingRecommendList"
        :key="index"
        class="ranking-item__recommend-item"
      >
        <img
          class="ranking-item__recommend-img"
          :src="item.goods_imgs?.goods_img"
          :alt="item.goods_id"
        />
      </span>
    </span>
  </div>
</template>
  
<script>
export default {
  name: 'ProductItemRankingList',
  props: {
    rankingList: {
      type: Object, 
      default: () => ({})
    },
    rankingRecommendList: {
      type: Array, 
      default: () => ([])
    },
    language: {
      type: Object, 
      default: () => ({})
    },
    rankingoptizAbt: {
      type: String, 
      default: () => ''
    },
  },
  computed: {
    rankingIcon() {
      return ['plana-1', 'plana-2', 'plana-3'].includes(this.rankingoptizAbt) ? 'https://img.ltwebstatic.com/images3_ccc/2024/10/14/e4/17288876566be980ba8b6263cfe4d6d817ddb3493b.png' : 'https://img.ltwebstatic.com/images3_ccc/2024/09/11/44/1726040176dbfd0d416b9fca80fe1a267dd1f42fa1.png'
    },
    showRankingRecommendList() {
      return this.rankingRecommendList.length > 0 && this.rankingoptizAbt === 'plana-3'
    },
  }
}
</script>
<style lang="less">
.product-item {
  &__ranking-item {
    background: #FFF7E2;
    padding: 7px 8px;
    color: #D58700;
    width: 100%;
    font-size: 12px;
    max-width: 100%;
    display: flex;
    align-items: center;
    color: #B4810F;
    margin: 8px 0;
    border-radius: 2px;
    line-height: 1;
    height: 28px;
    .ranking-item__primary-wrapper{
      display: flex;
      align-items: center;
      flex-shrink: 0;
      color: #D58700;
      font-weight: 700;
      font-size: 12px;
      background: none;
      border-radius: 2px; 
      height: 16px;
    }
    .ranking-item__second{
      color: #D58700;
      font-size: 12px;
      flex: 1;
      .text-overflow();
      max-width: 100%;
      margin-left: 8px;
    }
    .ranking-item__img {
      width: 16px;
      height: 16px;
      margin-right: 3px;
    }
    .ranking-item__text {
      max-width: 100%;
      .text-overflow();
    }
  }
  &__plana-1, &__plana-2, &__plana-3 {
    background: #FFF3D6;
    padding: 4px 8px 4px 4px;
    .ranking-item__primary-wrapper{
      height: 20px;
      border-radius: 6px 0px;
      background: linear-gradient(0deg, #D58700 0%, #D58700 100%), linear-gradient(113deg, #F7DD9F -188.01%, #D6862A 85.3%);
      box-shadow: 0px 0.5px 0px 0px rgba(255, 255, 255, 0.20), 0px 0.5px 0.5px 0px rgba(217, 134, 48, 0.10) inset;
      padding: 0 4px;
      color: #FFF;
      font-weight: 700;
      text-transform: capitalize;
    }
    .ranking-item__score-right{
      font-style: italic;
      font-weight: 700;
      text-transform: capitalize;
      color: #D58700;
      margin-left: 8px;
    }
    .ranking-item__rank-recommend{
      padding-left: 16px;
      display: flex;
      align-items: center;
    }
    .ranking-item__recommend-item{
      width: 20px;
      height: 20px;
      display: inline-block;
      border: 1px solid #FFF;
      margin-left: -8px;
      border-radius: 50%;
    }
    .ranking-item__recommend-img{
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}
</style>
