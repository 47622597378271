import _ from 'lodash'

const filterAttrs = ['size', 'attr_id', 'attr_name', 'attr_value_id', 'attr_value_name', 'attr_value_name_en']
function descriptionDeal (config, state) {
  const productDetails = _.cloneDeep(state?.productIntroData?.detail?.productDetails)
  const temp = {}
  const result = {
    descriptionList: [],
    descriptionName: config.description == 0 ? state.language.SHEIN_KEY_PC_16389 : state.language.SHEIN_KEY_PC_14606,
    descriptionColor: false,
    goodsDesc: (state?.productIntroData?.getBeautyGoodsDesc?.goodsDesc || '').replace(/\n/g, '<br>')
  }

  if (productDetails && productDetails.length) {
    // 相同类型数据进行合并
    productDetails.forEach(item => {
      if (Number(item.attr_value_id) !== 0 && Number(item.attr_select) !== 0) {
        if (temp[item.attr_id]) {
          temp[item.attr_id].attr_value += `, ${item.attr_value}`
        } else {
          temp[item.attr_id] = item
        }
      }
    })

    // 将合并的数据对象转为数组
    const concatData = Object.keys(temp).map(i => temp[i])

    // 排序
    const sortData = concatData.sort((curr, next) => {
      if (curr && next) {
        if (Number(curr.attr_sort) === Number(next.attr_sort)) {
          return curr.attr_id - next.attr_id
        } else {
          return curr.attr_sort - next.attr_sort
        }
      }
    })
    if (config.description == 0) {
      // color 属性置顶
      const colorIndex = sortData.findIndex(item => item.attr_id == 27)
      if (colorIndex) {
        sortData.unshift(...sortData.splice(colorIndex, 1))
      }
    }
    result.descriptionList = sortData
  }

  if (config.description == 0 && result.descriptionList?.[0]?.attr_id == 27 && result.goodsDesc) {
    result.descriptionColor = true
  }

  if (config.premiumMaterial == 1) {
    let compositionIndex = result.descriptionList.findIndex(item => item.attr_id == 62)
    if (compositionIndex > -1) {
      result.descriptionList[compositionIndex].attr_name = state.language.SHEIN_KEY_PC_18030
    }
  }

  const sizeInfoDes = state?.productIntroData?.sizeInfoDes?.sizeUnit === 1 ? state.productIntroData?.sizeInfoDes?.sizeInfoInch : state?.productIntroData?.sizeInfoDes?.sizeInfo
  if (sizeInfoDes instanceof Array && sizeInfoDes.length == 1 && config.description == 0) {
    // 只取第一条的size数据
    for (let key in sizeInfoDes[0]) {
      if (!filterAttrs.includes(key)) {
        result.descriptionList.push({
          attr_name: key,
          attr_value: sizeInfoDes[0][key],
          attr_sort: 0
        })
      }
    }
  }

  return result
}

function sizeGuideDeal (config) {
  return {
    isShow: config.sizeChart == 1
  }
}

function qtyDeal (config) {
  return {
    isShow: config.countCell == 1
  }
}

function colorDeal (config) {
  return {
    hasDropPop: config.colorType == 1,
    colorItemType: config.colorType == 2 ? 'block' : 'radio',
    imgField: config.colorType == 2 ? 'goods_thumb' : 'color_image'
  }
}

function moreDetailDeal (config, state) {
  const result = {
    isNewComponent: config.detailImg == 0,
    notShow: config.detailImg == 2, // 细节图不展示 包括独立 和 合并
    list: []
  }
  if (result.isNewComponent) {
    result.list = state.productIntroData.more_goods_imgs.map(item => {
      item.origin_image = item.thumbnail = item.image_url
      return item
    })
  }

  return result
}

// function galleryDeal () {
//   // 此处生成轮播图数据，不同模版样式间的轮播图数据共享
//   return {

//   }
// }

function productIntroGalleryDeal(config) {
  return {
    isRwGallery: config.rwProductIntroGallery
  }
}

function headNameDeal(config) {
  return {
    isBold: config.premiumHeadName == 1
  }
}

// function sizeDeviationTipsDeal(config) {
//   return {
//     isUnderSize: config.premiumSizeDeviationTips == 1
//   }
// }

function materialDeal(config, state) {
  let descriptionList = descriptionDeal(config, state).descriptionList
  let materialIdList = [160, 62, 1000078, 1000067, 1000069]
  let nameList = [
    state.language.SHEIN_KEY_PC_18029,
    state.language.SHEIN_KEY_PC_18032,
    state.language.SHEIN_KEY_PC_18031,
    state.language.SHEIN_KEY_PC_18033,
    state.language.SHEIN_KEY_PC_18034,
  ]
  let materialList = []
  let composition = null
  let index

  descriptionList.forEach(descItem => {
    index = materialIdList.findIndex(materialItem => descItem.attr_id == materialItem)
    if (index >= 0) {
      materialList[index] = descItem
      descItem.name = nameList[index]
    }
  })

  // inner 和 outer 合并为 composition 展示
  if (materialList[1] || materialList[2]) {
    composition = {
      name: state.language.SHEIN_KEY_PC_18030,
      attr_value: `${materialList[1] ? `${materialList[1].name}: ${materialList[1].attr_value} ` : ''}${materialList[2] ? `${materialList[2].name}: ${materialList[2].attr_value}` : ''}`,
    }
    materialList[1] = composition
    materialList[2] = null
  }

  return {
    isShow: config.premiumMaterial == 1,
    materialList
  }
}

function descPosDeal(config, state) {
  const originDescriptionList = state.productIntroData?.detail?.productDetails || []
  const detailGoodsDesc = state.productIntroData?.detail?.goods_desc?.replace(/\n/g, '<br>')?.replace(/\s/g, ' ') || ''
  const paragraphDescModule = state.cccConfig.desc?.style?.paragraphDescModule?.toLowerCase?.()?.replace?.(/[\u4e00-\u9fa5\s]/g, '')
  const showParagraphDescription = state.cccConfig?.desc?.style?.showParagraphDescription || ''
  const result = {
    fPosData: {
      isShow: false
    },
    oPosData: [],
    filterId: [],
    descriptionImg: [],
    hiddenDescriptionModule: config.descConfig == 1 && state.cccConfig.desc?.style?.showDescriptionModule == '0',
    detailGoodsDesc: {
      html: detailGoodsDesc,
      adaText: detailGoodsDesc.replace(/<\/?.+?\/?>/g, ''),
      pos: config.descConfig != 1 ? 'description' : showParagraphDescription == '1' ? paragraphDescModule : showParagraphDescription == '0' ? 'none' : 'description'
    }
  }
  if (config.descConfig != 1) return result
  let items = state.cccConfig.desc?.items || []
  if (state.cccConfig.desc?.style?.showDescriptionModule == '1') {
    items = items.slice(0, items.length - 1)
  }
  // 坑位一至少要一个属性，坑位二三至少两个属性，坑位一属性不足时候全部不展示，分开存储坑位一和坑位二三数据，防止坑位二递进为坑位一
  items.forEach((item, index) => {
    if (index == 0) {
      if (item.moduleAttribute?.length) {
        const { textAttr, imgAttr } = getModuleAttribute(item.moduleAttribute, result.filterId, originDescriptionList)
        result.fPosData = {
          ...item,
          imgAttr,
          textAttr,
          isShow: textAttr.length + imgAttr.length > 0
        }
      } else {
        result.fPosData = {
          isShow: false,
          textAttr: [],
          imgAttr: []
        }
      }
    }
    if (index !== 0 && index < 3 && item.moduleAttribute?.length > 1) {
      const dealItem = getModuleAttribute(item.moduleAttribute, result.filterId, originDescriptionList)
      result.oPosData.push({
        ...item,
        ...dealItem
      })
    }
  })
  getDescriptionImg(result, originDescriptionList, state)
  return result
}

function getDescriptionImg (result, originDescriptionList, state) {
  let descriptionImgFilter = []
  const descriptionImg = {}
  // 主销售属性不显示图片属性
  const mainSaleAttrId = state.productIntroData?.detail?.mainSaleAttribute?.[0]?.attr_id || ''
  originDescriptionList.forEach(_ => {
    if (!result.filterId.includes(String(_.attr_id)) && _.attr_image && _.attr_id != mainSaleAttrId) {
      descriptionImgFilter.push(String(_.attr_id))
      descriptionImg[_.attr_id] = descriptionImg[_.attr_id] ? descriptionImg[_.attr_id].concat(_) : [_]
    }
  })
  result.filterId = result.filterId.concat(descriptionImgFilter)
  result.descriptionImg = Object.values(descriptionImg)
}

function getModuleAttribute (moduleAttribute, filterId, originDescriptionList) {
  const textAttr = {}
  const imgAttr = {}
  const attrIds = {}
  moduleAttribute.forEach(moduleItem => {
    if (!attrIds[moduleItem.attribute_id]) {
      attrIds[moduleItem.attribute_id] = true
      // const productDetail = originDescriptionList.find(_ => _.attr_id + '-' + _.attr_value_id == moduleItem.uniqueId) || {}
      const productDetails = originDescriptionList.filter(_ => _.attr_id == moduleItem.attribute_id) || []
      if (productDetails[0]) {
        filterId.push(String(productDetails[0].attr_id))
      }
      productDetails.forEach(productDetail => {
        if (productDetail.attr_image) {
          imgAttr[productDetail.attr_id] = imgAttr[productDetail.attr_id] ? imgAttr[productDetail.attr_id].concat(productDetail) : [productDetail]
          if (textAttr[productDetail.attr_id]) {
            delete textAttr[productDetail.attr_id]
          }
        } else {
          if (!imgAttr[productDetail.attr_id]) {
            textAttr[productDetail.attr_id] = {
              name: productDetail.attr_name,
              value: textAttr[productDetail.attr_id] ? textAttr[productDetail.attr_id].value + ', ' + productDetail.attr_value : productDetail.attr_value,
              attr_id: productDetail.attr_id
            }
          }
        }
      })
    }
  })

  // topping字段升序
  return moduleAttribute.sort((a, b) => {
    const atp = a.topping || 9999
    const btp = b.topping || 9999
    return atp - btp
  }).reduce((res, curr) => {
    if (imgAttr[curr.attribute_id]) {
      res.imgAttr.push(imgAttr[curr.attribute_id])
    } else if (textAttr[curr.attribute_id]) {
      res.textAttr.push(textAttr[curr.attribute_id])
    }
    return res
  }, { textAttr: [], imgAttr: [] })
}

export default function cccTempalteDeal (state) {
  // 默认数据，color样式组件1，无数量加减器，普通描述，细节图与banner一起展示，sizechart展示
  let config = {
    colorType: 0,
    countCell: 0,
    description: 1,
    detailImg: 1,
    imgSrc: '',
    sizeChart: 1,
    descConfig: 1 // 配置描述坑位123
  }
  // 人工模板
  if (state.productIntroData?.cccTemplateData?.tempType == 1 && state.productIntroData?.cccTemplateData?.content?.[0]) {
    config = {
      ...state.productIntroData?.cccTemplateData?.content?.[0],
      descConfig: 1
    }
  }
  // 定制化模板
  if (state.productIntroData?.cccTemplateData?.tempType == 2 && ['PERMIUM-A', 'MOTF-C', 'MOTF-D', 'MOTF-E', 'MOTF-F'].includes(state.productIntroData?.cccTemplateData?.tempCode)) {
    // 默认是PERMIUM-A
    config = {
      ...config,
      // premium模板
      premiumProductIntroGallery: state.productIntroData?.cccTemplateData?.tempCode != 'MOTF-F' ? 1 : 0,   // 大图 (0: 默认, 1: 2+swiper)
      rwProductIntroGallery: state.productIntroData?.cccTemplateData?.tempCode == 'MOTF-F',
      premiumHeadName: 1,              // 商品名称 (0: 默认, 1: 加粗)
      // FR-6246 锁定展示在size下方
      // premiumSizeDeviationTips: 1,     // 尺码偏大偏小提示 (0: 展示在size标题旁, 1: 展示在size模块下方)
      premiumMaterial: 1,              // Material & Wash Care模块 (0: 不展示, 1: 展示)
      ...(['MOTF-C', 'MOTF-E', 'MOTF-F'].includes(state.productIntroData?.cccTemplateData?.tempCode) ? { detailImg: 0 } : {}),
      descConfig: ['MOTF-D', 'MOTF-E', 'MOTF-F'].includes(state.productIntroData?.cccTemplateData?.tempCode) ? 1 : 0
    }
  }


  return {
    // galleryConf: galleryDeal(config, state),
    moreDetailConf: moreDetailDeal(config, state),
    colorConf: colorDeal(config, state),
    descriptionConf: descriptionDeal(config, state),
    sizeGuideConf: sizeGuideDeal(config, state),
    qtyConf: qtyDeal(config, state),

    productIntroGalleryConf: productIntroGalleryDeal(config, state),
    headNameConf: headNameDeal(config, state),
    // sizeDeviationTipsConf: sizeDeviationTipsDeal(config, state),
    materialConf: materialDeal(config, state),
    descPosConf: descPosDeal(config, state)
  }
}
