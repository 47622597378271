import { createRouter, createWebHistory } from 'vue-router'
import App from '../App.vue'

const { langPath } = gbCommonInfo

const routes = [
  {
    path: '/:pathMatch(.*)*-p-:goodsId(\\d+)-cat-:catId(\\d+).html',
    name: 'goods_detail_v2',
    component: App,
  },
  {
    path: '/:pathMatch(.*)*-p-:goodsId(\\d+).html',
    name: 'goods_detail_v2_other', // Vue 3 不允许一样的 name 值，采用 goods_detail_v2_other
    component: App,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: App,
    redirect() {
      location.href = `${langPath}/404.html?from=${encodeURIComponent(
        location.href
      )}`
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  to.meta.currentGoodsId = to.params.goodsId // Vue 3 必须指定命名参数
  next()
})

export default router
