import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'
import { priceDisplayTheResultsPriceValue } from 'public/src/pages/components/productItemV3/utils/priceDisplayTheResults.js'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import schttp from 'public/src/services/schttp'

/**
 * 新商卡涉及abt的配置
 */
async function getNewGoodsCardAbtConfig() {
  const abtInfo = await getUserAbtData()
  const { listwithCoupon, listwithS3, listpriceTimeTag } = abtInfo || {}
  const showS3VipPriceOnSale = listwithS3?.p?.listwithS3 === 'withS3' // S3会员价格展示优化
  const showNewStyleEstimated = listwithCoupon?.p?.listwithCoupon === 'withCoupon'  //到手价新样式
  const showEstimatedPriceOnSale =  listwithCoupon?.p?.listwithCoupon !== 'off' //命中off不展示到手价

  const showEstimatedLabelCountdown = listpriceTimeTag?.p?.listpriceTimeTag === 'TimeTagEstimated' //【在Estimated行】展示倒计时标签
  const showDiscountLabelCountdownStyle = listpriceTimeTag?.p?.listpriceTimeTag === 'TimeTagTitle'  //在标题行 展示优惠倒计时标签-last day+折扣标签

  return {
    showEstimatedLabelCountdown,
    showDiscountLabelCountdownStyle,
    showS3VipPriceOnSale,
    showNewStyleEstimated,
    showEstimatedPriceOnSale,
  }
}
/**
 * 新商卡会员相关配置
 */
function getNewGoodsCardPaidUserConfig(isPaidUser) {
  return {
    showSheinClubDiscountValue: false, // 不展示大号付费会员折扣标签
    showSheinClubDiscountValueAfterPrice: !!isPaidUser, // 不展示小号付费会员折扣标签
    showSheinClubLabelInServicesLabel: false, // 付费会员标签展示在服务标签里面
    showSheinClubPriceOnSale: !!isPaidUser, // 是付费会员，就将价格展示为付费会员价格
    showSheinClubNewLabel: !!isPaidUser, // 展示新的付费会员文字标签
  }
}

/**
 * 获取新商卡配置
 */
export async function getNewGoodsCardBuyBoxConfig(opt = {}) {
  const { IS_RW } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
  const { isPaidUser } = opt

  const _paidUserConfig = getNewGoodsCardPaidUserConfig(isPaidUser)
  const newCardAbtConfig = await getNewGoodsCardAbtConfig()
  const res = {
    ..._paidUserConfig,
    ...newCardAbtConfig,
    moduleFrom: 'buybox',
    style: {
      discountLabel: 'border'
    },
    daEventExposeId: '2-3-2',
    daEventClickId: '2-3-5',
    showABPriceOnSale: true, // 显示AB测试价格
    showDiscountLabelAfterPrice: false, // 隐藏价格右边折扣标签
    hideFlashSaleCountDown: true, // 隐藏闪购倒计时
    showSheinClubLabelInServicesLabel: false, // 付费会员标签
    disableMainimgJump: true, // 禁止跳转到商详，PC会屏蔽右键菜单
    showBeltLabel: false, // 腰带

    showAddBagBtn: true,
    showAddBagBtnBottomRight: false,
    showEstimateTag: true, // 到手价
    showEstimatedPriceReachThreshold: true, // 到手价
    showEstimatedPriceOnSale: true, // 到手价 
    showEstimatedPrice: true, // 到手价
    showNewFlashPriceOnSale: !IS_RW, // 新人闪购价格
    showMultiColor: false, // 以下信息都隐藏
    showRankingList: false,
    showDiscountLabelBeforeGoodsName: false,
    showSellingPoint: false,
    showQuickShip: true,
    showLocalSeller: true,
    showBadge: true,
    showUserBehaviorLabel: false,
    showUserCommentKeywordsLabel: false,
    showBestDealLabel: false,
    showPriceDiscountDesc: false,

    showMainImgColor: true,
    showNewFlashNewLabel: true, // 展示新的新型闪购文字标签
    showPromotion: true, // 促销
    showPromoLabel: true, // 大促标签
    hideRetailPrice: true,
    hideDiscountBadge: true,
    PriceV2LabelPosition: 'priceRight', // 价格标签位置
    isCamelCasePrice: true, // 驼峰价格
    showSheinClubDiscountValueAfterPrice: false, // 付费会员折扣标签
    showTitle: false,
    disableSlicingTagList: true, // 允许换行服务标签
  }

  if (res.showDiscountLabelCountdownStyle) {
    res.showDiscountLabelAfterPrice = true
  }

  return res
}


/**
 *  是否是付费会员
 */
export async function getIsPaidUserByClient() {
  if (typeof sessionStorage === 'undefined') return false

  const isLogin = isLoginFn()
  if (!isLogin) {
    sessionStorage.removeItem('_GET_SHEIN_CLUB_USER')
    sessionStorage.removeItem('_IS_SHEIN_CLUB_USER')
    return false
  }

  const cacheIsSheinClubUser = sessionStorage.getItem('_IS_SHEIN_CLUB_USER')
  if (cacheIsSheinClubUser) {
    return Number(cacheIsSheinClubUser) === 1
  }

  try {
    const res = await schttp({
      url: '/api/prime/postQueryMemberInfo/get',
      method: 'POST'
    })
    const isPaidUser = Number(res?.info?.is_paid) === 1
    sessionStorage.setItem('_IS_SHEIN_CLUB_USER', res?.info?.is_paid)
    sessionStorage.setItem('_GET_SHEIN_CLUB_USER', true) // 降低qps
    return isPaidUser
  } catch(e) {
    return false
  }
}

/**
 * 格式化buybox商品数据
 */
export const getFormatBuyboxProductList = (list = [], notComparePrice = false, language) => {
  if (!list.length) return []
  let lowerPrice = ''
  const _newList = list.map(item => {
    const endPrice =  priceDisplayTheResultsPriceValue(item, language)
    return {
      ...item,
      pcBroadwiseView: item?.pcStandardView,
      pretreatInfo: {
        ...(item.pretreatInfo || {}),
        pcBroadwiseView: item.pretreatInfo?.pcStandardView || {}
      },
      _formatInfo: {
        endPrice: Number(endPrice),
        count: item?.pcStandardView?.salesLabels?.count,
        isAddToCart: false,
        buyBoxCartCount: 0,
      }
    }
  })
  // 下一页数据不需要比价
  if(!notComparePrice) {
    // 处理比价逻辑
    const { lowerPriceIndex, maxSalesVolumeIndex } = _newList.reduce((pre, cur, index) => {
      if (index === 0) return pre

      // 最低价
      // 有两个相同价格的商品，则不显示最低价标签
      if (cur._formatInfo.endPrice == pre.lowerPriceAmount) {
        pre.lowerPriceIndex = -1
      }
      if (cur._formatInfo.endPrice < pre.lowerPriceAmount) {
        pre.lowerPriceIndex = index
        pre.lowerPriceAmount = cur._formatInfo.endPrice
      }

      // 最大销量
      if (cur._formatInfo.count) {
        if (cur._formatInfo.count == pre.maxSalesVolume) {
          pre.maxSalesVolumeIndex.push(index)
        }
        if (cur._formatInfo.count > pre.maxSalesVolume) {
          pre.maxSalesVolume = cur._formatInfo.count
          pre.maxSalesVolumeIndex = [index]
        }
      }
      return pre
    }, { 
      lowerPriceAmount: _newList[0]?._formatInfo?.endPrice,  // 最低价
      lowerPriceIndex: 0, // 最低价索引
      maxSalesVolume: _newList[0]?._formatInfo?.count || 0, // 最大销量
      maxSalesVolumeIndex: _newList[0]?._formatInfo?.count ? [0] : [] // 最大销量索引
    }) 
    if(lowerPriceIndex !== -1) {
      _newList[lowerPriceIndex]._formatInfo.isLowerPice = true
      if(_newList[lowerPriceIndex]._serFormatInfo) { // 商详外露用
        _newList[lowerPriceIndex]._serFormatInfo.isLowerPice = true
        lowerPrice = _newList[lowerPriceIndex]._serFormatInfo.curPrice?.amountWithSymbol || ''
      }
    }
    maxSalesVolumeIndex.forEach(index => _newList[index]._formatInfo.isTopSales = true)
  }
  return {
    _newList,
    lowerPrice,
  }
}
