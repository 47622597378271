<template>
  <!-- 为了准确知道 价格区域渲染完成时机 先这样处理 -->
  <div class="price-real-time__container">
    <slot></slot>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'PricePromotionEntry',
  mounted() {
    setTimeout(() => {
      this.updateDetailStreamPart({ key: 'SECOND', value: true })
    }, 50)
  },
  methods: {
    ...mapMutations(['updateDetailStreamPart']),
  }
}
</script>

<style lang="less" scoped>
.price-real-time__container {
  position: relative;
}
</style>
