<template>
  <div
    v-if="storeInfo && storeInfo.link"
    :class="['shop-entry_JumpToStoreBtn', type]"
    :data-is-store="true"
    :data-brand-code="storeInfo.id"
    :data-brand-type="storeInfo.type"
    :data-name="storeInfo.name"
    :data-designer-id="storeInfo.designerId"
    :data-id="storeInfo.id"
    :data-sc-id="storeInfo.scId"
    :data-abt_ana_info="extAnalysisInfo.abtAnaInfo"
    :data-brand_info="extAnalysisInfo.brandInfo"
    :data-brand_label="extAnalysisInfo.brandLabel"
    :data-flash_sale_stype="flashEntryType === 'showflashsale' ? 2 : (flashEntryType === 'showflashbutton' ? 3 : 0)"
    data-page-nm="page_goods_detail"
    da-event-click="1-8-6-60"
    @click="goToStore"
  >
    <div
      v-if="flashEntryType === 'showflashsale'"
      class="store_flash-sale"
    >
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="12" 
        height="12" 
        viewBox="0 0 12 12" 
        fill="none"
      >
        <path 
          fill-rule="evenodd" 
          clip-rule="evenodd" 
          d="M8.66039 2.5H5.30039L2.90039 6.9H5.78039L5.06039 12.1L10.1004 4.9H7.22039L8.66039 2.5Z" 
          fill="#161616"
        />
      </svg>
      <span>{{ language.SHEIN_KEY_PC_30071 || 'Flash Sale' }}</span>
    </div>

    <div 
      v-if="flashEntryType === 'showflashbutton'"
      class="with-flash-icon"
    >
      <img
        class="flash-gif-icon"
        :src="`/she_dist/images/flash-sale/flash-gif-097c8939e4.gif`"
      />
      <div
        ref="flashScrollBox"
        class="flash-scroll-wrap"
        :class="{
          'has-animation': hasAnimation
        }"
      >
        <span 
          ref="flashScrollContent"
          class="flash-scroll-item"
          :class="{
            'has-animation': hasAnimation
          }"
          :style="{'animation-duration': millisecond }"
        >
          {{ language.SHEIN_KEY_PC_31587 || 'Store Flash Sale' }}
        </span>
      </div>
    </div>
    <span
      v-else
    >
      {{ buttonText }}
    </span>
  </div>
</template>

<script setup>
/**
 * @component JumpToStoreBtn 商详跳转至店铺/品牌/系列入口按钮
 */
import { toRefs, ref, onMounted, watch, onUnmounted, nextTick } from 'vue'
import { debounce } from '@shein/common-function'
import { goToStoreCommon } from './utils'
const hasAnimation = ref(false)
const millisecond = ref('1000ms')
const flashScrollBox = ref(null)
const flashScrollContent = ref(null)
/**
 * props
 */
const props = defineProps({
  // 需要跳转的店铺 tab
  storeTab: {
    type: String,
    default: '',
  },
  // 店铺信息
  storeInfo: {
    type: Object,
    default: () => ({}),
  },
  // 店铺评分
  storeRate: {
    type: [Number, String],
    default: 0,
  },
  // 是否为店铺类型
  // * 此按钮兼容品牌和系列的跳转行为
  isStore: {
    type: Boolean,
    default: true,
  },
  // 按钮类型 link: 链接 | button: 按钮 | inline-link: 行内链接
  type: {
    type: String,
    default: '',
  },
  // 按钮文本
  buttonText: {
    type: String,
    default: ''
  },
  // 额外埋点信息，用于模块跳转事件
  extAnalysisInfo: {
    type: Object,
    default: () => ({}),
  },
  flashEntryType: {
    type: String,
    default: ''
  },
  language: {
    type: Object,
    default: () => ({}),
  },
  detail: {
    type: Object,
    default: () => ({})
  }
})
const {
  storeTab,
  storeInfo,
  // storeRate,
  type,
  buttonText,
  isStore,
  extAnalysisInfo,
  flashEntryType,
  detail,
  language
} = toRefs(props)

/**
 * methods
 */
// 跳转到店铺页 方法迁移至当前目录的utils
function goToStore() {
  const url = goToStoreCommon({
    storeInfo: storeInfo.value || {},
    isStore: isStore.value,
    storeTab: storeTab.value,
    detail: detail.value,
  })
  location.href = url
}
function handleScroll() {
  if(flashEntryType.value !== 'showflashbutton') return
  nextTick(()=>{
    const scrollWidth = flashScrollContent?.value?.clientWidth - flashScrollBox?.value?.clientWidth || 0
    if(scrollWidth > 3) {
      hasAnimation.value = true
      let minSpeed = scrollWidth > 60 ? scrollWidth : 60
      millisecond.value = `${minSpeed * 40}ms`
    }else {
      hasAnimation.value = false
    }
  })
}

const debounceFunc = debounce({ func: handleScroll, wait: 200 })
/**
 * hooks
 */
onMounted(() => {
  handleScroll()
  window.addEventListener('resize', debounceFunc)
})
onUnmounted(() => {
  window.removeEventListener('resize', debounceFunc)
})

watch(flashEntryType, () => {
  handleScroll()
})

</script>

<style lang="less" scoped>
.shop-entry_JumpToStoreBtn {
  cursor: pointer; 
  position: relative;

  &.button {
    display: block;
    line-height: 36px;
    margin-top: 12px;
    width: 100%;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #222222;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #222;
    max-width: 50%;
    padding: 0 12px;
  }

  &.link, &.inline-link {
    text-decoration: none;

    &:hover {
      color: #1860A7;
      text-decoration: underline;
    }
  }
  &.link {
    display: block;
    color: #2d68a8;
    margin-top: 8px;
  }
  &.inline-link {
    display: inline-block;
    color: #1860A7;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
  .store_flash-sale{
    position: absolute;
    display: flex;
    align-items: center;
    padding: 0 3px 0 2px;
    height: 14px;
    position: absolute;
    right: -5px;
    top: -5px;
    font-size: 12px;
    color: #000;
    z-index: 1;
    background-image: url('/she_dist/images/flash-sale/scan_flash-6a3950325b.gif');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .with-flash-icon{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    
  }
  .flash-scroll-wrap{
    overflow: hidden;
    white-space: nowrap;
    vertical-align: top;
    &.has-animation{
      position: relative;
      flex: 1;
      height: 100%;
    }
  }
  .flash-scroll-item {
    display: inline-block;
    &.has-animation{
      position: absolute;
      left: 0;
      animation: flashScroll linear 4s alternate infinite;
    }
  }
  @keyframes flashScroll {
    0% {
      left: 0;
      transform: translateX(0);
    }
    35% {
        left: 0;
        transform: translateX(0);
    }
  
    65% {
        left: 100%;
        transform: translateX(-100%);
    }
    100% {
      left: 100%;
      transform: translateX(-100%);
    }
  }

  .flash-gif-icon{
    width: 18px;
    height: 18px;
    margin-right: 4px;
    flex: 0 0 auto;
  }
}
</style>
