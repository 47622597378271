<template>
  <div
    class="product-sheinx"
    :class="[{'product-sheinx-under': positionStyle === 'underName' }, {'product-sheinx-single': positionStyle === 'single'}]"
  >
    <div class="product-sheinx__title"> 
      {{ language.SHEIN_KEY_PC_25404 }} 
    </div>
    <div 
      class="product-sheinx__designer"
      :class="{
        'product-sheinx__designer-more': designerInfo.sheinx_design_id
      }"
      @click.stop="goToDesignerPage()"
    >
      <img 
        v-if="designerInfo.avatar_pic_path"
        :src="designerInfo.avatar_pic_path" 
        class="product-sheinx__designer-avatar"
      />
      <span class="product-sheinx__designer-name">
        {{ designerInfo.designer_name }}
      </span>
      <span 
        v-if="designerInfo.ins_account_name"
        class="product-sheinx__designer-ins"
      >
        @{{ designerInfo.ins_account_name }}
      </span>
      <Icon
        v-if="designerInfo.sheinx_design_id"
        name="sui_icon_more_right_12px_1"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { Icon } from '@shein-aidc/icon-vue3'
daEventCenter.addSubscriber({ modulecode: '1-6-1' })

export default {
  name: 'ProductSheinXEnter',
  components: {
    Icon
  },
  props: {
    designerInfo: {
      type: Object,
      default: () => ({})
    },
    positionStyle: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(['language']),
  },
  mounted() {
    daEventCenter.triggerNotice({ daId: '1-6-1-152' })
  },
  methods: {
    goToDesignerPage() {
      if (!this.designerInfo.sheinx_design_id) return
      daEventCenter.triggerNotice({ daId: '1-6-1-153' })
      location.href = `/designer/${this.designerInfo.sheinx_design_id}`
    },
  }
}
</script>

<style lang="less">
.product-sheinx-under {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0;
  height: 16px;
  line-height: 16px;
  padding-right: 32px;
  overflow: hidden;
  .product-sheinx {
    &__title {
    font-size: 12px;
    flex-shrink: 0;
    margin-right: 4px;
    color: @sui_color_gray_dark3;
    }
    &__designer {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 12;
      overflow: hidden;
      .icon-announce-right-big {
        margin-left: 4px;
        color: #666;
      }
    }
    &__designer-more {
      cursor: pointer;
    }
    &__designer-avatar {
      height: 16px;
      width: 16px;
      border-radius: 50%;
    }
    &__designer-name {
      color: @sui_color_black;
      margin-left: 4px;
      .text-overflow();
    }  
    &__designer-ins {
      margin-left: 4px;
      color: @sui_color_gray_dark3;
      .text-overflow();
    }
  }
}
.product-sheinx-single {
  display: flex;
  flex-direction: row;
  height: 47px;
  line-height: 47px;
  justify-content: space-between;
  margin-top: 25px;
  overflow: hidden;
  .product-sheinx {
    &__title {
      font-size: 16px;
      color: @sui_color_black;
      font-weight: 700;
      flex-shrink: 0;
      margin-right: 12px;
      text-transform: capitalize;
    }
    &__designer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 14px;
      color: #666;
      overflow: hidden;
      .icon-announce-right-big {
        margin-left: 4px;
        color: #666;
      }
    }
    &__designer-more {
      cursor: pointer;
    }
    &__designer-avatar {
      height: 20px;
      width: 20px;
      border-radius: 50%;
    }
    &__designer-name {
      font-size: 14px;
      font-weight: 700;
      color: @sui_color_black;
      margin-left: 4px;
      .text-overflow();
    }  
    &__designer-ins {
      font-size: 12px;
      margin-left: 4px;
      color: #666;
      .text-overflow();
    }
  }
}
</style>
