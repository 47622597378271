export const blurStoreItemsNum = (num) => {
  let res = num
  try {
    num = Number(num)
    if (num > 99999) {
      res =  '99K+'
    }else if(num >= 10000) {
      res = Math.floor(num / 1000) + 'K'
    }else if(num >= 1000) {
      res = (Math.floor(num / 100) / 10) + 'K'
    }
  } catch (error) {
    console.log('error: ', error)
  }
  return res
}
