import {
  useMapGetters,
  useMapMutation,
  useMapActions,
} from 'public/src/pages/goods_detail_v2/compositions/vuex.composition.js'
import { useMapState } from '@detail/store/mapHook.js'

function getProductRecommendDrawer () {
  return import(/* webpackChunkName: "product-recommend-drawer" */ 'public/src/pages/goods_detail_v2/components/ProductRecommendDrawer/index.js').then((res) => res.default)
}
function getProductAddOnItemDrawer () {
  return import(/* webpackChunkName: "product-addItem-drawer" */ 'public/src/pages/goods_detail_v2/components/ProductAddOnItemDrawer/index.js').then((res) => res.default)
}
const useCheckAddToGroup = () => {
  // 这个 HOOK 后续可以给商详其他场景直接使用
  // 因为数据源大多是推荐相关，所以不可以混入到我们 AddBtn Vuex 中

  const { exposedFilterList, recommendCountData, addOnitemTotal, oftenBWProductsTotal, bffCouponInfo, oftenBWProducts, addOnItemProducts } = useMapState('', [
    'exposedFilterList',
    'recommendCountData',
    'addOnitemTotal',
    'oftenBWProductsTotal',
    'bffCouponInfo',
    'oftenBWProducts',
    'addOnItemProducts'
  ])
  const {
    recommendDrawerProducts,
    oftenBWTabsList,
    isHitComplianceMode,
    addOnItemCouponInfo
  } = useMapGetters([
    'recommendDrawerProducts',
    'oftenBWTabsList',
    'isHitComplianceMode',
    'addOnItemCouponInfo'
  ])
  const { updateRecommendCountData } = useMapMutation([
    'updateRecommendCountData',
  ])
  const {
    getAlsoViewRecommendlist,
    autoGetCouponAtAddBag,
    showLoginModal,
    getAddMoreRecommendlist,
    getOftenBWRecommendlist,
    getBffCouponInfo
  } = useMapActions([
    'showLoginModal',
    'getAlsoViewRecommendlist',
    'autoGetCouponAtAddBag',
    'getAddMoreRecommendlist',
    'getOftenBWRecommendlist',
    'getBffCouponInfo'
  ])

  const checkAddToGroupCallBack = async (
    { goods_id, showAddWishAfterRec, language, from = 'GoodsLike' },
    { state, next }
  ) => {
    if (!showAddWishAfterRec) return next()
    const isShowAddGroup = state.show
    await getAlsoViewRecommendlistFunc()
    if (!recommendDrawerProducts.value.length) return next()
    await getProductRecommendDrawer().then((ProductRecommendDrawer) => {
      ProductRecommendDrawer?.showModal?.({
        from,
        products: recommendDrawerProducts.value,
        language: language?.value,
        currentGoodsId: goods_id,
        isShowAddGroup,
        exposedFilterList: exposedFilterList.value,
        fetchRecData: getAlsoViewRecommendlistFunc,
        setNoInteractiveTimes,
        config: {
          isHitComplianceMode: isHitComplianceMode.value,
        },
        handleOpenLogin: addToWish => {
          window?.QuickViewInstance?.hide?.() // 隐藏快速加车
          showLoginModal({
            type: 'qv-add-wish',
            originRef: {
              addToWish,
            },
          })
        },
        // 快加车点击商品后隐藏推荐弹窗
        onProductClick: () => {
          ProductRecommendDrawer?.hideModal?.()
        },
        autoGetCouponAtAddBag: options => {
          autoGetCouponAtAddBag(options)
        },
      })
    })
    
  }

  const openRecommendDrawer = async (
    { addOnItemPopupType, res, language, goods_id, isPaidUser, from = 'AddCart' },
    { callbacks }
  ) => {
    if (oftenBWProductsTotal.value >= 20) { // 若接口返回的商品总数＜20，则整个弹窗不展示
      await getProductAddOnItemDrawer().then((ProductAddOnItemDrawer) => {
        return ProductAddOnItemDrawer?.showModal?.({
          from,
          oftenBWProducts: oftenBWProducts.value?.products || [],
          oftenBWTabsList: oftenBWTabsList.value || [],
          isCouponFit: bffCouponInfo.value?.code == 0 && addOnItemCouponInfo.value,
          bffCouponInfo: bffCouponInfo.value,
          currCouponCode: addOnItemCouponInfo.value?.couponCode || '',
          addOnItemProducts: addOnItemProducts.value?.products || [],
          addOnitemTotal: addOnitemTotal.value,
          language,
          currentGoodsId: goods_id,
          exposedFilterList: exposedFilterList.value,
          config: {
            addOnItemPopupType, // 用于判断是样式2，还是样式3
            isPaidUser,
            isHitComplianceMode: isHitComplianceMode.value,
          },
          getBffCouponInfo,
          openOnlyPromoDrawer: callbacks?.openOnlyPromoDrawer,
          getOftenBWRecommendlist,
          getAddMoreRecommendlist,
          handleOpenLogin: addToWish => {
            window?.QuickViewInstance?.hide?.() // 隐藏快速加车
            showLoginModal({
              type: 'qv-add-wish',
              originRef: {
                addToWish,
              },
            })
          },
          setNoInteractiveTimesForCart,
        })
      })
      callbacks?.hasRecommendCallback?.()
    } else {
      callbacks?.notRecommendCallback?.()
    }
    // 通知 recomendList 更新
    import('public/src/pages/goods_detail_v2/utils/eventBus.js').then(
      ({ default: eventBus }) => {
        eventBus.emit('addBagToRefreshRecomendList')
      }
    )
  }

  /**
   * 获取你可能喜欢
   * @param {*} pageNum
   * @returns
   */
  const getAlsoViewRecommendlistFunc = async (pageNum = 1) => {
    return await getAlsoViewRecommendlist(pageNum)
  }

  const setNoInteractiveTimes = (times = 0) => {
    updateRecommendCountData({
      addWishRecommendCount: times,
    })
    return times
  }
  const setNoInteractiveTimesForCart = (times = 0) => {
    updateRecommendCountData({
      addCartRecommendCount: times,
    })
    return times
  }

  return {
    checkAddToGroupCallBack,
    openRecommendDrawer,
  }
}

export default useCheckAddToGroup
