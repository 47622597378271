<template>
  <div
    v-expose="{ id: '1-8-6-118' }"
    class="product-intro__freeshipping-item full-line"
    da-event-click="1-8-6-14"
    @click="clickHandle"
  >
    <div class="product-intro__freeshipping-icon">
      <sui_icon_return_48px 
        size="24"
        color="#198055"
      />
    </div>
    <div>
      <div class="product-intro__freeshipping-title">
        <span
          tabindex="0"
          v-html="returnPolicyText.title"
        ></span>
        <Icon
          class="product-intro__freeshipping-more"
          name="sui_icon_doubt_circle_14px_1"
          color="#999"
          size="14px"
        />
      </div>
      <div class="product-intro__freeshipping-desc">
        <!-- 可退货描述 -->
        <template v-if="allowReturn">
          <span class="gray">{{ language.SHEIN_KEY_PC_14599 }}</span>
        </template>
        <template v-else-if="returnExchangeAbt && isCustomization">
          <span class="gray">{{ language.SHEIN_KEY_PC_32122 || 'Customized items cannot be returned or exchanged' }}</span>
        </template>
        <template v-else-if="isRefundAble">
          <div v-html="returnPolicyText.refund_out_title"></div>
        </template>
        <!-- 新可退文案 -->
        <template v-else-if="isNewOutTitle">
          <span class="gray">{{ language.SHEIN_KEY_PC_31119 }}</span>
        </template>
        <!-- 不可退货描述 -->
        <template v-else>
          <span class="gray">{{ returnExchangeForbidText }}</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, sui_icon_return_48px } from '@shein-aidc/icon-vue3'

export default {
  name: 'ShippingReturnPolicy',
  components: { Icon, sui_icon_return_48px },
  props: {
    returnPolicyText: {
      type: Object,
      default: () => {
        title: 'xxx'
      }
    },
    allowReturn: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default: () => {}
    },
    isRw: {
      type: Boolean,
      default: false
    },
    isNewOutTitle: {
      type: Boolean,
      default: false
    },
    returnExchangeAbt: {
      type: Boolean,
      default: false
    },
    isCustomization: {
      type: Boolean,
      default: false
    },
    returnExchangeForbidText: {
      type: String,
      default: ''
    },
    isRefundAble: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickHandle() {
      this.$emit('clickHandle', 'return')
    }
  },
  emits: ['clickHandle']
}
</script>
