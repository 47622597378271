<script>
export default {
  name: 'SizeTipsContentPromotion'
}
</script>
<script setup>
import { defineProps } from 'vue'
defineProps({
  soldOutTips: {
    type: String,
    default: ''
  },
  underPriceTip: {
    type: String,
    default: ''
  },
  flashSaleTip: {
    type: String,
    default: ''
  },
  currentMall: {
    type: Object,
    default: () => {}
  }
})
</script>

<template>
  <div class="product-intro__size-tips">
    <span 
      v-if="soldOutTips"
      v-expose="{
        id: '1-8-6-239',
        data: {
          goods_level: currentMall?.sku_large_ship !== undefined ? 'sku' : 'skc',
          label_type: currentMall?.is_absolute_low_inventory ? 1 : 2,
          location: 'attr',
        },
      }"
    >{{ soldOutTips }}&nbsp;&nbsp;</span>
    <span
      v-if="flashSaleTip"
      style="color: #999"
    >{{ flashSaleTip }}</span>
    <span
      v-if="underPriceTip"
      style="color: #999"
    >{{ underPriceTip }}</span>
  </div>
</template>

<style lang="less">
.product-intro{
  &__size-tips {
    padding: 3px 0 10px;
    font-size: 12px;
    color: @sui_color_highlight;
  }
}
</style>
