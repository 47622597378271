<script>
export default {
  name: 'SizeSelectItemTitle'
}
</script>
<script setup>
import { defineProps, defineAsyncComponent, computed, inject } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { Icon } from '@shein-aidc/icon-vue3'

const CommonLocalSizeSelect = defineAsyncComponent(() => import('public/src/pages/goods_detail_v2/components/CommonLocalSizeSelect.vue'))

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  index: {
    type: Number,
    default: 0,
  },
  localSizeComputed: {
    type: Object,
    default: () => ({}),
  },
  currentSelectedCountry: {
    type: String,
    default: '',
  },
  notSelectedTipsAnim: {
    type: Boolean,
    default: false,
  },
  notSelectedTips: {
    type: Object,
    default: () => ({}),
  },
  country: {
    type: String,
    default: '',
  },
  tipsReady: {
    type: Boolean,
    default: false,
  }
})
const config = inject('config')
const language = inject('language')
const commonFn = inject('commonFn')
const setSizeRefs = inject('setSizeRefs')
const showNewSizeLocal = computed(() => config.isNewSizeLocal && props.localSizeComputed?.hasMultiLocal && props.item?.attr_id == 87 && !props.index)
const showSizeLocal = computed(() => props.localSizeComputed?.hasLocalSize && props.item?.attr_id == config.sizeSaleAttrId && props.currentSelectedCountry)
const showSizeGuideInTitle = computed(() => config.showSizeGuideInTitle && props.item?.attr_id == config.sizeSaleAttrId)
const showErrorTip = computed(() => props.notSelectedTips?.includes(String(props.item?.attr_id)))
const currentTabLocalIsActive = computed(() =>  {
  if(typeof window == 'undefined') return false
  let lastSelectCountry = window.localStorage.getItem('last_select_country')
  if(props.currentSelectedCountry){
    return props.currentSelectedCountry === config?.localSizeList?.country_code
  }
  if(lastSelectCountry && lastSelectCountry != config?.localSizeList?.country_code ){
    return false
  }
  if (!lastSelectCountry && ['old', 'local_size_no_default'].includes(config?.defaultlocalsize)) {
    return true
  }
  return false
})
const sizeSelectClick = () => {
  daEventCenter.triggerNotice({
    daId: '1-8-6-128',
    extraData: {
      choose_type: props.currentSelectedCountry ? 1 : 0,
      style_type: config.showOnlyOneLocalSize ? 'tab' : 'list' 
    }
  })
}
const onChangeSelectCountry = (key) => {
  commonFn.onChangeSelectCountry(key)
}
</script>

<template>
  <div
    class="product-intro__size-title"
    tabindex="0"
    :aria-label="item.attr_name"
  >
    {{ item.attr_name }}
    <ClientOnly>
      <template v-if="props.tipsReady">
        <CommonLocalSizeSelect
          v-if="showNewSizeLocal"
          :value="currentSelectedCountry"
          :multi-local-size="localSizeComputed.multiLocalSize"
          :current-tab-local-is-active="currentTabLocalIsActive"
          :language="language"
          :local-size-list="config.localSizeList"
          :show-only-one-local-size="config.showOnlyOneLocalSize"
          :style="{ backgroundColor: '#F6F6F6', border: 'none', marginLeft: '5px' }"
          :default-text="language.SHEIN_KEY_PC_24641"
          :is-new-size-local="config.isNewSizeLocal"
          :swap-size-tab-position="config.swapSizeTabPosition"
          @click-handle="sizeSelectClick"
          @update:value="onChangeSelectCountry"
        />
        <span
          v-else-if="showSizeLocal"
          class="country"
        >({{ currentSelectedCountry }})</span>
      </template>
      <div
        v-if="showSizeGuideInTitle"
        class="product-intro__size-guide title"
        da-event-click="1-8-6-6"
        @click="commonFn.openSizeGuideModal(undefined, { fromScene: 'detail_title' })"
      >
        <Icon
          class="size-guide-icon"
          name="sui_icon_sizeguide_24px"
          :size="'24px'" 
          :custom-style="{ verticalAlign: 'none'}"
        />
        <span class="product-intro__size-guide-t">
          {{
            language.SHEIN_KEY_PC_16557
          }}
        </span>
      </div>
      <div
        v-show="showErrorTip"
        :ref="setSizeRefs('errorTipRef', item.attr_id)"
        class="product-intro__size-error-tips"
        tabindex="-1"
        :aria-describedby="language.SHEIN_KEY_PC_14593"
        :class="{ 'animated product-intro__shake': notSelectedTipsAnim }"
      >
        {{ language.SHEIN_KEY_PC_20101 }} {{ item.attr_name }}
      </div>
    </ClientOnly>
  </div>
</template>

<style lang="less">
.product-intro {
  &__shake{
   animation-name: goodsShake;
  }
  &__size-guide,
  &__size-check {
    display: inline-block;
    color: @sui_color_link;
    font-size: 12px;
    cursor: pointer;
    line-height: 1;
  }
  &__size-guide {
    .margin-r(15px);
    color: @sui_color_link;
    &:hover {
      .product-intro__size-guide-t {
        text-decoration: underline;
      }
    }
    .size-guide-icon {
      vertical-align: -5px;
      .margin-r(5px);
      color: @sui_color_link;
      /* rw:begin*/
      .margin-r(0);
      vertical-align: -1px;
    }
    &.title {
      font-weight: normal;
      margin-left: 20px;
    }
  }
  &__size-error-tips {
    .margin-l(10px);
    display: inline-block;
    color: #d53333;
    font-size: 13px;
    font-weight: normal;
  }
}
@keyframes goodsShake {
  from,
  to {
      transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
      transform: translate3d(-6px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
      transform: translate3d(6px, 0, 0);
  }
}
</style>
