<script>
export default {
  name: 'SizeDescContent'
}
</script>
<script setup>
import { defineProps } from 'vue'
defineProps({
  sizeDesc: {
    type: Array,
    default: () => ([])
  },
  sizeInfoTitle: {
    type: String,
    default: () => '',
  },
  oneLocalSizeDesc: {
    type: String,
    default: ''
  },
})
</script>

<template>
  <div>
    <div 
      v-if="!oneLocalSizeDesc"
      class="product-intro__size-popover-text"
    >
      {{ sizeInfoTitle }}
    </div>
    <div 
      v-else
      class="product-intro__size-one-text"
      v-html="oneLocalSizeDesc"
    >
    </div>
    <ul
      v-for="desc in sizeDesc"
      :key="desc.name"
      class="product-intro__size-popover-list"
      :class="{
        morethan3: sizeDesc.length > 3,
        lessthan3: (sizeDesc.length > 1 && sizeDesc.length <= 3) || (sizeDesc.length == 1 && sizeDesc[0].name),
      }"
    >
      <li
        v-if="desc.name && sizeDesc.length > 1"
        class="product-intro__size-popover-title"
      >
        {{ desc.name }} -
      </li>
      <li
        v-for="subDesc in desc.list"
        :key="subDesc.k"
        class="product-intro__size-popover-li"
      >
        {{ subDesc.k }}:
        <span>{{ subDesc.val }}</span>
      </li>
    </ul>
    <slot name="footer"></slot>
  </div>
</template>

<style lang="less">
.product-intro {
  &__size-popover-text {
    padding-bottom: 5px;
    font-size: 12px;
    font-weight: 600;
    color: #222;
  }
  &__size-one-text{
    color: #222;
    padding-bottom: 5px;
  }
  &__size-popover-list {
    font-size: 12px;
    margin-bottom: 4px;
    &.morethan3 {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &.lessthan3 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    li {
      display: inline;
      word-break: break-word;
      color: #222;
      line-height: 14px;
    }
    span {
      color: #767676 /* rw:#959595 */;
      padding-right: 2px;
    }
    span::after {
      content: ",";
    }
  }
  &__size-popover-titleL {
    color: #222;
    display: inline-block;
  }
  &__size-popover-li:last-of-type {
    span::after {
      content: ".";
    }
  }
}
</style>
