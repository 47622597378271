<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 10H7V7H10V5H7V2H5V5H2V7H5V10Z"
      fill="#222222"
    />
  </svg>
</template>
