import { onMounted, nextTick } from 'vue'
import { transformImg, preloadImgs } from '@shein/common-function'
import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'

import dprInsertMixins from '@detail/utils/dprInsertMixins'
const dprInsert = dprInsertMixins.methods.dprInsert

export default function useColorBox({ colorList, loadConf }) {
  // colorList 颜色图片预加载
  const preloadColorsMainImg = async () => {
    if (colorList.value.length < 2) return
    const goodsIdList = colorList.value.reduce((res, curr) => {
      res.push({ goods_id: curr.goods_id })
      return res
    }, [])
    const res = await itemSer.getAtomicInfo({
      goods: goodsIdList,
      fields: { detailImage: true },
    })
    const imgList = []
    const { size, num, mode } = loadConf
    res.forEach(item => {
      const { main_image = {}, detail_image = [] } = item
      if (main_image?.origin_image) {
        imgList.push(
          transformImg({
            img: dprInsert(item.main_image.origin_image, size, mode),
          })
        )
      }
      if (detail_image?.length) {
        for (let i = 0; i < num; i++) {
          imgList.push(
            transformImg({
              img: dprInsert(item?.detail_image?.[i]?.origin_image, size, mode),
            })
          )
        }
      }
    })

   
    nextTick(() => {
      preloadImgs({ imgs: imgList })
    })
  }

  // 落地后预取
  onMounted(() => {
    setTimeout(() => {
      preloadColorsMainImg()
    }, 2000)
  })
}
