<script>
export default {
  name: 'SizeTipsContent'
}
</script>
<script setup>
import { defineProps, inject, computed, watch } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import SizeTipsContentPromotion from '../SizeTipsContentPromotion/index.vue'
import { Icon, sui_icon_sizeguide_24px, sui_icon_more_right_14px_1 } from '@shein-aidc/icon-vue3'
const { GB_cssRight } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : { }

const props = defineProps({
  tipsInfo: {
    type: Object,
    default: () => ({})
  },
  sizeRecommendData: {
    type: Object,
    default: () => ({})
  },
  recommendMySizeText: {
    type: String,
    default: '',
  },
  goodsId: {
    type: String,
    default: '',
  },
  hideOneSize: {
    type: Boolean,
    default: false,
  },
  editMySize: {
    type: Function,
    default: () => {},
  },
  currentMall: {
    type: Object,
    default: () => {}
  },
  realTimeReady: {
    type: Boolean,
    default: false,
  },
  hydratedFinished: {
    type: Boolean,
    default: false,
  }
})
const language = inject('language')
const config = inject('config')
const commonFn = inject('commonFn')

const openReviews = () => {
  if(config.from === 'detail') {
    daEventCenter.triggerNotice({
      daId: '1-8-6-137',
      extraData: {
        type: 3
      }
    })
    const dom = document.querySelector('.common-reviews')
    if (dom){
      let y = dom.getBoundingClientRect().y
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const headerHeight = document.querySelector('.j-c-header1').offsetHeight
      if (y < headerHeight) {
        y = y - headerHeight
      }
      window.scrollTo({
        behavior: 'smooth',
        top: y + scrollTop
      })
    }
  }
}
const showPromotionTips = computed(() => {
  const { promotionTips, soldOutTips } = props.tipsInfo
  const { underPriceTip, flashSaleTip } = promotionTips || {}
  return soldOutTips || underPriceTip || flashSaleTip
})
const showTipsInfo = computed(() => {
  const isShowTips = props.tipsInfo.trueToSizeStrongText && config?.tipsshow && config?.tipsshow === 'default'
  return isShowTips
})
watch(
  () => props.realTimeReady,
  async (val) => {
    if(val) {
      if (props.tipsInfo?.tipsLog) {
        if (props.tipsInfo.heelHeightInfo) {
          daEventCenter.triggerNotice({
            daId: '1-8-6-251',
            extraData: {
              tips_from: 'heel',
            },
          })
        }
        if (props.tipsInfo.tipsOrigin) {
          daEventCenter.triggerNotice({
            daId: '1-8-6-251',
            extraData: {
              tips_from: props.tipsInfo.tipsOrigin
            },
          })
        }
      }

    }
  },
  {
    immediate: true,
  }
)
const openSizeGuideFromSizeTips = () => {
  if (props.tipsInfo.sizeTipsText) {
    commonFn.openSizeGuideModal('sizeTips', {
      fromScene: 'detail_tips'
    })
  }
}

</script>

<template>
  <SizeTipsContentPromotion 
    v-if="tipsInfo.promotionTips.promotionTipsLocation == 'top' && showPromotionTips && hydratedFinished"
    :sold-out-tips="tipsInfo.soldOutTips"
    :under-price-tip="tipsInfo.promotionTips.underPriceTip"
    :flash-sale-tip="tipsInfo.promotionTips.flashSaleTip"
    :current-mall="currentMall"
  />
  <span
    v-if="tipsInfo.showSizeTips && config?.tipsshow && config?.tipsshow === 'default'"
    class="product-intro__size-deviation product-intro__size-deviation_below"
    @click="openSizeGuideFromSizeTips"
  >
    {{ tipsInfo.sizeDeviationTips }}
    <span
      v-if="tipsInfo.heelHeightInfo"
      dir="ltr"
    >
      {{ tipsInfo.heelHeightInfo }}
    </span>
    <i
      v-show="tipsInfo.sizeTipsText"
      v-expose="tipsInfo.sizeTipsExpose || {}"
      class="product-intro__size-deviation-icon svgicon svgicon-arrow-down right"
    ></i>
    <span
      v-if="showTipsInfo"
      v-expose="tipsInfo.trueToSizeStrongExpose || {}"
      class="product-intro__undersize-deviation-tipsstrong"
      :class="{'product-intro__start-line': !!tipsInfo?.sizeDeviationTips?.trim(), 'cursor-pointer': config.from === 'detail'}"
      @click.stop.prevent="openReviews"
    >
      <span class="tipsstrong-span">
        <Icon 
          class="like-icon"
          name="sui_icon_like_18px" 
          size="16px"
          :custom-style="{ verticalAlign: GB_cssRight ? 'sub' : 'text-bottom'}"
        />{{ tipsInfo.trueToSizeText }}
      </span>
      {{ tipsInfo.trueToSizeStrongText }}
      <sui_icon_more_right_14px_1
        v-if="config.from == 'detail'" 
        size="14px" 
        :is-rotate="GB_cssRight"
      />
    </span>
  </span>
  <div
    v-if="!hideOneSize && config.showSizeGuideInTips || sizeRecommendData.showSelfRecommend"
    class="product-intro__size-help"
  >
    <div 
      v-if="config.showSizeGuideInTips"
      v-tap="{ id: '1-8-6-6', data: [{ goodsId, from: 'other' }]}"
      v-expose="{ id: '1-8-6-139', data: { goodsId, from: 'other' }}"
      class="product-intro__size-guide"
      @click="() => commonFn.openSizeGuideModal(undefined, { fromScene: 'detail_title' })"
    >
      <sui_icon_sizeguide_24px
        class="size-guide-icon"
        :size="IS_RW ? '12px' : '24px'" 
        :custom-style="{ verticalAlign: 'none'}"
      />
      <span class="product-intro__size-guide-t">
        {{
          language.SHEIN_KEY_PC_16557
        }}
      </span>
    </div>
    <a 
      v-if="sizeRecommendData.showSelfRecommend"
      class="product-intro__size-rec"
      @click="editMySize"
    >
      <span class="button-text">
        <template v-if="recommendMySizeText">
          {{ language.SHEIN_KEY_PC_19348 }}
          <b>{{ recommendMySizeText }}</b>
        </template>
        <template v-else>{{ language.SHEIN_KEY_PC_19355 }}</template>
      </span>
    </a>
  </div>
  <SizeTipsContentPromotion 
    v-if="tipsInfo.promotionTips.promotionTipsLocation == 'bottom' && showPromotionTips && hydratedFinished"
    :sold-out-tips="tipsInfo.soldOutTips"
    :under-price-tip="tipsInfo.promotionTips.underPriceTip"
    :flash-sale-tip="tipsInfo.promotionTips.flashSaleTip"
    :current-mall="currentMall"
  />
</template>

<style lang="less">
.product-intro {
  &__size-deviation {
    display: inline-block;
    margin: 0 10px;
    color: #666;
    font-size: 12px;
    line-height: normal;
    &_below {
      margin: 0 0 8px 0 !important;
    }
  }
  &__size-tips-bold{
    font-weight: bold;
    color: #000000;
  }
  &__size-deviation-icon {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    font-size: 14px;
  }
  &__undersize-deviation-tipsstrong {
    display: inline;
    align-items: center;
    font-size: 12px;
    line-height: normal;
    .tipsstrong-span {
      color: #C44A01;
      // color: @sui_color_micro_emphasis;
      margin-right: 2px;
    }
    .like-icon {
      margin-right: 2px;
    }
    &.cursor-pointer {
      cursor: pointer;
    }
  }
  &__start-line {
    display: flex;
    align-items: center;
    margin-top: 2px;
  }
  &__size-help {
    padding: 2px 0 12px;
  }
  &__size-guide,
  &__size-check {
    display: inline-block;
    color: @sui_color_link;
    font-size: 12px;
    cursor: pointer;
    line-height: 1;
  }
  &__size-guide {
    .margin-r(15px);
    color: @sui_color_link;
    &:hover {
      .product-intro__size-guide-t {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .size-guide-icon {
      vertical-align: -7px;
      .margin-r(5px);
      color: @sui_color_link;
      /* rw:begin*/
      .margin-r(0);
      vertical-align: -1px;
    }
    &.title {
      font-weight: normal;
      margin-left: 20px;
    }
  }
  &__size-rec {
    cursor: auto;
    width: auto;
    margin-left: 0px;
    margin-bottom: 0px;
    transform-origin: 0px 0px;
    opacity: 1;
    transform: scale(1, 1);
    .button-text {
      display: inline-block;
      font-size: 12px;
      cursor: pointer;
      color: @sui_color_link;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
