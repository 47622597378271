<template>
  <div 
    class="product-parallelimport"
    @click="jumpTo"
    v-html="parallelimportText"
  ></div>
</template>
  
<script>
import { mapState, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { template } from '@shein/common-function'
  
export default {
  name: 'ParallelImport',
  computed: {
    ...mapState(['language', 'currentGoodsId', 'productIntroData']),
    ...mapGetters(['mallCode']),
    parallelimportText() {
      const { SHEIN_KEY_PC_28820, SHEIN_KEY_PC_28821, SHEIN_KEY_PC_28822 } = this.language || {}
      return `<div id="ParallelImportBox" class="ParallelImportBox">${template(`<span style="color: #2D68A8;cursor: pointer;">${SHEIN_KEY_PC_28821}</span>`, SHEIN_KEY_PC_28820)} ${SHEIN_KEY_PC_28822}</div>`
    },
    parallelimportExposeInfo() {
      return { 
        goods_id: this.currentGoodsId,
        mallCode: this.mallCode,
      }
    },
  },
  watch: {
    currentGoodsId: {
      handler() {
        daEventCenter.triggerNotice({ daId: '1-8-6-196', bindData: this.parallelimportExposeInfo })
      },
      immediate: true,
    },
  },
  methods: {
    jumpTo(e) {
      if (e?.target?.id !== 'ParallelImportBox') {
        daEventCenter.triggerNotice({ daId: '1-8-6-195', bindData: this.parallelimportExposeInfo })
        window.open(`/product/article/2008`)    
      }
    },
  }
}
</script>
  
<style lang="less">
.product-parallelimport {
    margin-top: 25px;
    padding: 10px;
    background: #F7F8FA;
    color: #999;
    font-size: 12px;
}
</style>
  
