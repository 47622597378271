<template>
  <div class="promotion-text-box">
    <template
      v-for="(item, index) in activityMergeCouponDataListFormat"
      :key="index"
    >
      <ProductIntroHeadPromotionNewTextSpecialItem
        v-if="item.isSpecial"
        class="promotion-text-box__itemForCalc"
        :expose-config="item.exposeConfig"
        :tap-config="item.tapConfig"
        :text="parsePercentText(item.text)"
      />
      <span
        v-else
        class="promotion-text-box__itemForCalc promotion-text-box_item"
        :class="{
          'best-pay': item?.isBestPay && item.icon
        }"
        v-html="parsePercentText(item.text)"
      ></span>
    </template>
  </div>
</template>
<script>
import ProductIntroHeadPromotionNewTextSpecialItem from 'public/src/pages/goods_detail_v2/components/ProductIntroHeadPromotionNewTextSpecialItem.vue'
import { parsePercentText } from 'public/src/pages/goods_detail_v2/utils/ar.js'

function getMaxShowIndex(wrapWidth, domList = [], minWidth = 23) {
  if (wrapWidth <= 0 || !domList?.length) return []

  const marginRightString = window.getComputedStyle(domList[0])?.marginRight ?? ''
  const domMarginRight = Math.ceil(marginRightString.replace('px', ''))

  let widthCumulativeValue = wrapWidth
  let lastIndex = 0
  for (let i = 0; i < domList.length; i ++) {
    const element = domList[i]
    const isLastIndex = i === domList.length - 1
    const elWidth = isLastIndex ? element.offsetWidth : element.offsetWidth + domMarginRight

    const surplus =  widthCumulativeValue - elWidth
    // 剩余宽度小于最小宽度，直接跳出循环
    if (minWidth && minWidth > surplus) {
      lastIndex = i
      break
    }

    if (surplus >= 0) {
      lastIndex = i
      widthCumulativeValue = surplus
      continue
    }

    lastIndex = i
    break
  }

  return lastIndex
}

export default {
  name: 'ProductIntroHeadPromotionNewText',
  components: { ProductIntroHeadPromotionNewTextSpecialItem },
  props: {
    activityMergeCouponDataList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      maxShowIndex: -1,
      unWatchData: () => {},
      parsePercentText
    }
  },
  computed: {
    activityMergeCouponDataListFormat() {
      if (this.maxShowIndex == -1) return this.activityMergeCouponDataList
      return this.activityMergeCouponDataList.slice(0, this.maxShowIndex + 1)
    }
  },
  mounted() {
    this.unWatchData = this.$watch('activityMergeCouponDataList.length', () => {
      this.calcShowTextData()
    }, {
      immediate: true,
    })
  },
  beforeUnmount() {
    this.unWatchData?.()
  },
  methods: {
    /**
     * 计算宽度是否能在一行展示，不够则截断
     */
    calcShowTextData() {
      this.maxShowIndex = -1
      this.$nextTick(() => {
        const { width } = this.$el.getBoundingClientRect()
        const tagListDom = this.$el.querySelectorAll('.promotion-text-box__itemForCalc')
        const maxShowIndex = getMaxShowIndex(width, tagListDom)
        this.maxShowIndex = maxShowIndex
      })
    },
  }
}
</script>

<style lang="less">
.base-text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@payIconwidth: 21px;
@payIconPaddingSize: 2px;
.promotion-text-box {
    display: flex;
    position: relative;
    box-sizing: content-box;
    margin-right: auto;
    color: #c44a01;
    font-size: 12px;
    .base-text-ellipsis();
    &_item {
        padding: 0 4px;
        border: 0.5px solid #FA6338;
        color: #FA6338;
        background: #FFF;
        margin-right: 6px;
        border-radius: 2px;
        line-height: 18px;
        height: 18px;
        position: relative;
        /*rw:begin*/
        border: 0.5px solid #fc4070;
        color: #fc4070;
        /*rw:end*/
        &:last-child {
            margin-right: 0;
            .base-text-ellipsis();
        }
        &.best-pay{
          // display: flex;
          // align-items: center;
          // padding-right: 2px;
          padding-right: calc(@payIconwidth + @payIconPaddingSize * 2);
        }
        .payment-icon{
          // width: 21px;
          // max-height: 14px;
          // margin-left: 2px;
          width: @payIconwidth;
          max-height: 14px;
          position: absolute;
          right: @payIconPaddingSize;
          top: 50%;
          transform: translateY(-50%);
        }
    }
}
</style>
