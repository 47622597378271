<script>
export default {
  name: 'OtherContentComp'
}
</script>
<script setup>
import { defineProps, defineAsyncComponent, computed, inject, ref } from 'vue'
import { LazyHydrationWrapper } from '@shein-aidc/shein-lazy-hydration'
import SizeDescContent from '../SizeDescContent/index.vue'
import SizeTipsContent from '../SizeTipsContent/index.vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
const ProductFindSrimilarFloatContent = defineAsyncComponent(() => import('public/src/pages/goods_detail_v2/components/ProductFindSrimilarFloat/ProductFindSrimilarFloatContent.vue'))
const props = defineProps({
  findSimilarBind: {
    type: Object,
    default: () => ({})
  },
  sizeInfoIndex: {
    type: Object,
    default: () => ({}),
  },
  bubbleInfoComputed: {
    type: Object,
    default: () => ({}),
  },
  tipsInfo: {
    type: Object,
    default: () => ({}),
  },
  sizeRecommendData: {
    type: Object,
    default: () => ({}),
  },
  recommendMySizeText: {
    type: String,
    default: '',
  },
  goodsId: {
    type: String,
    default: '',
  },
  hideOneSize: {
    type: Boolean,
    default: false,
  },
  editMySize: {
    type: Function,
    default: () => {},
  },
  currentMall: {
    type: Object,
    default: () => ({}),
  },
  realTimeReady: {
    type: Boolean,
    default: false
  },
  tipsReady: {
    type: Boolean,
    default: false
  }
})

const sizeTipsContentHydrated = ref(false)
const config = inject('config')
// 售罄推荐列表
const showFindSimilarFloat = computed(() => {
  return ['detail', 'quickAdd'].includes(config.from) && props.findSimilarBind?.show && props.findSimilarBind?.mode === 'module'
})
const sizeItem = computed(() => props.sizeInfoIndex?.[config.sizeSaleAttrId] || {})
const showSizeOuter = computed(() => sizeItem.value?.attr_value_id && config.sizeInfoAtOuter)
const sizeDesc = computed(() => props.bubbleInfoComputed?.sizeDescObj?.[sizeItem.value?.attr_value_name_en])



const onSizeTipsContentHydrated = () => {
  sizeTipsContentHydrated.value = true
}

</script>

<template>  
  <SizeDescContent 
    v-if="showSizeOuter"
    class="product-intro__size-outer"
    :size-info-title="bubbleInfoComputed.sizeInfoTitle"
    :size-desc="sizeDesc"
  />
  <ClientOnly>
    <ProductFindSrimilarFloatContent
      v-if="showFindSimilarFloat"
      v-bind="findSimilarBind"
    />
  </ClientOnly>

  <LazyHydrationWrapper 
    :when-triggered="tipsReady"
    @hydrated="onSizeTipsContentHydrated"
  >
    <SizeTipsContent
      :real-time-ready="realTimeReady"
      :tips-info="tipsInfo"
      :size-recommend-data="sizeRecommendData"
      :recommend-my-size-text="recommendMySizeText"
      :goods-id="goodsId"
      :hide-one-size="hideOneSize"
      :edit-my-size="editMySize"
      :current-mall="currentMall"
      :hydrated-finished="sizeTipsContentHydrated"
    />
  </LazyHydrationWrapper>
</template>
