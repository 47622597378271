<template>
  <div
    v-if="goodsDesc"
    class="goods-color__desc-box"
  >
    <p
      ref="context"
      class="context-box"
      :class="{ fold: showFold && foldState }"
    >
      <span
        class="context"
        v-html="goodsDesc"
      ></span>
      <span
        v-if="showFold"
        v-show="foldState"
        class="unfold-btn"
        @click="onUnfold"
      >
        ...
        <span class="btn-text">{{ language.SHEIN_KEY_PC_19640 }}</span>
      </span>
    </p>
    <p
      v-if="showFold"
      v-show="!foldState"
      class="fold-btn-box"
    >
      <span
        class="fold-btn"
        @click="onFold"
      >
        {{ language.SHEIN_KEY_PC_19642 }}
      </span>
    </p>
  </div>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'

const maxRetry = 30

export default {
  name: 'ColorDesc',
  props: {
    goodsDesc: {
      type: String,
      default: '',
    },
    language: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      foldLimit: 2,
      showFold: false,
      foldState: true,
      retryCount: 0,
      retryTimer: 0,
    }
  },
  computed: {},
  watch: {
    goodsDesc() {
      this.showFold = false
      this.$nextTick(() => {
        clearTimeout(this.retryTimer)
        this.refreshFold()
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshFold()
      this.postAnalysis()
    })
  },
  beforeUnmount() {
    clearTimeout(this.retryTimer)
  },
  methods: {
    // 更新折叠状态
    refreshFold(firstTime) {
      const context = this.$refs?.context
      if (!context) {
        return
      }
      const contextHeight = context.clientHeight
      if (contextHeight < 1 && this.retryCount < maxRetry) {
        console.log('Waiting for ColorDesc render...')
        this.retryCount = this.retryCount + 1
        this.retryTimer = setTimeout(() => {
          this.refreshFold(firstTime)
        }, 1e2)
        return
      }
      if (contextHeight > 50) {
        this.showFold = true
        if (firstTime) {
          this.foldState = true
        }
      } else {
        this.showFold = false
      }
      this.retryCount = 0
    },
    onUnfold() {
      this.foldState = false
      daEventCenter.triggerNotice({
        daId: '1-8-6-126',
        extraData: {
          fold_tp: 'unfold',
        },
      })
    },
    onFold() {
      this.foldState = true
      daEventCenter.triggerNotice({
        daId: '1-8-6-126',
        extraData: {
          fold_tp: 'fold',
        },
      })
    },
    postAnalysis() {
      if (!this.goodsDesc) {
        return
      }
      this.$nextTick(() => {
        const { showFold } = this
        daEventCenter.triggerNotice({
          daId: '1-8-6-125',
          extraData: {
            fold_tp: showFold ? 'fold' : '0',
          },
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.goods-color__desc-box {
  padding: 8px;
  background-color: #fafafa;
  margin-bottom: 16px;

  .context-box {
    font-size: 12px;
    line-height: 16px;
    color: #767676;
    position: relative;
    &.fold {
      height: 48px;
      overflow: hidden;
    }

    .context {
      display: inline;
      overflow-wrap: break-word;
    }
    .unfold-btn {
      display: inline-block;
      padding-left: 16px;
      position: absolute;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        90deg,
        rgba(250, 250, 250, 0) 0%,
        rgb(250, 250, 250) 30%,
        rgb(250, 250, 250) 100%
      );
      color: #222;
      cursor: pointer;
      .btn-text {
        color: #2d68a8;
      }
    }
  }

  .fold-btn-box {
    display: inline-block;
    padding-right: 4px;
    font-size: 12px;
    line-height: 16px;
    color: #2d68a8;
    cursor: pointer;
  }
}
</style>
