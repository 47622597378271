<script>
export default {
  name: 'SizeRelationLabel'
}
</script>
<script setup>
import { Icon } from '@shein-aidc/icon-vue3'
import { defineProps, inject, computed } from 'vue'
const { GB_cssRight } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : { }
const props = defineProps({
  sizeGroupItem: {
    type: Object,
    default: () => ({}),
  },
  from: {
    type: String,
    default: '',
  },
})
const commonFn = inject('commonFn')
const relateGood = computed(() => 
  Object.assign({}, props.sizeGroupItem, 
    {
      hideSizeGroupeSize: true,
      activityFrom: 'sizegroup'
    }
  ))

const getAnalysisData = ({ type = 'click' }) => {
  let goods_size_list = '' // goods_id`size_group1`theme_type1
  const { theme_type = '', merge_theme_type = '', displayDesc, goodsId, mainGoodsId } = props.sizeGroupItem
  goods_size_list = `${goodsId}\`${displayDesc}\`${merge_theme_type || theme_type}`

  const data = {
    location: props.from === 'detail' ? 'page' : 'popup',
    source_goods_id: mainGoodsId,
    size_group_style: 'size_attr',
    goods_size_list
  }
  return {
    id: type === 'click' ? '1-8-6-199' : '1-8-6-198',
    data,
    code: 'size_group_code'
  }
}
</script>

<template>
  <div
    v-expose="getAnalysisData({ type: 'expose' })"
    v-tap="getAnalysisData({ type: 'click' })"
    class="product-intro__size-radio"
    tabindex="0"
    @click="commonFn.showQuickviewModal($event, true, relateGood)"
  >
    <div class="product-intro__size-radio-inner">
      <span>{{ sizeGroupItem.displayDesc }}</span>
      <Icon 
        name="sui_icon_more_right_14px_1" 
        size="14px"
        :is-rotate="GB_cssRight" 
        :custom-style="{ verticalAlign: '-3px'}"
      />
    </div>
  </div>
</template>
