<template>
  <div
    v-if="
      config.isCustomization &&
        !(config.isCurrentSoldOut || config.isAllSoldOut)
    "
    class="product-intro__add-tips"
  >
    {{ language.SHEIN_KEY_PC_27606 }}
    <a
      class="product-intro__add-tips-link"
      @click="jumpToArticle"
    >
      {{ language.SHEIN_KEY_PC_22233 }}
    </a>
  </div>
  <!-- earn 售罄不展示-->
  <div v-if="!(config.isCurrentSoldOut || config.isAllSoldOut || config.IS_RW)">
    <div
      v-if="point > 0"
      class="product-intro__add-tips"
    >
      <span v-html="text"></span>
    </div>
  </div>
</template>

<script name="BtnTips" setup>
import { onMounted, ref, watch, computed } from 'vue'
import schttp from 'public/src/services/schttp'
import { template } from '@shein/common-function'

const props = defineProps({
  language: {
    type: Object,
    default: () => ({}),
  },
  config: {
    type: Object,
    default: () => ({}),
  },
  integralTips: {
    type: String,
    default: '',
  },
  bottomTipsPointParams: {
    type: Object,
    default: () => ({}),
  }
})

const jumpToArticle = () => {
  const { IS_EUR_DATA_CENTER, SiteUID } = gbCommonInfo
  let articleId = '1925'
  if (IS_EUR_DATA_CENTER) {
    articleId = '1819'
  }
  if (['us'].includes(SiteUID)) {
    articleId = '1898'
  }
  window.open(`/product/article/${articleId}`)
}

const pointMap = ref({
  sku: {},
  skc: {}
})

const point = computed(() => {
  let { skc, sku } = props.bottomTipsPointParams
  if (sku && pointMap.value.sku[sku]) return pointMap.value.sku[sku]
  if (skc && pointMap.value.skc[skc]) return pointMap.value.skc[skc]
  return 0
})

const text = computed(() => {
  return template(point.value, props.integralTips)
})

const getPoint = () => {
  let { skc, sku } = props.bottomTipsPointParams
  if (sku) {
    if (pointMap.value.sku[sku]) return
  } else {
    if (pointMap.value.skc[skc]) return
  }
  schttp({
    url: '/api/productInfo/point/productBudgetPointGive',
    params: props.bottomTipsPointParams
  }).then(res => {
    let point = res?.product_list?.[0]?.point_list?.[0]?.point
    if (sku) {
      pointMap.value.sku[sku] = point
    } else {
      pointMap.value.skc[skc] = point
    }
  })
}

onMounted(() => {
  getPoint()
})
watch(() => props.bottomTipsPointParams.skc, () => {
  getPoint()
})
watch(() => props.bottomTipsPointParams.sku, () => {
  getPoint()
})

</script>

<style lang="less">
.product-intro {
  &__add-tips {
    margin-top: 10px;
    font-size: 12px;
    color: #767676;

    &-link {
      color: @sui_color_link;
      &:hover {
        color: @sui_color_link;
        cursor: pointer;
      }
    }
  }
  &__integral-tips {
    margin-top: 9px;
    font-size: 12px;
    color: #959595;
    .num {
      color: @sui_color_highlight;
    }
  }
}
</style>
