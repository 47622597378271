import { stringifyQueryString } from '@shein/common-function'

export const goToStoreCommon = ({ storeInfo = {}, isStore, storeTab = '', detail }) => {

  const { type, name, designerId, id, link, scId } = storeInfo
  const { cat_id, goods_id } = detail
  let  url = ''
  const common_params = {
    src_module: 'DetailBrand',
    src_identifier: [
      `on=${type}`,
      `cn=${name}`,
      `hz=0`,
      `ps=1_1`,
      `jc=${
        designerId
          ? `sheinxDesignerInformation_${designerId}`
          : isStore
            ? `thirdPartyStoreHome_${id}`
            : `itemPicking_${scId}`
      }`
    ].join('`'),
    src_tab_page_id: getSaPageInfo.tab_page_id || '',
    main_cate_id: cat_id,
    main_goods_id: goods_id,
  }
  // if (storeBusinessType == 2 && storeTab?.value) {
  // 品牌店铺 2 才有Tab， 需要跳转到指定Tab
  if (storeTab) {
    common_params.tab = storeTab
  }
  const split = !!~link.indexOf('?') ? '&' : '?'
  if (isStore) {
    const store_params = stringifyQueryString({
      queryObj: {
        // store_score: storeRate.value || 0,
        ici: 'PageGoodsDetail',
        rule_poskey: 'DetailShopItemList',
        ...common_params
      }
    })
    url = `${link}${split}${store_params}`
  } else {
    const brand_params = stringifyQueryString({ queryObj: common_params })
    url = `${link}${split}${brand_params}`
  }
  return url
}
