import { template, htmlDecode } from '@shein/common-function'
import ProductIntroShippingSheinClub from '../ProductIntroShippingSheinClub'
import { formatTime } from 'public/src/pages/goods_detail_v2/utils/shippingInfoUtil.js'
/**
  * 格式化配送时间
  */
const commonMixins = {
  methods: {
    formatTime,  
    // 获取时效文案：eg.2-5 Business Day ｜eg.2023-01-01 到 2023-01-05
    getShippingOutsideText(policy = {}, abtControl = false) {
      const isBusiness = policy.date_type == 1 // date_type (1=工作日，0=自然日)
      const customDayUnit = isBusiness ? this.language.SHEIN_KEY_PC_24975 : this.language.SHEIN_KEY_PC_24976
      // 卡片式通过abt控制span文案样式
      const customTransportInterval = abtControl ? `<span>${policy.transport_interval}</span>` : policy.transport_interval
      const result =  policy.desc_type == 1 ?
        this.formatExpectTime(policy, abtControl) : 
        policy.transport_interval ? `${customTransportInterval} ${htmlDecode({ text: customDayUnit })}` : ''
      return result
    },
    formatExpectTime(item, addTag) {
      if (item.expect_time === '') {
        return ''
      }
      let expectTime = this.formatTime(item, this.lang)
      expectTime = addTag ? `<span>${expectTime}</span>` : expectTime
      return template(expectTime, this.language.SHEIN_KEY_PC_16986)
    },

    /**
     * 计算免邮提示
     * 逻辑同shipping info文章页运费展示逻辑
     * @item 物流信息
     */
    calcFreeShipping(item, type, shippingStyleV1 = false, isLogisticsShippinFree = false,) {
      let freeDes = ''
      let zeroThreshold = false
      if (isLogisticsShippinFree){ // 无门槛免邮
        freeDes = this.formatShippingDesc({
          textType: 'free',
          shippingType: item.type,
          shippingAmountWithSymbol: 0,
          shippingStyleV1,
        })
        zeroThreshold = true
      } else if ( // 不支持免邮
        typeof item.is_support_free_shipping != 'undefined' &&
            !Number(item.is_support_free_shipping)
      ) {
        freeDes = this.formatShippingDesc({
          textType: 'nofree',
          shippingType: item.type,
          shippingAmountWithSymbol: 0,
          is_support_free: false,
          shippingStyleV1
        })
      } else if (Number(item.has_zero_threshold) === 1) { // 无门槛免邮
        freeDes = this.formatShippingDesc({
          textType: 'free',
          shippingType: item.type,
          shippingAmountWithSymbol: 0,
          shippingStyleV1
        })
        zeroThreshold = true
      } else { 
        if (Number(item.starting_price) === 0) {
          if (Number(item.step_price) === 0) {
            freeDes = this.formatShippingDesc({
              textType: 'free',
              shippingType: item.type,
              shippingAmountWithSymbol: 0,
              shippingStyleV1
            })
            zeroThreshold = true
          }
        } else {
          if (Number(item.full_amount && item.full_amount.amount) > 0) { // 有门槛免邮
            freeDes = this.formatShippingDesc({
              textType: 'threshold',
              shippingType: item.type,
              shippingAmountWithSymbol: item.full_amount.amountWithSymbol,
              shippingStyleV1
            })
          }
        }
      }
      if (type === 'detail') {
        return freeDes
      } else if (type === 'price') {
        return zeroThreshold
      }
    },
    getDayPercentDes(item, largeShipStatus) {
      const {
        desc_type,
        day_percent_desc,
        shipping_day_percent_desc,
        delivery_day_percent_desc,
        days,
        percent
      } = item || {}
      if (largeShipStatus && day_percent_desc ) {
        return day_percent_desc
      }
      if (+desc_type === 0 && shipping_day_percent_desc && days) {
        return shipping_day_percent_desc
      }
      if (+desc_type === 1 && delivery_day_percent_desc && percent) {
        return delivery_day_percent_desc
      }
      return null
    },
    /**
     * 多语言文案处理
     * 0 元包邮门槛 'free'
     * 有包邮门槛   'threshold'
     */
    formatShippingDesc({
      textType,
      shippingType,
      shippingAmountWithSymbol,
      is_support_free = true,
      shippingStyleV1 = false
    }) {
      const isFreeShipping = textType === 'free' ? true : false
      switch (shippingType) {
        // 平邮
        case 'stand_shipping':
          return is_support_free
            ? isFreeShipping
              ? this.handleFreeDom(this.language.SHEIN_KEY_PC_16688, shippingStyleV1)
              : this.handlethresholdFreeDom(shippingAmountWithSymbol, this.language.SHEIN_KEY_PC_16684)
            : this.handleNoFreeDom(this.language.SHEIN_KEY_PC_19523)
            // 快递
        case 'express_shipping':
          return is_support_free
            ? isFreeShipping
              ? this.handleFreeDom(this.language.SHEIN_KEY_PC_16683, shippingStyleV1)
              : this.handlethresholdFreeDom(shippingAmountWithSymbol, this.language.SHEIN_KEY_PC_16685)
            : this.handleNoFreeDom(this.language.SHEIN_KEY_PC_19523)
            // 加急
        case 'expedited_shipping':
          return is_support_free
            ? isFreeShipping
              ? this.handleFreeDom(this.language.SHEIN_KEY_PC_16689, shippingStyleV1)
              : this.handlethresholdFreeDom(shippingAmountWithSymbol, this.language.SHEIN_KEY_PC_16690)
            : this.language.SHEIN_KEY_PC_19523
            // 店配
        case 'shop_transit':
          return is_support_free
            ? isFreeShipping
              ? this.handleFreeDom(this.language.SHEIN_KEY_PC_24347, shippingStyleV1)
              : this.handlethresholdFreeDom(shippingAmountWithSymbol, this.language.SHEIN_KEY_PC_24346)
            : this.handleNoFreeDom(this.language.SHEIN_KEY_PC_19523)
            // 快递店配
        case 'express_shoptransit':
          return is_support_free
            ? isFreeShipping
              ? this.handleFreeDom(this.language.SHEIN_KEY_PC_28080, shippingStyleV1)
              : this.handlethresholdFreeDom(shippingAmountWithSymbol, this.language.SHEIN_KEY_PC_28081)
            : this.handleNoFreeDom(this.language.SHEIN_KEY_PC_19523)
      }
    },
    handleFreeDom(textLang, shippingStyleV1) { // 处理免邮无门槛文案
      let htmlDom = ''
      if(this.needExcuteQuickshipStrongStyle && this.satisfyQuickshipStrongAbt) {
        const freeStrongFreeText = this.deliveryStrongTitleSection ? `${this.language.SHEIN_KEY_PC_28938} & ${this.deliveryStrongTitleSection}` : this.language.SHEIN_KEY_PC_28938
        htmlDom = `<span class="green">${freeStrongFreeText}</span>`
      } else {
        htmlDom = `<span class="${shippingStyleV1 ? 'green' : ''}">${textLang}</span>`
      }
      return htmlDom
    },
    handlethresholdFreeDom(shippingAmountWithSymbol, textLang) { // 处理免邮有门槛文案
      let thresholdText = ''
      if(this.needExcuteQuickshipStrongStyle && this.satisfyQuickshipStrongAbt){ // 命中强化样式
        let firstHalfText = `${this.language.SHEIN_KEY_PC_28938}(${this.language.SHEIN_KEY_PC_30594}&ge;${shippingAmountWithSymbol})`
        let text = this.deliveryStrongTitleSection ? `${firstHalfText} & ${this.deliveryStrongTitleSection}` : firstHalfText
        thresholdText = `<span class="green">${text}</span>`
      } else {
        thresholdText = template(
          shippingAmountWithSymbol,
          textLang
        )
      }
      return thresholdText
    },
    handleNoFreeDom(textLang) { // 处理不支持免邮文案
      let noFreeDom = ''
      if(this.needExcuteQuickshipStrongStyle && this.satisfyQuickshipStrongAbt) {
        noFreeDom = this.deliveryStrongTitleSection ? `<span class="green">${this.deliveryStrongTitleSection}</span>` : this.getAsLowAsText()
      } else {
        noFreeDom = textLang
      }
      return noFreeDom
    },
    getAsLowAsText() {
      let text = ''
      let { starting_price_obj = {}, lowestShippingPrices = {}, full_amount = {} } = this.shippingSummary?.list || {}
      if(this.shippingSummary.list.shipping_mold == 1) {
        text = template(
          starting_price_obj.amountWithSymbol || full_amount.amountWithSymbol,
          this.language.SHEIN_KEY_PC_17728
        )
      } else {
        text = template(
          lowestShippingPrices.amountWithSymbol || full_amount.amountWithSymbol,
          this.language.SHEIN_KEY_PC_17728
        )
      }
      return text
    },
  }
}


const enterMixins = {
  components: {
    ProductIntroShippingSheinClub
  },
  props: {
    isShwoDelayWarn: {
      type: Boolean,
      default: false
    },
    delayWarn: {
      type: Array,
      default: () => []
    },
    language: {
      type: Object,
      default: () => {}
    },
    showDescQuickShipTime: {
      type: Boolean,
      default: false
    },
    quickShipHighlight: {
      type: Boolean,
      default: false
    },
    descQuickShipTime: {
      type: String,
      default: ''
    },
    showSheinClub: {
      type: Boolean,
      default: false
    },
    isPaidUser: {
      type: Boolean,
      default: false
    },
    mallCode: {
      type: String,
      default: ''
    },
    shippingAbtState: {
      type: Object,
      default: () => {}
    },
    deliveryStrongTitleSection: {
      type: String,
      default: ''
    },
    quickshipStrongAbt: {
      type: Object,
      default: () => ({})
    },
    quickShipInfo: {
      type: Object,
      default: () => ({})
    },
    satisfyQuickshipStrongAbt: {
      type: Boolean,
      default: false
    },
    showShippingClub: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showPaidVipTips() {
      const { showShippingClub, showSheinClub, isPaidUser } = this
      return showSheinClub && showShippingClub && !isPaidUser
    },
  },
  methods: {
    template,
    /**
     * 显示运输政策详情弹窗
     */
    showShippingModal(e, index, item = {}) {
      let shippingPosition = ''
      if (index >= 0) {
        const { days = '', transport_type = '' } = item
        shippingPosition = index
        daEventCenter.triggerNotice({
          daId: '1-8-6-120',
          extraData: {
            days,
            shipping_method: transport_type
          }
        })
      } else {
        shippingPosition = ''
      }

      this.$emit('clickEnterHandle', { shippingPosition })
    },
    dayPercentExpose(item = {}) {
      const { days = '', transport_type = '' } = item
      return  {
        id: '1-8-6-121',
        data: {
          days,
          shipping_methods: transport_type
        }
      }
    },
  },
  emits: ['clickEnterHandle']
}



export {
  commonMixins,
  enterMixins
}
