<template>
  <div v-if="showFlashBelt">
    <div
      class="product-intro__head-flashsale"
      @click="clickFlashLink"
    >
      <div class="product-intro__head-flashsale-title">
        <span class="flashsale-icon">
          <Icon
            name="sui_icon_flashsale_16px"
            size="16px"
          />
        </span>
        {{ flashDescText }}
      </div>
      <div class="product-intro__head-flashsale-time">
        <span v-if="showCountTime">
          <span class="end-in">
            {{ globalTempalte('', language.SHEIN_KEY_PC_17111) }}
          </span>
          <span
            v-if="cssRight"
            class="num"
          >{{ secondHundred }}</span>
          <span v-html="countdownStr"></span>
          <span
            v-if="!cssRight"
            class="num"
          >{{ secondHundred }}</span>
        </span>
        <span
          v-else-if="isFlashSaleCountDownAbtHide || isRw"
          :class="{
            'hide': isFlashSaleCountDownAbtHide,
            'hide_rw': isRw
          }"
        >{{ language.SHEIN_KEY_PC_28779 }}</span>
        <Icon
          name="sui_icon_more_right_18px"
          class="product-intro__head-flashsale-icon"
          :is-rotate="cssRight"
          size="12px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import schttp from 'public/src/services/schttp'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { CountDown, template } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'
export default {
  name: 'FlashBelt',
  components: {
    Icon,
  },
  props: {
    flashGoods: {
      type: Object,
      default: () => {},
    },
    language: {
      type: Object,
      default: () => {},
    },
    langPath: {
      type: String,
      default: '',
    },
    promotionInfo: {
      type: Object,
      default: () => {},
    },
    cccFlashZoneData: {
      type: Object,
      default: () => {},
    },
    cssRight: {
      type: Boolean,
      default: false
    },
    isFlashSaleCountDownAbtHide: {
      type: Boolean,
      default: false
    },
    isRw: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showFlashBelt: true,
      showCountTime: false,
      flashTimer: null,
      secondInterval: null,
      countdownStr: '',
      secondHundred: 9,
    }
  },
  computed: {
    flashType () {
      return this.flashGoods?.flashType
    },
    endTime () {
      return this.flashGoods?.endTimestamp || 0
    },
    isBrandFlash() {
      return this.flashType == 6
    },
    flashDescText () {
      const { language = {}, flashGoods = {} } = this
      const { flashType = null } = flashGoods
      if (flashType == 1) {
        return language.SHEIN_KEY_PC_15950 // 常规闪购
      } else if ([4, 5].includes(Number(flashType))) {
        const { titleKey } = this.cccFlashZoneData || {} 
        return language[titleKey] || language?.SHEIN_KEY_PC_27483 || language?.SHEIN_KEY_PC_15950 // 专区兜底文案
      } else if (this.isBrandFlash) {
        return language.SHEIN_KEY_PC_29165
      }
      return language.SHEIN_KEY_PC_15950
    }
  },
  mounted() {
    this.initMyCountDown()
    this.handleExpose()
  },
  unmounted() {
    this.clearTimeAndInterval()
  },
  methods: {
    // 闪购腰带 | 促销携带闪购信息曝光
    handleExpose () {
      let promotionFlashItem = this.promotionInfo?.find(item => item.typeId == 10)
      daEventCenter.triggerNotice({
        daId: '1-8-6-27',
        extraData: {
          promotion_type_id: promotionFlashItem?.typeId || '',
          promotion_id: promotionFlashItem?.id || '',
          is_brand: Number(!!this.brandDetail?.name),
          brand_code: this.brandDetail?.brand_code || '',
        }
      })
      daEventCenter.triggerNotice({
        daId: '1-8-6-45',
        extraData: {
          flash_location: 1
        }
      })
    },
    clickFlashLink () {
      daEventCenter.triggerNotice({
        daId: '1-8-6-46',
        extraData: {
          flash_location: 1
        }
      })
      window.open(`${this.langPath}/flash-sale.html?ici=GoodsDetail_FlashSale&flash_type=${this.flashType}`)
    },
    globalTempalte: template,
    clearTimeAndInterval () {
      // 清除定时器
      this.flashTimer && this.flashTimer?.clear()
      this.secondInterval && clearInterval(this.secondInterval)
    },
    timeStepTxt (timeObj) {
      const { D, H, M, S } = timeObj || {}
      const day = Number(D) > 0 ? `<em>${D}</em>D` : ''
      const hmsStr = `<em>${H}h</em>:<em>${M}m</em>:<em>${S}s</em>`
      return day + hmsStr
    },
    async initMyCountDown () {
      if (this.isFlashSaleCountDownAbtHide) return
      const { currTime: serverTime } = await schttp({ url: '/api/productInfo/currentTime/get', })
      if (this.endTime && this.endTime < serverTime ) {
        this.showFlashBelt = false
        return 
      }
      this.flashTimer = new CountDown({
        countFunc: (timeObj) => {
          const { D } = timeObj || {}
          // 倒计时小于72小时 = 3 天 时展示倒计时文案
          if (Number(D) < 3 && this.showCountTime === false) {
            this.showCountTime = true
            // 额外百毫秒展示
            this.secondInterval = setInterval(() => {
              this.secondHundred --
              if (this.secondHundred < 0) this.secondHundred = 9
            }, 100)
          }
          if (this.showCountTime) {
            this.countdownStr = this.timeStepTxt(timeObj)
          }
        },
        endFunc: () => {
          this.showCountTime = false
          this.showFlashBelt = false
          this.clearTimeAndInterval()
        }
      })
      this.flashTimer.start({
        dayMode: true,
        seconds: this.endTime - serverTime, // 单位是秒
      })
    },
  },
}
</script>

<style lang="less" scoped>
.product-intro {
  &__head-flashsale {
    margin-top: 15px;
    padding: 15px;
    background-color: @sui_color_flash;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 700;
    color: #000;
    text-decoration: none;
    border-radius: 2px;
    /* rw:begin*/
    color: #fff;
    & + .product-intro__head-promotion {
      margin-top: 10px;
    }
    &:hover,
    &:focus {
      text-decoration: none;
    }
    &:visited {
      text-decoration: none;
    }
    &:active {
      text-decoration: none;
    }
  }
  &__head-flashsale-title {
    display: flex;
    align-items: center;
    color: #000;
    /* rw:begin*/
    color: #fff;
    .flashsale-icon {
      margin-right: 5px;
      color: #222;
      /* rw:begin*/
      width: 15px;
      height: 15px;
      border-radius: 50%;
      line-height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #fff;
    }
    svg {
      width: 14px;
      height: 14px;
      /* rw:begin*/
      color: #fff;
    }
  }
  &__head-flashsale-time {
    color: #000;
    /* rw:begin*/
    color: #fff;
    * {
      display: inline-block;
    }
    .num {
      color: #fff;
      background-color: #222;
      height: 14px;
      line-height: 14px;
      min-width: 14px;
      padding: 0 1px;
      text-align: center;
      margin-left: 4px /* rtl: ignore */;
      /* rw:begin*/
      color: #222;
      background-color: #fff;
    }
    .hide {
      font-weight: 400;
    }
    .hide_rw {
      color: #FFF;
    }
    .end-in {
      margin-right: 6px;
    }
  }
  &__head-flashsale-icon {
    transform: translateY(-1px);
  }
}
</style>
