import { computed, toRefs } from 'vue'
import { template } from '@shein/common-function'
export default function useSizeTips(props, reactiveInfo) {
  const { 
    constant,
    mallCode,
    language,
    tipsCombo,
    config,
    getSkcEstimatedInfo,
    baseInfo
  } = toRefs(props)
  const { skuInfo, skuList, skcSaleAttr, polyAttrSize } = reactiveInfo || {}
  const promotionTips = computed(() => {
    const result = {}
    const { SHEIN_KEY_PC_24113, SHEIN_KEY_PC_17383 } = language.value || {}
    const { newFlashPromotionData, flashSaleInfo, underPriceInfo } = tipsCombo.value || {}
    // 闪购购买数量上限提示
    if (flashSaleInfo) {
      const { flashType, singleNum, limitMode, buyLimit, soldNum, endTimestamp } = flashSaleInfo || {}
      const newFlashPromotionPriceWithSymbol = newFlashPromotionData?.price?.amountWithSymbol || ''
      const onlySpPromotion = [2, 3, 4].includes(flashType) && !newFlashPromotionPriceWithSymbol // 并未享受到闪购活动价
      if (+singleNum && !onlySpPromotion) {
        let currentTime = Date.now() / 1000
        let limit_text = limitMode ? template(singleNum, SHEIN_KEY_PC_24113) : template(singleNum, SHEIN_KEY_PC_17383)
        if ((+buyLimit > +soldNum) && (+currentTime < +endTimestamp) && limit_text) {
          result.flashSaleTip = limit_text
        }
      }
    }
    // 一口价购买数量上限提示
    if (underPriceInfo) {
      const { singleNum, limitMode } = underPriceInfo || {}
      if (+singleNum) {
        let limit_text = limitMode ? template(singleNum, SHEIN_KEY_PC_24113) : template(singleNum, SHEIN_KEY_PC_17383)
        if (limit_text) {
          result.underPriceTip = limit_text || ''
        }
      }
    }
    if (polyAttrSize.value?.length) result.promotionTipsLocation = 'top'
    else if (!skcSaleAttr.value?.length) result.promotionTipsLocation = 'bottom'
    return result
  })
  const almostSoldOut = computed(() => {
    let skuStock = 0
    for (let i = 0; i < skuList.value.length; i++) {
      skuStock += +skuList.value[i]?.mall?.[props.mallCode]?.stock || 0
      if (skuStock > (tipsCombo.value?.soldOutTipsLimitNum || 5)) {
        return false
      }
    }
    return skuStock !== 0
  })

  /**
   * 库存模糊用的
   */
  const lowInventoryThreshold = computed(() => tipsCombo.value?.lowInventoryThreshold)
  const almostSoldOutThreshold = computed(() => tipsCombo.value?.almostSoldOutThreshold)
  const fuzzyInventoryThreshold = computed(() => tipsCombo.value?.fuzzyInventoryThreshold)

  /**
  * 当前mall
  * @description 根据skc，sku，mallCode获取当前mall信息
  * @description 从iqs获取的真实库存数据，混入了是否低库存的标识
  */
  const currentMall = computed(() =>{
    const { mall_stock } = baseInfo.value || {}
    let currentMall = {}
    if (skuInfo.value?.sku_code) {
      currentMall = skuInfo.value.mall_stock?.find(item => item.mall_code === mallCode.value) || {}
      currentMall.is_relatively_low_inventory = currentMall.sku_can_sale_days <= almostSoldOutThreshold.value
    } else {
      currentMall =  mall_stock?.find(item => item.mall_code === mallCode.value) || {}
      currentMall.is_relatively_low_inventory = currentMall?.skc_can_sale_days <= almostSoldOutThreshold.value
    }
    currentMall.is_absolute_low_inventory = currentMall?.confusion <= lowInventoryThreshold.value
    currentMall.stock = currentMall?.confusion > fuzzyInventoryThreshold.value ? fuzzyInventoryThreshold.value : currentMall?.confusion
    
    return currentMall
  })
  
  const soldOutTips = computed(() => {
    const { SHEIN_KEY_PC_16990, SHEIN_KEY_PC_16400 } = language.value || {}
    const { showSizeStockTips } = tipsCombo.value || {}
    if (!showSizeStockTips) return ''
    const { stock, is_absolute_low_inventory, is_relatively_low_inventory } = currentMall.value || {}
    if(stock === 0) {
      // 无库存不展示提示
      return ''
    }else if(is_absolute_low_inventory === true) {
      return template(stock, SHEIN_KEY_PC_16400)
    }else if(is_relatively_low_inventory === true) {
      return SHEIN_KEY_PC_16990
    }
    return ''
  })

  const sizeDeviationTipsMap = computed(() => {
    const { sizeTipsText, showHeelHeight, heelHeight, defaultTrueToSizeText, cccTrueToSizeText, hiddenHeelHeightTipsAbt, trueToSizeStrongText } = tipsCombo.value || {}
    const { cssRight } = constant.value || {}
    // 显示优先级 CCC配置 > 评论偏大偏小 > PDC
    if (cccTrueToSizeText) {
      const heelHeightText = showHeelHeight && !hiddenHeelHeightTipsAbt ? (`${
        cssRight ? ' ' : '; '
      }${heelHeight}`) : ''
      return {
        hasHeel: showHeelHeight && !hiddenHeelHeightTipsAbt,
        text: `${cccTrueToSizeText}${heelHeightText}`,
        from: 'ccc',
      }
    }
    // 尺码偏大偏小（评论）
    if (sizeTipsText) {
      return {
        hasHeel: showHeelHeight && !hiddenHeelHeightTipsAbt,
        text: `${sizeTipsText || ''}${showHeelHeight && !hiddenHeelHeightTipsAbt ? '; ' + heelHeight : ''}`,
        from: 'reviews',
      }
    }

    // 跟高
    if (showHeelHeight && !hiddenHeelHeightTipsAbt) {
      if (trueToSizeStrongText) {
        return {
          text: heelHeight,
          hasHeel: showHeelHeight && !hiddenHeelHeightTipsAbt,
          from: 'reviews',
        }
      }
      return {
        hasHeel: showHeelHeight && !hiddenHeelHeightTipsAbt,
        text: `${defaultTrueToSizeText}${(cssRight || !defaultTrueToSizeText ? ' ' : '; ')}${heelHeight}`,
        from: 'pdc',
      }
    }
    // 评论 true to size
    if (trueToSizeStrongText) return {
      text: '',
      from: 'reviews',
      isTrueToSizeStrong: true, 
      hasHeel: showHeelHeight && !hiddenHeelHeightTipsAbt,
    }
    return {
      text: defaultTrueToSizeText,
      hasHeel: showHeelHeight && !hiddenHeelHeightTipsAbt,
      from: 'pdc',
    }
  })

  const tipsInfo = computed(() => {
    const { showHeelHeight, cmInchInfo, sizeTipsText, isLargeSizeTips, trueToSizeStrongText, trueToSizeText, sizeTipsBold, hiddenHeelHeightTipsAbt, } = tipsCombo.value || {}
    return { 
      promotionTips: promotionTips.value, 
      soldOutTips: getSkcEstimatedInfo.value?.isSatisfiedBuyMultiple ? '' : soldOutTips.value, 
      showSizeTips: props.tipsReady && (sizeDeviationTipsMap?.value?.text && config.value.from === 'detail') || sizeTipsText || trueToSizeStrongText,
      sizeDeviationTips: sizeDeviationTipsMap.value?.text,
      tipsOrigin: sizeDeviationTipsMap.value?.from,
      tipsLog: sizeDeviationTipsMap.value?.isTrueToSizeStrong || sizeDeviationTipsMap.value?.text,
      heelHeightInfo: (hiddenHeelHeightTipsAbt || !showHeelHeight) ? '' : cmInchInfo,
      sizeTipsBold,
      sizeTipsText,
      isLargeSizeTips,
      sizeTipsExpose: {
        id: '1-8-6-136',
        data: { type: isLargeSizeTips ? 1 : 2 }
      },
      trueToSizeText,
      trueToSizeStrongText,
      trueToSizeStrongExpose: {
        id: '1-8-6-136',
        data: { type: 3 }
      },
    }
  })

  return { tipsInfo, almostSoldOut }
}
