<template>
  <div
    v-if="goodsComponent"
    class="product-intro__ingredients j-expose__product-intro__ingredients"
  >
    <div
      v-enterkey
      class="product-intro__ingredients-head j-expose__product-intro__ingredients-head"
      @click="handleFold"
    >
      <span class="j-expose__product-intro__ingredients-name">{{
        language.SHEIN_KEY_PC_17597
      }}</span>
      <sui_icon_min_16px
        v-if="unfold.includes('Ingredients')"
        size="16px"
        class="head-icon"
      />
      <sui_icon_add_16px
        v-else
        size="16px"
        class="head-icon"
      />
    </div>
    <ClientOnly>
      <div v-show="unfold.includes('Ingredients')">
        <div :style="{ 'max-height': '195px', overflow: 'hidden' }">
          <div
            ref="Ingredients"
            class="product-intro__ingredients-panel"
            @click="handleMore"
          >
            <span
              v-for="(item, index) in goodsComponentOuter"
              ref="itemWord"
              :key="index"
              v-html="item + ' '"
            ></span>
          </div>
        </div>
      </div>
    </ClientOnly>
    <ClientOnly>
      <s-dialog
        v-model:visible="showModal"
        :type="'W720'"
        :append-to-body="true"
        :show-close="true"
      >
        <template #title>
          {{ language.SHEIN_KEY_PC_17597 }}
        </template>
        <div
          class="product-intro__ingredients-text"
          v-html="goodsComponent"
        ></div>
      </s-dialog>
    </ClientOnly>
  </div>
</template>

<script>
import 'public/src/icon/arrow-down.svg'
import { mapState, mapMutations } from 'vuex'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { sui_icon_min_16px, sui_icon_add_16px } from '@shein-aidc/icon-vue3'
export default {
  name: 'ProductIntroIngredients',
  components: { ClientOnly, sui_icon_min_16px, sui_icon_add_16px },
  data() {
    return {
      visibilityText: [],
      showModal: false,
    }
  },

  computed: {
    ...mapState(['productIntroData', 'unfold', 'language']),
    goodsComponent() {
      const goodsComponent =
        this.productIntroData?.getBeautyGoodsDesc?.goodsComponent || ''
      return goodsComponent.replace(/\n/g, ' <br> ')
    },
    goodsComponentOuter() {
      return this.visibilityText.length
        ? this.visibilityText
        : this.goodsComponent.split(/\s+/)
    },
  },

  watch: {
    'productIntroData.getBeautyGoodsDesc'() {
      if (this.unfold.includes('Ingredients')) {
        this.setShowMoreMark()
      }
    },
    'unfold': {
      handler (newVal) {
        if (newVal.includes('Ingredients')) {
          this.setShowMoreMark()
        }
      },
      deep: true,
    }
  },

  methods: {
    ...mapMutations(['assignState']),
    /**
     * 收起展开
     */
    handleFold() {
      daEventCenter.triggerNotice({
        daId: '1-8-6-52',
      })
      let updatedArray = []
      if (this.unfold.includes('Ingredients')) {
        updatedArray = this.unfold.filter(item => item !== 'Ingredients')
      } else {
        updatedArray = [...this.unfold, 'Ingredients']
      }
      this.assignState({
        unfold: updatedArray
      })
    },
    handleMore(e) {
      if (e.target.className == 'product-intro__ingredients-more') {
        this.showModal = true
      }
    },
    setShowMoreMark() {
      this.$nextTick(() => {
        if (this.$refs.Ingredients.offsetHeight > 195) {
          let maxTop = -9999
          let line = 0
          let resultIndex = 0
          for (let i = 0; i < this.$refs.itemWord.length; i++) {
            const item = this.$refs.itemWord[i]
            if (item.offsetTop > maxTop) {
              if (line >= 1) {
                line += Math.round((item.offsetTop - maxTop) / 18)
              } else {
                line = 1
              }
              maxTop = item.offsetTop
            }
            // 第十行的最后一个单词
            if (line > 10) {
              resultIndex = i - 1
              break
            }
          }
          let visibilityText = []
          let str = ''
          for (let i = resultIndex; i >= 0; i--) {
            if (
              i == resultIndex &&
              this.$refs.itemWord[i].offsetHeight > 18
            ) {
              continue
            }
            str += this.goodsComponentOuter[i]
            if (str.length >= 12) {
              visibilityText = this.goodsComponentOuter.slice(0, i)
              break
            }
          }
          this.visibilityText = visibilityText.concat(
            '<span class="product-intro__ingredients-more">...' +
              this.language.SHEIN_KEY_PC_15953 +
              '</span>'
          )
        }
      })
    },
  },
}
</script>

<style lang="less">
.product-intro {
  &__ingredients-text {
    word-break: break-word;
    font-size: 14px;
    line-height: 16px;
    color: #222;
  }
  &__ingredients-panel {
    padding: 15px 0 0;
    word-break: break-all;
    line-height: 18px;
    font-size: 12px;
  }
  &__ingredients-more {
    color: #1860a7;
    cursor: pointer;
    font-size: 12px;
    margin: 5px 0 15px;
  }
}
</style>
