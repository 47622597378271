<script>
let QuickViewPopover = null
export default {
  name: 'ProductIntroSizeV4'
}
</script>
<script setup>
import { defineProps, defineEmits, computed, watch } from 'vue'
import { useMapState, useMapGetters, useMapActions, useMapMutation } from 'public/src/pages/goods_detail_v2/store/mapHook.js'
import { getImageRatio } from 'public/src/pages/common/utils/index.js'
import Size from 'public/src/pages/goods_detail_v2/components/Size/index.vue'
defineProps({
  currentUnit: {
    type: Number,
    default: -1
  },
  country: {
    type: String,
    default: '',
  },
  showQuickShip: {
    type: Boolean,
    default: false
  },
  deliveryFloorQuickshipTitle: {
    type: String,
    default: '',
  }
})
const emits = defineEmits(['openQuickView', 'update:currentUnit', 'switchColor', 'showLoginModal', 'updateQuickShip'])
const openQuickView = (data) => {
  emits('openQuickView', data)
}
const { 
  tsp,
  sizeComposeData,
  findSimilarFloatParams, 
  sizeConfig, 
  findSimilarFloatInfo, 
  tipsCombo, 
  mallCode, 
  sizeRecommendData, 
  sizeModalComb,
  externalSkuCode,
  isAllSoldOut,
  tipsReady
} = useMapGetters('Size', [
  'tsp',
  'sizeComposeData', 
  'findSimilarFloatParams', 
  'sizeConfig', 
  'findSimilarFloatInfo', 
  'tipsCombo', 
  'mallCode', 
  'sizeRecommendData', 
  'sizeModalComb',
  'externalSkuCode',
  'isAllSoldOut',
  'recommendedSizeData',
  'tipsReady'
])

const { currentMall } = useMapGetters(['currentMall'])
const { 
  skcEstimatedInfo,
  realTimeReady,
} = useMapState([
  'skcEstimatedInfo',
  'realTimeReady',
])
const { updateExposedList, autoGetCouponAtAddBag, updateRealBffInfo } = useMapActions('', ['updateExposedList', 'autoGetCouponAtAddBag', 'updateRealBffInfo'])
const { assignState, setProductIntroData } = useMapMutation('', ['assignState', 'setProductIntroData'])
const { updateRecommendedSizeData } = useMapMutation('Size', ['updateRecommendedSizeData'])
// TODO: 兼容旧数据流格式
const onUpdateOldData = (data) => {
  const { polyAttrSize, skcName, skcSaleAttr, skuList, tackInfoDes } = data || {}
  assignState({
    polyAttrSize,
    saleAttrList: {
      skcName,
      skc_sale_attr: skcSaleAttr,
      sku_list: skuList,
    },
    tackInfoDes,
    skcSaleAttr,
  })
}
const showLoginModal = (addToWish) => {
  emits('showLoginModal', { addToWish })
}
const onChangeSelectCountry = (value, config) => {
  const { isNewSizeLocal } = config || {}
  // 新逻辑要求所有地方一起联动
  isNewSizeLocal ? assignState({ country: value }) : assignState({ sizeGuideCountry: value })
}
const onChangeUnit = (value) => {
  emits('update:currentUnit', value)
}
const onChangeHasSize = (value) => {
  assignState({ hasLocalSize: value })
}
const mallClick = (mallInfo) => {
  const { mallCode, mallStock } = mallInfo || {}
  setProductIntroData({ mallCode, mallStock })
  updateRealBffInfo()
}
const compose = (data) => {
  const { skuInfo, attrs, mallStock } = data || {}
  assignState({ skuInfo, skuAttrs: attrs })
  setProductIntroData({ mallStock })
}
const handleClickSizeGroupItem = (item) => {
  const { findPerfectFitScene } = item
  if(findPerfectFitScene) return handleOpenQuickCart(item)
  const color = {
    goods_sn: item.goodsSN,
    goods_id: item.goodsId,
    goods_url_name: item.goodsName,
    cat_id: item.catId
  }
  emits('switchColor', color)
}
const handleOpenQuickCart = async (item) => {
  const { goodsId, goods_img, mall_code } = item
  const { isHitComplianceMode = false } = sizeComposeData.value.constant || {}
  const imgRatio = await getImageRatio(goods_img)
  const quickViewData = {
    goodsId,
    imgRatio,
    mallCode: mall_code,
    config: {
      showBestDealLabel: true,
      showFollowBeltByOrigin: true,
      showEstimatedPrice: !isHitComplianceMode,
      hideRelationGoods: true,
      hideRelationPlusSize: true,
      hideSizeGroupeSize: true,
      addSource: 'DetailPage',
    },
    callback: {
      handleOpenLogin: showLogin,
      handleAddToCartDone(res){
        window.cart_module && window.cart_module.recart(res)
        self.autoGetCouponAtAddBag({ needToast: res?.code == 0, isMainGoods: false })
      },
    },
    analysis: {
      daIdConfig: {
        viewFullDetail: '2-8-6',
      },
      pageType: '详情页',
      sourceTarget: null,
      index: 0,
      sa: {
        code: 'sizegroup',
        activity_from: 'sizegroup',
      },
    },
  }
  if (QuickViewPopover) {
    QuickViewPopover.show(quickViewData)
    return
  }
  import('public/src/pages/common/quickView').then(_ => {
    QuickViewPopover = _.default
    QuickViewPopover.show(quickViewData)
  })
}
const showLogin = (addToWish) => {
  QuickViewPopover.hide()
  emits('showLoginModal', { addToWish })
}
const handleUpdateQuickShip = (value) => {
  emits('updateQuickShip', value)
}

// 更新推荐尺码
const onRecommendedSizeChange = (data) => {
  updateRecommendedSizeData(data)
}

const findSimilarBind = computed(() => Object.assign({ updateExposedList, autoGetCouponAtAddBag }, findSimilarFloatParams.value || {}))
const tspShowBuySizeTable = computed(() => !!tsp.value?.[600029050])
watch(findSimilarFloatInfo.value, (value) => {
  // 售罄找相似弹窗, 由于组件异步加载, 需要延迟加载
  import('public/src/pages/goods_detail_v2/components/ProductFindSrimilarFloat/index.js').then((component) => {
    const { mode, show } = value || {}
    const { showModal, hideModal } = component?.default || {}
    mode === 'modal' && show ? showModal?.(findSimilarBind.value) : hideModal?.()
  })
})

</script>

<template>
  <Size
    :mall-code="mallCode"
    :current-unit="currentUnit"
    :country="country"
    :sale-attr-list="sizeComposeData.saleAttrList"
    :data-map-compose="sizeComposeData.dataMapCompose"
    :local-size="sizeComposeData.localSize"
    :size-info-des="sizeComposeData.sizeInfoDes"
    :tack-info-des="sizeComposeData.tackInfoDes"
    :language="sizeComposeData.language"
    :base-info="sizeComposeData.baseInfo"
    :sku-map-compose="sizeComposeData.skuMapCompose"
    :constant="sizeComposeData.constant"
    :related-local-size="sizeComposeData.relatedLocalSize"
    :find-similar-bind="findSimilarBind"
    :tips-combo="tipsCombo"
    :size-recommend-data="sizeRecommendData"
    :get-other-options="sizeComposeData.getOtherOptions"
    :config="sizeConfig"
    :size-modal-comb="sizeModalComb"
    :external-sku-code="externalSkuCode"
    :is-all-sold-out="isAllSoldOut"
    :get-skc-estimated-info="skcEstimatedInfo"
    :tsp-show-buy-size-table="tspShowBuySizeTable"
    :show-quick-ship="showQuickShip"
    :delivery-floor-quickship-title="deliveryFloorQuickshipTitle"
    :current-mall="currentMall"
    :recommended-size-data="recommendedSizeData"
    :is-show-modal-info="true"
    :real-time-ready="realTimeReady"
    :tips-ready="tipsReady"
    @mall-click="mallClick"
    @compose="compose"
    @open-quick-view="openQuickView"
    @on-change-select-country="onChangeSelectCountry"
    @on-change-unit="onChangeUnit"
    @on-change-has-size="onChangeHasSize"
    @handle-click-size-group-item="handleClickSizeGroupItem"
    @auto-get-coupon-at-add-bag="autoGetCouponAtAddBag"
    @show-login="showLoginModal"
    @on-update-old-data="onUpdateOldData"
    @handle-update-quick-ship="handleUpdateQuickShip"
    @on-recommended-size-change="onRecommendedSizeChange"
  />
</template>
