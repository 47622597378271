import { fireBtnAnalysis } from './analysis.js'

const testBeforeOCP = (
  { baseInfo, validateParams, customerAnalysis },
  { btnEventCenter, checkNotSelectedTips }
) => {
  const { hasGoodsSize, openQuick, isCustomization } = validateParams
  const { reportData = {} } = customerAnalysis
  const {
    sku_code,
    quickShip,
    goods_sn,
    goods_id,
    mallCode,
    mall_tags,
    defaultMallCode,
    hasNewFlag,
  } = baseInfo
  if (hasGoodsSize && !sku_code) {
    btnEventCenter({ type: 'checkNotSelected', data: checkNotSelectedTips + 1 })
    // * HACK-oneClickPay 在此也会上报打开加车弹窗事件
    fireBtnAnalysis('one_tap_pay', {
      result: { code: 1 },
      postData: {
        quickShip: quickShip,
        sku: goods_sn,
        goods_id,
        mallCode,
        is_default_mall: defaultMallCode == mallCode ? 1 : 0,
        mallTag: mall_tags,
        location: openQuick ? 'suspension' : 'page',
        image_tp: 'small',
        ...reportData,
      },
      faultReason: 'no select size',
      isNew: hasNewFlag,
      is_customize: isCustomization ? 1 : 0,
    })
    return false
  }
  return true
}


const onOneClickPaySuccess = ({ baseInfo, validateParams }, { successInfo, btnEventCenter }) => {
  btnEventCenter({ type: 'updateOCPStage' })
  // * HACK-oneClickPay 在此也会上报打开加车弹窗事件
  if (typeof window === 'undefined') {
    return
  }
  const { hasNewFlag } = baseInfo
  const { isCustomization } = validateParams
  fireBtnAnalysis('one_tap_pay', {
    result: successInfo?.response || { code: 0 },
    postData: successInfo.payParams,
    isNew: hasNewFlag,
    is_customize: isCustomization ? 1 : 0
  })
}

// 一键购支付失败回调
const onOneClickPayFail = ({ baseInfo, validateParams }, { btnEventCenter }) => {
  // 更新状态
  btnEventCenter({ type: 'updateOCPStage' })
  // * HACK-oneClickPay 在此也会上报打开加车弹窗事件
  if (typeof window === 'undefined') {
    return
  }
  const { mallCode, sku_code, quickShip, goods_sn, goods_id, hasNewFlag, mall_tags } = baseInfo
  const { isCustomization, openQuick } = validateParams
  const curMallCode = String(mallCode.value)
  fireBtnAnalysis('one_tap_pay', {
    skuCode: sku_code || '',
    result: { code: 1 },
    postData: {
      quickShip: quickShip,
      sku: goods_sn,
      goods_id,
    },
    faultReason: 'server_failure',
    isNew: hasNewFlag,
    is_customize: isCustomization ? 1 : 0,
    mallCode: curMallCode,
    location: openQuick ? 'suspension' : 'page',
    image_tp: 'small',
    mallTag: mall_tags,
  })
}


export { testBeforeOCP, onOneClickPaySuccess, onOneClickPayFail }
