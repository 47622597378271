<template>
  <div class="product-intro__qty j-expose__product-intro__qty">
    <div class="product-intro__qty-title j-expose__product-intro__qty-title">
      {{ language.SHEIN_KEY_PC_14601 }}:
      <div
        class="product-intro__qty-calc j-expose__product-intro__qty-calc"
        da-event-click="1-8-6-54"
      >
        <div class="reduce">
          <sui_icon_min_16px
            :color="quantity <= 1 ? '#ccc' : '#000'"
            @click="reduceQty"
          />
        </div>
        <input
          v-model="value"
          type="text"
          :disabled="stock === 0"
          @input="handleQtyInput"
          @keypress="handleQtyKeypress"
        />

        <div class="add">
          <sui_icon_add_16px
            :color="minLimit <= value ? '#ccc' : '#000'"
            @click="addQty"
          />
        </div>
      </div>
      <div class="product-intro__tips">
        <div
          v-if="buyMultipleText"
          class="product-intro__buyMultipleText"
        >
          <Icon
            name="sui_icon_activity_12px_1"
            size="12px"
            style="margin-right: 2px;"
          />
          {{ buyMultipleText }}
        </div>
        <div
          v-if="buyMultipleText && showStockTips"
          class="product-intro__gap"
        ></div>
        <div
          v-if="showStockTips"
          v-expose="{
            id: '1-8-6-239',
            data: {
              goods_level: currentMall?.sku_large_ship !== undefined ? 'sku' : 'skc',
              label_type: currentMall?.is_absolute_low_inventory ? 1 : 2,
              location: 'qty',
            },
          }"
          class="product-intro__qty-orange"
        >
          <Icon
            name="sui_icon_hourglass_12px_1"
            size="12px"
            style="margin-right: 2px;"
          />
          {{ stockTip }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { template } from '@shein/common-function'
import { Icon, sui_icon_min_16px, sui_icon_add_16px } from '@shein-aidc/icon-vue3'
export default {
  name: 'ProductIntroQty',
  components: { Icon, sui_icon_min_16px, sui_icon_add_16px },
  data() {
    return {
      value: 1,
    }
  },
  computed: {
    ...mapState([
      'productIntroData',
      'saleAttrList',
      'quantity',
      'language',
      'skuInfo',
      'currentSelectedColor',
    ]),
    ...mapGetters([
      'isAllSoldOut',
      'screenAbtConfig',
      'pageComponents',
      'promotionInfo',
      'mallCode',
      'getEstimatedInfo',
      'currentMall',
    ]),
    showStockTips () {
      const { stock, is_absolute_low_inventory, is_relatively_low_inventory } =  this.currentMall || {}
      return stock > 0 && (is_absolute_low_inventory || is_relatively_low_inventory)
    },
    // 库存
    stock() {
      const currentStock =
        this.productIntroData.detail.is_on_sale == 0
          ? 0
          : Number(
            this.productIntroData.detail.mall?.[this.mallCode]?.stock || 0
          )
      return this.skuInfo.sku_code
        ? this.skuInfo.mall?.[this.mallCode]?.stock || 0
        : currentStock
    },
    // 闪购加车上限
    flashSaleLimit() {
      const { promotionInfo } = this
      const flashSaleData = promotionInfo.find((i) => i.typeId == 10)
      if (!flashSaleData) {
        return 100000
      }
      const currentTime = Date.now() / 1000
      const {
        singleLimitType,
        buyLimit,
        soldNum,
        endTimestamp,
        singleNum,
      } = flashSaleData
      const hasLimit = (singleLimitType == 1 || singleLimitType == 3)
      return hasLimit &&
        Number(buyLimit) > Number(soldNum) &&
        Number(currentTime) < Number(endTimestamp)
        ? singleNum
        : 100000
    },
    // 一口价加车上限
    underPriceLimit() {
      const { productIntroData, mallCode } = this
      const underPriceInfo = productIntroData?.detail?.mall?.[mallCode]
        ?.promotionInfo?.find(i => i.typeId == 30)
      const limitNum = Number(underPriceInfo?.singleNum)
      if (isNaN(limitNum) || !limitNum) {
        return 100000
      }
      return limitNum
    },
    minLimit() {
      const { stock, flashSaleLimit, underPriceLimit } = this
      return Math.min(stock, flashSaleLimit, underPriceLimit)
    },
    stockTip() {
      const { stock, is_absolute_low_inventory, is_relatively_low_inventory } =  this.currentMall || {}
      if(stock <= 0) {
        return ''
      }else if(is_absolute_low_inventory === true) {
        return this.replaceTemplate(stock, this.language.SHEIN_KEY_PC_16400)
      }else if(is_relatively_low_inventory === true) {
        return this.language.SHEIN_KEY_PC_16990
      }
      return ''
    },
    buyMultipleText() {
      // 当数量选择器的数量小于等于n件到手价的n，展示文案为 Buy {0} save {1}， {0}取n件到手价的n，{1}取买n件时整体的减免金额；
      // 当数量选择器的数量大于n件到手价的n,展示文案为 Buy more save more
      let { language, getEstimatedInfo } = this
      let { isSatisfiedBuyMultiple, buyMultiplePic, isSatisfiedBuyMultipleFromPromotion, isSatisfiedBuyMultipleFromCoupon, optimalCoupon, mulSaleInfo } = getEstimatedInfo || {}
      if (!isSatisfiedBuyMultiple) return null
      if (this.value <= buyMultiplePic) {
        if (isSatisfiedBuyMultipleFromPromotion) {
          return template(
            buyMultiplePic,
            mulSaleInfo?.retail_discount_price?.amountWithSymbol,
            language.SHEIN_KEY_PC_31387 || 'Buy {0} save {1}'
          )
        }
        if (isSatisfiedBuyMultipleFromCoupon) {
          return template(
            buyMultiplePic,
            optimalCoupon?.retail_coupon_discount_price?.amountWithSymbol || optimalCoupon?.retail_coupon_discount_price?.amount_text || '',
            language.SHEIN_KEY_PC_31387 || 'Buy {0} save {1}'
          )
        }
      }
      return language.SHEIN_KEY_PWA_31774 || 'Buy more save more'
    }
  },
  watch: {
    quantity: {
      immediate: true,
      handler(val) {
        this.value = val
      },
    },
    minLimit() {
      this.quantityWithStockCheck()
    },
    'pageComponents.qtyConf.isShow'(val) {
      // 多个skc可能会有数量加减器也可能没有
      if (val && this.quantity !== 1) {
        this.assignState({
          quantity: 1,
        })
      }
    },
  },
  methods: {
    ...mapMutations(['assignState']),
    replaceTemplate: template,
    addQty() {
      if (this.quantity < this.minLimit) {
        this.assignState({
          quantity: this.quantity + 1,
        })
      }
    },
    reduceQty() {
      if (this.quantity <= 1) {
        return
      }
      this.assignState({
        quantity: this.quantity - 1,
      })
    },
    handleQtyKeypress(e) {
      if (e.keyCode < 48 || e.keyCode > 57) {
        e.preventDefault()
        return
      }
    },
    handleQtyInput(e) {
      this.value = e.target.value
      let val = parseInt(e.target.value)
      val = val
        ? val < this.minLimit
          ? val
          : this.minLimit
            ? this.minLimit
            : 1
        : 1
      this.$nextTick(() => {
        this.value = val
      })
      this.assignState({
        quantity: val,
      })
    },
    quantityWithStockCheck() {
      if (this.minLimit < this.quantity) {
        this.assignState({
          quantity: this.minLimit >= 1 ? this.minLimit : 1,
        })
      }
    },
  },
}
</script>

<style lang="less">
.product-intro {
  &__qty-title {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  &__qty-calc {
    border: 1px solid rgba(246, 246, 246, 1);
    display: flex;
    margin: 0 15px;
    .add,
    .reduce {
      display: inline-block;
      width: 26px;
      height: 24px;
      background: rgba(247, 246, 247, 1);
      cursor: pointer;
      user-select: none;
      text-align: center;
      line-height: 24px;
    }
    .disabled {
      cursor: auto;
    }
    input {
      width: 50px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border: none;
      outline: 0;
      background: #fff;
      font-size: 14px;
      font-weight: bold;
      &[disabled] {
        color: #ccc;
      }
    }
  }
  &__qty-orange {
    display: flex;
    align-items: center;
    color: @sui_color_highlight;
    font-size: 12px;
    font-weight: normal;
  }
  &__qty-grey {
    color: #ccc;
    font-size: 12px;
    font-weight: normal;
  }
  &__tips {
    display: flex;
    flex-direction: column;
  }
  &__buyMultipleText {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.32px;
    color: @sui_color_highlight;
  }
  &__gap {
    height: 2px;
  }
}
</style>
