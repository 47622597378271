<template>
  <div
    :class="[className, 'shop-entry__storeFollowBtnBox']"
    @click="onClick"
  >
    <slot name="button"></slot>

    <!-- follow success popup dialog -->
    <SDialog
      v-model:visible="popupShow"
      show-close
      append-to-body
      type="W720"
    >
      <template #title>
        {{ langSource.SHEIN_KEY_PC_25387 }}
      </template>
      <template
        #subtitle
      >
        {{ langSource.SHEIN_KEY_PC_25388 }}
      </template>
      <div class="shop-entry__storeFollowTipsImgBox">
        <img
          :src="`${PUBLIC_CDN}/she_dist/images/store/follow-store-tips-af43b52732.png`"
        />
        <div class="shop-entry__modalBtnBox">
          <SButton
            :type="['primary']"
            @click="() => popupShow = false"
          >
            {{ langSource.SHEIN_KEY_PC_25376 }}
          </SButton>
        </div>
      </div>
    </SDialog>
  </div>
</template>

<script setup>
/**
 * @component StoreFollowBtnBox 店铺关注按钮外壳
 * * 处理店铺关注和取消关注逻辑，无vuex依赖
 */
import schttp from 'public/src/services/schttp'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'
import {
  ref,
  toRefs,
  defineEmits,
  computed,
  onMounted,
  nextTick,
} from 'vue'
import { SDialog } from '@shein-aidc/sui-dialog'
import { SButton } from '@shein-aidc/sui-button'
import { SToast } from '@shein-aidc/sui-toast'

const { PUBLIC_CDN, language: commonLang } = gbCommonInfo
// 场景对应的key
const SCENE_KEY_MAP = new Map([
  ['StoreDetail', 1],
  ['ProductDetail', 2],
])
// 是否展示过关注成功弹窗的key
const BEEN_POPUP_SHOW_KEY = 'beenPopupStoreFollowSuccess'

const emit = defineEmits([
  'stateChanged',
  'stateChanging',
  'stateChangeFail',
  'showLoginModal',
])

/**
 * props
 */
const props = defineProps({
  // 盒子类名
  className: {
    type: String,
    default: '',
  },
  // 场景
  // 店铺页: StoreDetail 商品详情页: ProductDetail
  scene: {
    type: String,
    default: '',
  },
  // 店铺关注状态
  followState: {
    type: Boolean,
    default: false,
  },
  // 店铺id
  storeId: {
    type: [String, Number],
    default: '',
  },
  // 未登录时是否自动登录
  autoLogin: {
    type: Boolean,
    default: false,
  },
  language: {
    type: Object,
    default: () => ({}),
  }
})
const {
  scene,
  followState,
  storeId,
  language,
} = toRefs(props)

/**
 * data
 */
const loading = ref(false)
const popupShow = ref(false)
const beenPopupShow = ref(false)

/**
 * computed
 */
// 多语言
const langSource = computed(() => {
  return language.value || commonLang || {}
})
// 点击关注按钮时的请求接口
const changeStateApi = computed(() => (
  followState.value
    ? '/api/store/batchChangeFollowState/create'
    : '/api/store/followStore/create'
))
// 点击关注按钮时的接口请求参数
const changeStateParams = computed(() => {
  // 关注
  if (!followState.value) return {
    storeId: storeId.value,
    scene: SCENE_KEY_MAP.get(scene.value),
  }
  // 取关
  return {
    storeIdSet: [storeId.value]
  }
})

/**
 * methods
 */
/**
 * methods
 */
// 点击关注
const onClick = () => {
  if (typeof window !== 'undefined' && typeof SHEIN_LOGIN !== 'undefined') {
    const isLogin = isLoginFn() || false
    // 已登录直接调用接口
    if (isLogin) return handleOnClick()
  
    // 处理未登录
    handleNotLogin()
  }
}
// 处理登录回调
const loginCallback = state => {
  if (String(state?.code) === '0') {
    setTimeout(() => {
      handleOnClick()
    }, 3e2)
  }
}
// 未登录逻辑处理
const handleNotLogin = () => {
  if (typeof window !== 'undefined' && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD && window?.gbCommonInfo?.WEB_CLIENT === 'shein') {
    emit('showLoginModal', {
      type: 'StoreFollowBtnBox',
      callback: loginCallback,
    })
  }else {
    SHEIN_LOGIN?.show({
      cb: loginCallback,
      from: 'other',
    })
  }
}

// 点击关注
const handleOnClick = async function () {
  if (loading.value) return
  if (!storeId.value) return

  loading.value = true
  emit('stateChanging', true)
  const targetState = !followState.value
  try {
    const result = await schttp({
      url: changeStateApi.value,
      method: 'post',
      data: changeStateParams.value,
    })
    const { code, info } = result
    if (code === '0' && (info?.result === 1)) {
      emit('stateChanged', targetState)
      // 首次关注成功，则弹出关注成功弹窗
      updateBeenPopupShow()
      if (targetState && !beenPopupShow.value) {
        popupShow.value = true
        beenPopupShow.value = true
        window.localStorage?.setItem(BEEN_POPUP_SHOW_KEY, '1')
      }
    } else {
      throw result
    }
  } catch (err) {
    const toastContent = targetState
      ? (String(err?.code) === '400429'
        ? langSource.value?.SHEIN_KEY_PC_25394 // 关注失败，关注数量超出上限
        : langSource.value?.SHEIN_KEY_PC_25393) // 关注失败，请稍后重试
      : langSource.value?.SHEIN_KEY_PC_25392 // 取消关注失败，请稍后重试
    nextTick(() => {
      SToast({
        content: toastContent
      })
    })
    emit('stateChangeFail', err)
  }
  if (loading.value) {
    loading.value = false
    emit('stateChanging', false)
  }
}
// 更新是否展示过关注成功弹窗状态
function updateBeenPopupShow () {
  if (typeof window === 'undefined') return
  beenPopupShow.value = !!window.localStorage?.getItem(BEEN_POPUP_SHOW_KEY)
}

/**
 * hooks
 */
onMounted(() => {
  updateBeenPopupShow()
})
</script>

<style lang="less">
.shop-entry__storeFollowBtnBox {
  display: block;
  cursor: pointer;
}

.shop-entry__storeFollowTipsImgBox {
  width: 100%;

  img {
    display: block;
    width: 100%;
  }

  .shop-entry__modalBtnBox {
    margin-top: 32px;
    display: flex;
    justify-content: center;
  }
}
</style>
