<template>
  <div
    class="product_under-price"
    :class="{ romwe: isRw }"
    @click="onEntryClick"
  >
    <p class="price-text">
      {{ underPriceText }}
    </p>
    <p class="more-tip">
      <span>{{ language.SHEIN_KEY_PC_24756 }}</span>
      <Icon
        name="sui_icon_more_right_18px"
        size="16"
      />
    </p>

    <ClientOnly>
      <div class="svg-box">
        <svg
          width="140"
          height="44"
          viewBox="0 0 140 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.12"
            d="M29.7362 5.1764C29.7351 5.1764 29.7404 5.17429 29.7394 5.17321L20.8109 1.69585C20.7592 1.68318 20.7107 1.66309 20.6548 1.66309C20.3826 1.66309 20.1495 1.82174 20.0397 2.05015L19.8783 2.3484C18.97 4.02784 17.1922 5.03047 15.1245 5.03047C13.0567 5.03047 11.279 4.02784 10.3706 2.3484L10.1923 2.0195C10.0763 1.81113 9.86104 1.66309 9.60468 1.66309C9.54035 1.66309 9.48438 1.68426 9.42534 1.70115L0.513799 5.17218C0.513799 5.17218 0.520119 5.1764 0.519093 5.1764C0.213146 5.31811 0.00108031 5.62904 0.00108031 5.98973C0.00108031 5.99081 0 5.99184 0 5.99292V15.1052C0.0284664 15.5758 0.409332 15.9502 0.884078 15.9502C1.0075 15.9502 1.12569 15.9248 1.23329 15.8793C1.2354 15.8783 1.23437 15.8815 1.2354 15.8815L5.40159 14.219V30.1253C5.40159 30.6213 5.8025 31.0221 6.2962 31.0221H23.9527C24.4464 31.0221 24.8473 30.6213 24.8473 30.1253V14.2105L29.0166 15.8825C29.0198 15.8836 29.0177 15.8783 29.0209 15.8793C29.1285 15.9248 29.2466 15.9502 29.3701 15.9502C29.8364 15.9502 30.2067 15.5896 30.2489 15.1316V5.95377C30.2352 5.609 30.0316 5.31286 29.7362 5.1764Z"
            fill="white"
          />
          <path
            opacity="0.12"
            d="M117.718 28.5803C117.717 28.5803 117.722 28.5782 117.721 28.5771L108.793 25.0998C108.741 25.0871 108.693 25.067 108.637 25.067C108.365 25.067 108.131 25.2257 108.022 25.4541L107.86 25.7523C106.952 27.4318 105.174 28.4344 103.106 28.4344C101.039 28.4344 99.2609 27.4318 98.3526 25.7523L98.1743 25.4234C98.0582 25.2151 97.843 25.067 97.5866 25.067C97.5223 25.067 97.4663 25.0882 97.4073 25.1051L88.4957 28.5761C88.4957 28.5761 88.5021 28.5803 88.501 28.5803C88.1951 28.722 87.983 29.033 87.983 29.3937C87.983 29.3947 87.9819 29.3958 87.9819 29.3969V38.5092C88.0104 38.9798 88.3913 39.3542 88.866 39.3542C88.9894 39.3542 89.1076 39.3288 89.2152 39.2833C89.2173 39.2822 89.2163 39.2854 89.2173 39.2854L93.3835 37.6229V53.5293C93.3835 54.0253 93.7844 54.4261 94.2781 54.4261H111.935C112.428 54.4261 112.829 54.0253 112.829 53.5293V37.6144L116.999 39.2865C117.002 39.2875 117 39.2822 117.003 39.2833C117.11 39.3288 117.229 39.3542 117.352 39.3542C117.818 39.3542 118.189 38.9935 118.231 38.5356V29.3577C118.217 29.0129 118.014 28.7168 117.718 28.5803Z"
            fill="url(#paint0_linear_611_62093)"
          />
          <path
            opacity="0.15"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M78.8789 -1.74274H54.2837V9.73711C54.2837 15.864 59.2505 20.8308 65.3774 20.8308H67.7852C71.8266 20.8308 75.3632 18.6698 77.3026 15.4402H80.732C84.7327 15.4402 87.9758 12.1971 87.9758 8.19648C87.9758 4.19587 84.7327 0.952733 80.732 0.952733L78.8789 0.952733V-1.74274ZM78.5553 12.408C78.7667 11.5525 78.8789 10.6579 78.8789 9.73711V3.985H80.732C83.058 3.985 84.9435 5.87054 84.9435 8.19648C84.9435 10.5224 83.058 12.408 80.732 12.408H78.5553Z"
            fill="url(#paint1_linear_611_62093)"
          />
          <path
            opacity="0.12"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M41.7307 27.8146H50.3897V27.6371C50.3897 25.1891 48.4513 23.2045 46.0602 23.2045C43.6691 23.2045 41.7307 25.1891 41.7307 27.6371V27.8146ZM38.6135 27.8146H38.1026C36.4463 27.8146 34.9683 28.8546 34.4091 30.4136L30.4828 41.3598C29.5658 43.9161 31.4605 46.6085 34.1763 46.6085H57.9481C60.6638 46.6085 62.5585 43.9161 61.6415 41.3598L57.7152 30.4136C57.156 28.8546 55.6781 27.8146 54.0217 27.8146H53.5069V27.6371C53.5069 23.4265 50.1729 20.0131 46.0602 20.0131C41.9475 20.0131 38.6135 23.4265 38.6135 27.6371V27.8146Z"
            fill="white"
          />
          <path
            opacity="0.12"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M119.856 -2.19829H128.515V-2.37605C128.515 -4.82407 126.576 -6.80858 124.185 -6.80858C121.794 -6.80858 119.856 -4.82407 119.856 -2.37605V-2.19829ZM116.738 -2.19829H116.228C114.571 -2.19829 113.093 -1.15827 112.534 0.400777L108.608 11.3469C107.691 13.9032 109.585 16.5956 112.301 16.5956H136.073C138.789 16.5956 140.683 13.9032 139.767 11.3469L135.84 0.400775C135.281 -1.15827 133.803 -2.19829 132.147 -2.19829H131.632V-2.37605C131.632 -6.58664 128.298 -10 124.185 -10C120.072 -10 116.738 -6.58664 116.738 -2.37605V-2.19829Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id="paint0_linear_611_62093"
              x1="103.106"
              y1="25.067"
              x2="103.106"
              y2="54.4261"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop
                offset="1"
                stop-color="white"
                stop-opacity="0.55"
              />
            </linearGradient>
            <linearGradient
              id="paint1_linear_611_62093"
              x1="71.1297"
              y1="-1.74274"
              x2="71.1297"
              y2="20.8308"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop
                offset="1"
                stop-color="white"
                stop-opacity="0.18"
              />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </ClientOnly>
  </div>
</template>

<script>
import { template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { stringifyQueryString } from '@shein/common-function'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { Icon } from '@shein-aidc/icon-vue3'
export default {
  name: 'UnderPriceEntry',
  components: { ClientOnly, Icon },
  props: {
    getPrice: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    isRw: {
      type: Boolean,
      default: false
    },
    langPath: {
      type: String,
      default: ''
    }
  },
  computed: {
    underPriceText() {
      const { language, getPrice } = this
      const amount = getPrice?.suitRulePrice?.amountWithSymbol
      if (!amount) {
        return ''
      }
      return template(amount, language.SHEIN_KEY_PC_24559)
    },
    analysisData() {
      const { getPrice } = this
      const { rule_num, promotionInfo } = getPrice || {}
      const underPricePromotion = promotionInfo?.find?.(item => item.typeId == 30)
      const promoId = underPricePromotion?.id || ''
      const promoType = underPricePromotion?.typeId || ''
      return {
        promotion_id: promoId,
        promotion_tp_id: promoType,
        content_list: `underPrice_${rule_num || 0}`,
        src_module: 'promotion',
        src_identifier: `on=${promoType}\`cn=${promoId}\`ps=1_1\`jc=underPrice_${rule_num || 0}`
      }
    },
  },
  mounted() {
    const { analysisData } = this
    daEventCenter.triggerNotice({
      daId: '1-8-6-130',
      extraData: { ...analysisData }
    })
  },
  methods: {
    onEntryClick() {
      const { getPrice, analysisData } = this
      if (!getPrice) {
        return
      }

      // 埋点
      daEventCenter.triggerNotice({
        daId: '1-8-6-131',
        extraData: { ...analysisData }
      })

      // 跳转会场页
      const { rule_num = 0 } = getPrice
      const params = {
        tab: rule_num,
        ici: 'PageGoodsDetail',
        src_module: analysisData.src_module,
        src_identifier: analysisData.src_identifier,
        src_tab_page_id: window.getSaPageInfo?.tab_page_id,
      }
      const searchParams = stringifyQueryString({ queryObj: params })
      setTimeout(() => {
        location.href = `${this.langPath}/under-prices-products.html?${searchParams}`
      }, 3e2)
    }
  },
}
</script>

<style lang="less" scoped>
.product_under-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  background: linear-gradient(90deg, #FA6338 0%, #FF8F3E 100%);
  border-radius: 1px;
  position: relative;
  overflow: hidden;
  padding: 0 12px;
  color: #fff;
  margin: 16px 0 12px;
  cursor: pointer;

  .price-text {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
  }

  .more-tip {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */

    i {
      font-size: 13px;
      font-weight: 200;
    }
  }

  .svg-box {
    position: absolute;
    top: 0;
    right: 36px;
    z-index: 0; /* stylelint-disable-line declaration-property-value-blacklist */
  }

  &.romwe {
    background: linear-gradient(90deg, #FC407F 0%, #FC40A6 100%);
  }
}
</style>
